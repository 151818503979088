import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./contexts/UserContext";
import { BrowserRouter } from "react-router-dom";
import { DataProvider } from "./contexts/DataContext";
import { SnackbarProvider } from "notistack";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <AuthProvider>
    <DataProvider>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SnackbarProvider>
    </DataProvider>
  </AuthProvider>
);
