import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TableHead,
  Divider,
  Tabs,
  Tab,
  Tooltip,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Stack,
  Pagination,
  TextField,
  IconButton,
  Switch,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useData } from "../../contexts/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TodoList from "../TodoList";
import WorkHour from "../WorkHour";
import Mail from "../Mail";
import ApplicationAdmin from "./ApplicationAdmin";
import FileSaver from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";
import { getDatabase, push, ref, remove, update } from "firebase/database";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Notifications from "./Notifications";
import Market from "./Market";
import VerifiedIcon from "@mui/icons-material/Verified";
import DeleteIcon from "@mui/icons-material/Delete";
import Chronology from "./Chronology";
import EditableTable from "./EditableTable";

const convertLog = (log) => {
  if (log.kind === "N") {
    return {
      type: "Új bevitt érték",
      value: log.path[0] + " → " + JSON.stringify(log.rhs),
    };
  }
  if (log.kind === "E") {
    const temp = log.rhs === "" ? "üres mező" : log.rhs;
    return {
      type: "Érték megváltozott",
      value: log.path + ": " + log.lhs + " → " + temp,
    };
  }
  if (log.kind === "A") {
    if (log.item.kind === "N") {
      return {
        type: "Új bevitt érték",
        date: log.date,
        value: log.path + " → " + log.item?.rhs?.name,
      };
    }
    if (log.item.kind === "D") {
      return {
        type: "Érték törlésre került",
        date: log.date,
        value: log.path + " → " + log.item.lhs.name,
      };
    }
  }
  if (log.kind === "upload") {
    return {
      type: "Új fájl került feltöltésre",
      date: log.date,
      value: log.name,
    };
  }
  if (log.kind === "delete") {
    return {
      type: "Egy fájl törlés került",
      date: log.date,
      value: log.name,
    };
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CommentSection = (props) => {
  const { id } = useParams();
  const [comment, setcomment] = useState("");

  const handleSubmitComment = () => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    const dataRef = ref(database, "palyazatok/" + id + "/megjegyzesek");

    push(dataRef, { komment: comment, datum: date });
    setcomment("");
  };

  return (
    <Box>
      <Typography sx={{ mb: "20px" }}>Megjegyzések</Typography>
      <TextField
        sx={{ width: "100%", mb: "10px" }}
        label="Megjegyzés"
        multiline
        value={comment}
        onChange={(e) => {
          setcomment(e.target.value);
        }}
      />
      <Button
        variant="outlined"
        onClick={() => {
          handleSubmitComment();
        }}
      >
        Megjegyzés hozzáadása
      </Button>
    </Box>
  );
};

const Application = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useData().applicationsData.find((item) => item.key === id);
  const emailData = useData().emailData;
  const [value, setValue] = React.useState(0);
  const componentRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = React.useState(1);
  const [pageCount, setpageCount] = useState(1);
  const [showData, setshowData] = useState();

  const handleChange2 = (event, value) => {
    setPage(value);
  };
  const rowsPerPage = 7;

  useEffect(() => {
    if (emailData) {
      const d = emailData.filter((item) => item.palyazatKulcs === id);
      d.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });

      if (d?.length < rowsPerPage) {
        setpageCount(1);
      } else {
        setpageCount(Math.ceil(d?.length / rowsPerPage));
      }

      setshowData(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkItem = (name, label) => {
    if (data[name]) {
      return { value: data[name], label };
    } else {
      return { value: false, label };
    }
  };

  const handleDownload = () => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr.map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")).join("\n");
    }

    let temp = JSON.parse(JSON.stringify(data));

    delete temp.tennivalok;
    delete temp.munkaidoNyilvantartas;
    delete temp.log;
    delete temp.megjegyzesek;

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot([temp]));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, "Pályázat mentés.csv");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const group = () => {
    let array = [];
    array.push(checkItem("eszkozBeszerzes", "eszközbeszerzés"));
    array.push(checkItem("ingatlanEpites", "ingatlan építés"));
    array.push(checkItem("ingatlanVasarlas", "ingatlan vásárlás"));
    array.push(checkItem("felujitas", "felújítás"));
    array.push(checkItem("korszerusites", "korszerűsítés"));
    array.push(checkItem("megujuloEnergia", "megújuló energia"));
    array.push(checkItem("munkaeroFelvetel", "munkaerő felvétel"));
    array.push(checkItem("munkaeroMegtartas", "munkaerő megtartás"));
    array.push(checkItem("kutatasFejlesztes", "kutatás fejlesztés"));
    return array;
  };

  const dateSince = (date) => {
    date = date.replaceAll(" .", "-");
    date = date.replaceAll(".", "");
    const date1 = new Date(date);
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + " nap telt el";
  };

  const manualAccept = (item) => {
    let today = new Date().toLocaleDateString("hu-HU");
    // const ref = db.ref("email/" + logKulcs);
    // ref.update({ accepted: true, elfogadasDatuma: today });
    const db = getDatabase();
    const dataRef = ref(db, "email/" + item.id);
    update(dataRef, { accepted: true, elfogadasDatuma: today, manual: true });
  };

  const getComments = () => {
    const list = [];
    if (data?.megjegyzesek !== undefined) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(data.megjegyzesek)) {
        value.key = key;
        list.push(value);
      }
    }
    return list;
  };

  const deleteItem = (item) => {
    const database = getDatabase();
    const dataRef = ref(database, "palyazatok/" + id + "/megjegyzesek/" + item.key);
    remove(dataRef);
  };

  const handleComplete = (item) => {
    if (item.teljesitve === undefined) {
      const database = getDatabase();
      const dataRef = ref(database, "palyazatok/" + id + "/megjegyzesek/" + item.key);
      const date = new Date().toLocaleString() + "";

      update(dataRef, { teljesitve: true, teljesitesDatuma: date });
    }
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ paddingTop: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">
              {data && data.projektAzonosito} - {data && data.cegnev}&emsp; pályázat adatai
            </Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  navigate("/applications/add", { state: data });
                }}
              >
                Adatok szerkesztése
              </Button>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Vissza
              </Button>
            </Box>
          </Box>
        </Card>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered >
              <Tab label="Főoldal" {...a11yProps(0)} sx={{px:"12px"}}/>
              <Tab label="Piacfelmérés" {...a11yProps(1)} sx={{px:"12px"}}/>
              <Tab label="Korábbi támogatások" {...a11yProps(2)} sx={{px:"12px"}}/>
              <Tab label="Adminisztráció" {...a11yProps(3)} sx={{px:"12px"}}/>
              <Tab label="Kronológia" {...a11yProps(4)} sx={{px:"12px"}}/>
              <Tab label="Előzmények" {...a11yProps(5)} sx={{px:"12px"}}/>
              <Tab label="Munkaóra nyilvántartás" {...a11yProps(6)} sx={{px:"12px"}}/>
              <Tab label="Értesítők" {...a11yProps(7)} sx={{px:"12px"}}/>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
                {data && (
                  <>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Projekt adatok</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Cég:</TableCell>
                              <TableCell>{data.cegnev}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Cég Email címe:</TableCell>
                              <TableCell>{data.cegEmail}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt felelőse:</TableCell>
                              <TableCell>{data.projektFelelose}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt státusza:</TableCell>
                              <TableCell>{data.statusz}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt azonosító:</TableCell>
                              <TableCell>{data.projektAzonosito}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt cím:</TableCell>
                              <TableCell>{data.projektCime}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Box sx={{ marginTop: "10px" }}>
                          <Typography variant="body2" sx={{ margin: "15px" }}>
                            Projekt célja:
                          </Typography>
                          {group().map((item, index) => {
                            if (item.value) {
                              return <Chip key={"b" + index} label={item.label} variant="outlined" color="primary" sx={{ margin: "3px" }} />;
                            } else {
                              return null;
                            }
                          })}

                          <Typography variant="body2" sx={{ marginLeft: "15px" }}>
                            {data.projektEgyeb ? "Egyéb: " + data.projektEgyeb : null}
                          </Typography>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Pénzügy</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Projekt költségvetése:</TableCell>
                              <TableCell>{data.koltsegvetes}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt elszámolható költsége:</TableCell>
                              <TableCell>{data.projektElszamolhazoKoltsege}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Támogatás mértéke:</TableCell>
                              <TableCell>{data.tamogatasMerteke}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Támogatás összege:</TableCell>
                              <TableCell>{data.tamogatasOsszege2}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Önerő összege:</TableCell>
                              <TableCell>{data.oneroOsszege}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Önerő:</TableCell>
                              <TableCell>{data.onero}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Hitel összege:</TableCell>
                              <TableCell>{data.hitelOsszege}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Támogatás mértéke:</TableCell>
                              <TableCell>{data.tamogatasMerteke} %</TableCell>
                            </TableRow>
                            {data?.modosultKoltsegvetes === true && (
                              <>
                                <TableRow>
                                  <TableCell>Módosult projekt költségvetése:</TableCell>
                                  <TableCell>{data.Modosultkoltsegvetes}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult projekt elszámolható költsége:</TableCell>
                                  <TableCell>{data.ModosultprojektElszamolhazoKoltsege}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult támogatás mértéke:</TableCell>
                                  <TableCell>{data.ModosulttamogatasMerteke}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult támogatás összege:</TableCell>
                                  <TableCell>{data.ModosulttamogatasOsszege2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult önerő összege:</TableCell>
                                  <TableCell>{data.ModosultoneroOsszege}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult önerő:</TableCell>
                                  <TableCell>{data.Modosultonero}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult hitel összege:</TableCell>
                                  <TableCell>{data.ModosulthitelOsszege}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Módosult támogatás mértéke:</TableCell>
                                  <TableCell>{data.ModosulttamogatasMerteke} %</TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Szállító</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          {data.szallito?.map((item, i) => (
                            <React.Fragment key={"c" + i}>
                              <TableRow>
                                <TableCell>
                                  <b>{i + 1}. Szállító</b>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Szállító neve</TableCell>
                                <TableCell>{item.neve}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Adószáma</TableCell>
                                <TableCell>{item.adoszama}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Ajánlat összege</TableCell>
                                <TableCell>{item.ajanlatOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Dátum</TableCell>
                                <TableCell>{item.datum}</TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Támogatás</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            {data?.tamogatas?.map((item) => (
                              <>
                                <TableRow>
                                  <TableCell>Támogatás összege:</TableCell>
                                  <TableCell>{item.tamogatasOsszege}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Támogatás EUR összege:</TableCell>
                                  <TableCell>{item.tamogatasEUROsszege}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Támogatói okirat kelte:</TableCell>
                                  <TableCell>{item.tamogatoiOkiratKelte}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Támogatói okirat hatályba lépése:</TableCell>
                                  <TableCell>{item.tamogatoiOkiratHatalybaLepese}</TableCell>
                                </TableRow>
                              </>
                            ))}
                            <TableRow>
                              <TableCell>Deviza árfolyam Támogatói Okirat keltekor:</TableCell>
                              <TableCell>{data.arfolyamOkiratKeltekor}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Jóváhagyás dec. 31-én érvényes dev. árf.:</TableCell>
                              <TableCell>{data.arfolyamEvVegen}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Év végi árfolyam szerinti támogatás EUR összege:</TableCell>
                              <TableCell>{data.arfolyamEvVegenEur}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Elszámolás</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            {data.mvhPalyazat === true && (
                              <>
                                <TableRow>
                                  <TableCell>MVH pályázat:</TableCell>
                                  <TableCell>igen</TableCell>
                                </TableRow>
                                {data?.elszamolasMerfoldko?.map((item, i) => (
                                  <>
                                    <TableRow>
                                      <TableCell>{i + 1 + ". Mérföldkő"}</TableCell>
                                      <TableCell>{item.merfoldko}</TableCell>
                                    </TableRow>
                                    {i === 0 && (
                                      <>
                                        <TableRow>
                                          <TableCell>Elszámolás benyújtásának időpontja:</TableCell>
                                          <TableCell>{item.elszamolasIdopontja}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>Elszámolásra benyújtott összeg:</TableCell>
                                          <TableCell>{item.elszamolasOsszege}</TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                    <TableRow>
                                      <TableCell>Mérföldkő dátuma:</TableCell>
                                      <TableCell>{item.merfoldkoDatuma}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Mérföldkőig elszámolni kívánt támogatás:</TableCell>
                                      <TableCell>{item.merfoldkoigTamogatas}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>Mérföldkő tartalma:</TableCell>
                                      <TableCell>{item.merfoldkoTartalma}</TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </>
                            )}
                            {data.penzugyminiszteriumos === true && (
                              <>
                                <TableRow>
                                  <TableCell>Pénzügyminisztériumos:</TableCell>
                                  <TableCell>igen</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Elszámolás benyújtásának TO szerinti időpontja:</TableCell>
                                  <TableCell>{data.elszamolasBenyujtasanakTervezettIdopontja}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Elszámolás benyújtásának időpontja:</TableCell>
                                  <TableCell>{data.elszamolasBenyujtasanakIdopontja}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Elszámolásra benyújtott összeg:</TableCell>
                                  <TableCell>{data.elszamolasraBenyujtottOsszeg}</TableCell>
                                </TableRow>
                              </>
                            )}
                            <TableRow>
                              <TableCell>Záró elszámolás esedékessége:</TableCell>
                              <TableCell>{data.zaroElszamolasEsedekessege}</TableCell>
                            </TableRow>
                            {data?.elszamolasHianypotlas?.map((item, i) => (
                              <>
                                <TableRow>
                                  <TableCell>Hiánypótlás dátuma:</TableCell>
                                  <TableCell>{item.hianypotlasDatum}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Elszámolás hiánypótlásának határideje:</TableCell>
                                  <TableCell>{item.elszamolasHianypotlasanakHatarideje}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Hiánypótlás beküldése:</TableCell>
                                  <TableCell>{item.hianypotlasBekuldese}</TableCell>
                                </TableRow>
                              </>
                            ))}
                            {data?.elszamolasTisztazoKerdes?.map((item, i) => (
                              <>
                                <TableRow>
                                  <TableCell>Tisztázó kérdés dátuma:</TableCell>
                                  <TableCell>{item.tisztazoKerdesDatum}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Elszámolás tisztázó kérdésének határideje:</TableCell>
                                  <TableCell>{item.elszamolasTisztazoKerdesenekHatarideje}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Tisztázó kérdés beküldése:</TableCell>
                                  <TableCell>{item.tisztazoKerdesBekuldese}</TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Időpontok</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Pályázat benyújtásának időpontja:</TableCell>
                              <TableCell>{data.benyujtasIdopontja}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>A Projekt költségek elszámolhatóságának kezdő időpontja:</TableCell>
                              <TableCell>{data.projektKoltsegekElszamolasanakKezdete}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt tervezett kezdete:</TableCell>
                              <TableCell>{data.projektTervezettKezdete}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Projekt tervezett befejezése:</TableCell>
                              <TableCell>{data.projektTervezettBefejezese}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Hiánypótlás határideje:</TableCell>
                              <TableCell>{data.hianypotlasHatarideje}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Hiánypótlás dokumentuimai:</TableCell>
                              <TableCell>
                                {data.hianypotlasDokumentumai?.map((item) => {
                                  return item.name + ", ";
                                })}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Tisztázó kérdés határideje:</TableCell>
                              <TableCell>
                                {data.tisztazoKerdesDokumentumai?.map((item) => {
                                  return item.name + ", ";
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Dokumentumok</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Fájl neve</TableCell>
                              <TableCell>Kategória</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.docURL
                              ?.filter((item) => {
                                return item?.admin === undefined;
                              })
                              .map((item, i) => (
                                <TableRow key={"f" + i}>
                                  <TableCell>
                                    <a
                                      href={item.docURL}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      <Chip
                                        label={item.docName}
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            backgroundColor: "#f2f8ff",
                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                          },
                                        }}
                                      />
                                    </a>
                                  </TableCell>
                                  <TableCell>{item.docCategory}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography>Költségvetés részei</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table>
                          <TableBody>
                            {data?.koltsegvetesReszei?.map((item, i) => (
                              <>
                                <TableRow>
                                  <TableCell>{i + 1}. Eszköz:</TableCell>
                                  <TableCell>{item.eszkoz}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Szállító neve:</TableCell>
                                  <TableCell>{item.neve}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Adószáma:</TableCell>
                                  <TableCell>{item.adoszama}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Ajánlat összege:</TableCell>
                                  <TableCell>{item.ajanlatOsszege}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Dátum:</TableCell>
                                  <TableCell>{item.datum}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Ellenajánlat 1.</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Szállító neve:</TableCell>
                                  <TableCell>{item.neveEllenajanlat1}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Adószáma:</TableCell>
                                  <TableCell>{item.adoszamaEllenajanlat1}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Ajánlat összege:</TableCell>
                                  <TableCell>{item.ajanlatOsszegeEllenajanlat1}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Dátum:</TableCell>
                                  <TableCell>{item.datumEllenajanlat1}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Ellenajánlat 2.</TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Szállító neve:</TableCell>
                                  <TableCell>{item.neveEllenajanlat2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Adószáma:</TableCell>
                                  <TableCell>{item.adoszamaEllenajanlat2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Ajánlat összege:</TableCell>
                                  <TableCell>{item.ajanlatOsszegeEllenajanlat2}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Dátum:</TableCell>
                                  <TableCell>{item.datumEllenajanlat2}</TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}

                <Card sx={{ p: "20px", my: "20px" }}>
                  <Typography sx={{ fontWeight: "500" }}>Emailek</Typography>
                  <Typography sx={{ mb: "15px" }} variant="body2">
                    Kipipált elemek esetén értesítés kiküldése be van kapcsolva.
                  </Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.eredetiMegbizasEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                eredetiMegbizasEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Eredeti megbízási beérkezése"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.hianypotlasEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                hianypotlasEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Hiánypótlás határideje"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.tisztazoKerdesEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                tisztazoKerdesEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Tisztázó kérdés határideje"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.projektBefejezesEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                projektBefejezesEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Projekt befejezése (elszámolás esedékessége)"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.tamogatasiElolegEszamolasaEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                tamogatasiElolegEszamolasaEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Támogatási előleg elszámolásának napja"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.fenntartasiIdoszakiJelentesEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                fenntartasiIdoszakiJelentesEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Fenntartási időszaki jelentés"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.zaroElszamolasEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                zaroElszamolasEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Záró elszámolás esedékessége"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.elszamolasHianypotlasEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                elszamolasHianypotlasEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Elszámolás hiánypótlásának határideje"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.elszamolasTisztazoKerdesEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                elszamolasTisztazoKerdesEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Elszámolás tisztázó kérdésének határideje"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.szerzodesmodositasEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                szerzodesmodositasEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Szerződésmódosítás"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.visszaerkezettTeljesitesigazolasEmail}
                            onChange={(e) => {
                              const db = getDatabase();
                              const dataRef = ref(db, "palyazatok/" + data.key);
                              update(dataRef, {
                                visszaerkezettTeljesitesigazolasEmail: e.target.checked,
                              });
                            }}
                          />
                        }
                        label="Visszaérkezett teljesítésigazolás"
                      />
                    </FormGroup>
                  </Box>
                </Card>

                <Card
                  sx={{
                    mt: "20px",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Munkaóra kulcs:</Typography>
                  <Tooltip title="Másolás">
                    <Typography
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(data.key);
                        enqueueSnackbar("A kulcs másolásra került.", {
                          variant: "success",
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                          },
                        });
                      }}
                    >
                      {data.key}
                    </Typography>
                  </Tooltip>
                </Card>

                <Button sx={{ mt: "20px" }} variant="contained" onClick={() => handleDownload()}>
                  Letöltés
                </Button>
                <Button sx={{ mt: "20px", ml: "20px" }} onClick={handlePrint} variant="contained">
                  Nyomtatás
                </Button>

                <Box sx={{ display: "none", displayPrint: "block" }}>
                  <div ref={componentRef}>
                    <div style={{ display: "block" }}>
                      <div className="grid-container">
                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Projekt adatok
                          </Typography>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Cég:</TableCell>
                                <TableCell>{data.cegnev}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Cég Email címe:</TableCell>
                                <TableCell>{data.cegEmail}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt felelőse:</TableCell>
                                <TableCell>{data.projektFelelose}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt státusza:</TableCell>
                                <TableCell>{data.statusz}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt azonosító:</TableCell>
                                <TableCell>{data.projektAzonosito}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt cím:</TableCell>
                                <TableCell>{data.projektCime}</TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                          <Box sx={{ marginTop: "10px" }}>
                            <Typography variant="body2" sx={{ margin: "15px" }}>
                              Projekt célja:
                            </Typography>
                            {group().map((item, index) => {
                              if (item.value) {
                                return <Chip key={"g" + index} label={item.label} variant="outlined" color="primary" sx={{ margin: "3px" }} />;
                              } else {
                                return null;
                              }
                            })}
                          </Box>
                        </div>
                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Pénzügy
                          </Typography>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Projekt költségvetése:</TableCell>
                                <TableCell>{data.koltsegvetes}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Önerő összege:</TableCell>
                                <TableCell>{data.oneroOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Önerő:</TableCell>
                                <TableCell>{data.onero}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Hitel összege:</TableCell>
                                <TableCell>{data.hitelOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Támogatás mértéke:</TableCell>
                                <TableCell>{data.tamogatasMerteke} %</TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="page-break"></div>
                    <div style={{ display: "block" }}>
                      <div className="grid-container">
                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Támogatás
                          </Typography>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Támogatás összege:</TableCell>
                                <TableCell>{data.tamogatasOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Támogatás összege:</TableCell>
                                <TableCell>{data.tamogatasEurOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Támogatói okirat és kelte:</TableCell>
                                <TableCell>
                                  {data.docURL
                                    ?.filter((item) => {
                                      return item.docCategory === "Támogatói okirat";
                                    })
                                    ?.map((item, i) => (
                                      <Chip
                                        key={"h" + i}
                                        label={item.docName}
                                        variant="filled"
                                        color="primary"
                                        onClick={() => window.open(item.docURL, "_blank", "noopener,noreferrer")}
                                      />
                                    ))}
                                  {data.tamogatoiOkiratKelte}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Deviza árfolyam Támogatói Okirat keltekor:</TableCell>
                                <TableCell>{data.arfolyamOkiratKeltekor}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Jóváhagyás dec. 31-én érvényes dev. árf.:</TableCell>
                                <TableCell>{data.arfolyamEvVegen}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Év végi árfolyam szerinti támogatás EUR összege:</TableCell>
                                <TableCell>{data.arfolyamEvVegenEur}</TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                        </div>

                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Elszámolás
                          </Typography>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Pályázati Előleg összege:</TableCell>
                                <TableCell>{data.palyazatiElolegOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Pályázati Előleg megérkezésének dátuma:</TableCell>
                                <TableCell>{data.elolegMegerkezesenekDatuma}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Záró elszámolás esedékessége:</TableCell>
                                <TableCell>{data.zaroElszamolasEsedekessege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Elszámolás hiánypótlásának határideje:</TableCell>
                                <TableCell>{data.elszamolasHianypotlasanakHatarideje}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Elszámolás tisztázó kérdésének határideje:</TableCell>
                                <TableCell>{data.elszamolasTisztazoKerdesenekHatarideje}</TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="page-break"></div>
                    <div style={{ display: "block" }}>
                      <div className="grid-container">
                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Szállító
                          </Typography>
                          <table>
                            <tbody>
                              {data.szallito?.map((item, i) => (
                                <React.Fragment key={"i" + i}>
                                  <TableRow>
                                    <TableCell>
                                      <b>{i + 1}. Szállító</b>
                                    </TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Szállító neve</TableCell>
                                    <TableCell>{item.neve}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Adószáma</TableCell>
                                    <TableCell>{item.adoszama}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Ajánlat összege</TableCell>
                                    <TableCell>{item.ajanlatOsszege}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Dátum</TableCell>
                                    <TableCell>{item.datum}</TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="page-break"></div>
                    <div style={{ display: "block" }}>
                      <div className="grid-container">
                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Időpontok
                          </Typography>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Pályázat benyújtásának időpontja:</TableCell>
                                <TableCell>{data.benyujtasIdopontja}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>A Projekt költségek elszámolhatóságának kezdő időpontja:</TableCell>
                                <TableCell>{data.projektKoltsegekElszamolasanakKezdete}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt tervezett kezdete:</TableCell>
                                <TableCell>{data.projektTervezettKezdete}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt tervezett befejezése:</TableCell>
                                <TableCell>{data.projektTervezettBefejezese}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Hiánypótlás határideje:</TableCell>
                                <TableCell>
                                  {data.hianypotlasDokumentumai?.map((item) => {
                                    return item.name + ", ";
                                  })}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Tisztázó kérdés határideje:</TableCell>
                                <TableCell>
                                  {data.tisztazoKerdesDokumentumai?.map((item) => {
                                    return item.name + ", ";
                                  })}
                                </TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                        </div>

                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Dokumentumok
                          </Typography>
                          <table>
                            <tbody>
                              {data.docURL?.map((item, i) => (
                                <TableRow key={"j" + i}>
                                  <TableCell>
                                    <a
                                      href={item.docURL}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      <Chip
                                        label={item.docName}
                                        variant="outlined"
                                        color="primary"
                                        sx={{
                                          cursor: "pointer",
                                          "&:hover": {
                                            backgroundColor: "#f2f8ff",
                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                          },
                                        }}
                                      />
                                    </a>
                                  </TableCell>
                                  <TableCell>{item.docCategory}</TableCell>
                                </TableRow>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="page-break"></div>
                    <div style={{ display: "block" }}>
                      <div className="grid-container">
                        <div>
                          <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                            Adminisztráció
                          </Typography>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Eredeti megbízási beérkezésének dátuma:</TableCell>
                                <TableCell>{data.eredetiMegbizasBeerkezesiDatuma}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Szerződésmódosítás:</TableCell>
                                <TableCell>{data.szerzodesmodositas ? "igen" : "nem"}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Szerződésmódosítás dátuma:</TableCell>
                                <TableCell>{data.szerzodesmodositasDatuma}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt mappa kész:</TableCell>
                                <TableCell>{data.projektMappaKesz ? "igen" : "nem"}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Projekt mappa kiküldésének ideje:</TableCell>
                                <TableCell>{data.projektMappakikuldese}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Teljesítés igazolás kiküldésének napja:</TableCell>
                                <TableCell>{data.teljesitesIgazolasKikuldesenekNapja}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Visszaérkezett teljesítésigazolás:</TableCell>
                                <TableCell>{data.visszaerkezettTeljesitesigazolas}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Visszaérkezett az átadás-átvételi jegyzőkönyv:</TableCell>
                                <TableCell>{data.visszaerkezettAzAtadasatveteliTrue ? "igen" : "nem"}</TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                        </div>
                        <div>
                          <table>
                            <tbody>
                              <TableRow>
                                <TableCell>Előleg összege:</TableCell>
                                <TableCell>{data.elolegOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Előleg számla száma:</TableCell>
                                <TableCell>{data.elolegSzamlaSzama}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Előleg dátuma:</TableCell>
                                <TableCell>{data.elolegDatuma}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Sikerdíj mértéke:</TableCell>
                                <TableCell>{data.sikerdijMerteke}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Sikerdíj összege:</TableCell>
                                <TableCell>{data.sikerdijOsszege}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Sikerdíj várható esedékessége:</TableCell>
                                <TableCell>{data.sikerdijVarhatoEsedekessege}</TableCell>
                              </TableRow>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="page-break"></div>
                    <WorkHour id={id} data={data.munkaidoNyilvantartas} location={"palyazatok"} />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
                <Card sx={{ padding: "20px", mb: "20px" }}>
                  <CommentSection />
                  <Box sx={{ mt: "20px", backgroundColor: "#f5f5f5", borderRadius: "5px" }}>
                    {getComments().map((item, index) => (
                      <>
                        <Box sx={{ p: "10px", display: "flex", justifyContent: "space-between" }} key={"sdf" + index}>
                          <Box>
                            <Box>
                              <Typography variant="body2" sx={{ mb: "4px" }}>
                                {item.datum}
                              </Typography>
                            </Box>
                            <Box sx={{ wordBreak: "break-word", whiteSpace: "pre-line" }}>
                              <Typography>{item.komment}</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <IconButton aria-label="delete" onClick={() => deleteItem(item)} color="warning" sx={{ marginRight: "10px" }}>
                              <DeleteIcon />
                            </IconButton>
                            <Switch
                              checked={item.teljesitve === true ? true : false}
                              color="success"
                              onChange={() => handleComplete(item)}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          </Box>
                        </Box>
                        <Divider />
                      </>
                    ))}
                  </Box>
                </Card>
                <Card sx={{ padding: "20px" }}>
                  <Button
                    variant="outlined"
                    sx={{}}
                    onClick={() => {
                      navigate("add-todo", {
                        state: {
                          location: "palyazatok",
                          context: "applicationsData",
                          projektAzonosito: data.projektAzonosito,
                          tennivalok: data.tennivalok,
                        },
                      });
                    }}
                  >
                    Feladat hozzáadása
                  </Button>
                  <TodoList tennivalok={data.tennivalok} name={data.projektAzonosito} id={id} db="palyazatok" />
                </Card>
                <Box sx={{ mt: "20px" }}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                      <Typography>Levelezés</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Mail email={data.cegEmail} label={data.key}></Mail>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Market data={data} id={id} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <EditableTable id={id} data={data}/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ApplicationAdmin data={data} id={id} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Chronology data={data} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <Box sx={{ padding: "20px" }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Levelezés</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Mail email={data.cegEmail} label={data.key}></Mail>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Rendszerelőzmények</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h6">Rendszerelőzmények</Typography>
                  {data.log && (
                    <Box
                      sx={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {data.log.map((item, index) => (
                        <Box sx={{ mt: index === 0 ? "0px" : "10px" }} key={"a" + index}>
                          <Typography style={{ fontWeight: "500" }}>{convertLog(item)?.type}</Typography>
                          <Typography>
                            {item.date}&nbsp; {convertLog(item)?.value}
                          </Typography>
                          <Divider></Divider>
                        </Box>
                      ))}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <WorkHour id={id} data={data.munkaidoNyilvantartas}></WorkHour>
            {showData?.length > 0 && (
              <Card sx={{ p: "20px", mt: "20px" }}>
                <Box>
                  {showData?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        padding: "10px",
                        backgroundColor: "#f3f3f3",
                        mb: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{item.type !== "Értesítés" ? "Munkaidő nyilvántartás" : "Értesítés"}</Box>
                        <Box>{item.date}</Box>
                      </Box>
                      <Box
                        sx={{
                          mt: "4px",
                          fontWeight: "600",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{item.cegnev}</Box>
                        <Box>
                          {item.accepted && item.manual === undefined && (
                            <Tooltip title={`Elfogadva, ekkor: ${item.elfogadasDatuma}`}>
                              <DoneIcon sx={{ fill: "green" }} />
                            </Tooltip>
                          )}
                          {item.accepted && item.manual && (
                            <Tooltip title={`Manuálisan elfogadva, ekkor: ${item.elfogadasDatuma}`}>
                              <VerifiedIcon sx={{ fill: "orange" }} />
                            </Tooltip>
                          )}
                          {item.accepted === false && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Button onClick={() => manualAccept(item)}>Manuális elfogadás</Button>
                              <Typography variant="subtitle2" sx={{ mx: "10px" }}>
                                {dateSince(item.date)}
                              </Typography>
                              <Tooltip title="Elfogadásra vár">
                                <HourglassBottomIcon sx={{ fill: "orange" }} />
                              </Tooltip>
                            </Box>
                          )}

                          {item.accepted === undefined && (
                            <Tooltip title="Hiba elküldés során">
                              <ErrorIcon sx={{ fill: "#fc6060" }} />
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  <Stack spacing={2}>
                    <Pagination count={pageCount} page={page} onChange={handleChange2} />
                  </Stack>
                </Box>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={value} index={7}>
            <Notifications id={id} />
          </TabPanel>

        </Box>
      </Box>
    </Container>
  );
};

export default Application;
