import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useData } from "../../contexts/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TodoList from "../TodoList";
import Mail from "../Mail";
import FileSaver from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";
import WorkHour from "../WorkHour";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const convertLog = (log) => {
  if (log.kind === "N") {
    return {
      type: "Új bevitt érték",
      value: log.path[0] + " → " + JSON.stringify(log.rhs),
    };
  }
  if (log.kind === "E") {
    const temp = log.rhs === "" ? "üres mező" : log.rhs;
    return {
      type: "Érték megváltozott",
      value: log.path + ": " + log.lhs + " → " + temp,
    };
  }
  if (log.kind === "A") {
    if (log.item.kind === "N") {
      return {
        type: "Új bevitt érték",
        date: log.date,
        value: log.path + " → " + log.item.rhs?.name,
      };
    }
    if (log.item.kind === "D") {
      return {
        type: "Érték törlésre került",
        date: log.date,
        value: log.path + " → " + log.item.lhs?.name,
      };
    }
  }
  if (log.kind === "upload") {
    return {
      type: "Új fájl került feltöltésre",
      date: log.date,
      value: log.name,
    };
  }
  if (log.kind === "delete") {
    return {
      type: "Egy fájl törlés került",
      date: log.date,
      value: log.name,
    };
  }
};

const Procurement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useData().procurementData.find((item) => item.key === id);
  const componentRef = useRef();
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDownload = (id) => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(
            pathStr
          );
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr
        .map((row) =>
          row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")
        )
        .join("\n");
    }

    const temp = [data];

    for (let item of temp) {
      delete item.tennivalok;
      delete item.munkaidoNyilvantartas;
      delete item.log;
    }

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot([temp]));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, "Közbeszerzés mentés.csv");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Közbeszerzés adatai</Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  navigate("/procurements/add", { state: data });
                }}
              >
                Adatok szerkesztése
              </Button>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Vissza
              </Button>
            </Box>
          </Box>
        </Card>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "rgba(0, 0, 0, 0.12)",
              mb: "30px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="Főoldal" {...a11yProps(0)} />
              <Tab label="Munkaóra nyilvántartás" {...a11yProps(2)} />
              <Tab label="Előzmények" {...a11yProps(3)} />
            </Tabs>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Projekt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Cég:</TableCell>
                        <TableCell>{data.cegnev}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cég Email címe:</TableCell>
                        <TableCell>{data.cegEmail}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Státusz:</TableCell>
                        <TableCell>{data.statusz}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Árajánlat típusa:</TableCell>
                        <TableCell>{data.arajanlatTipusa}</TableCell>
                      </TableRow>

                      {data.arajanlatTipusa === "Árajánlatot tevő" && (
                        <TableRow>
                          <TableCell>
                            Határidő lejáratát megelőző 5. nap:
                          </TableCell>
                          <TableCell>{data.hataridoLejarata}</TableCell>
                        </TableRow>
                      )}
                      {data.arajanlatTipusa === "Árajánlatot kérő" && (
                        <>
                          <TableRow>
                            <TableCell>Tudomásszerzés időpontja:</TableCell>
                            <TableCell>
                              {data.tudomasszerzesIdopontja}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Határidő (30. nap) ideje:</TableCell>
                            <TableCell>{data.tudomasszerzesHatarido}</TableCell>
                          </TableRow>
                        </>
                      )}

                      <TableRow>
                        <TableCell>Közbeszerzés típusa:</TableCell>
                        <TableCell>{data.kozbeszerzesTipusa}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Azonosító:</TableCell>
                        <TableCell>{data.azonosito}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Szerződéskötés dátuma:</TableCell>
                        <TableCell>{data.szerzodeskotesDatuma}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Beadás ideje:</TableCell>
                        <TableCell>{data.beadasIdeje}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Befejezés ideje:</TableCell>
                        <TableCell>{data.befejezes}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Pénzügy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Sikerdíj mértéke:</TableCell>
                        <TableCell>{data.sikerdijMerteke}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sikerdíj:</TableCell>
                        <TableCell>{data.sikerdij}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Várható bevétel:</TableCell>
                        <TableCell>{data.varhatoBevetel}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Előleg:</TableCell>
                        <TableCell>{data.eloleg}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Projekt költségvetés:</TableCell>
                        <TableCell>{data.projektKoltsegvetes}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Dátumok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Hiánypótlás határideje (max. 5 nap):
                        </TableCell>
                        <TableCell>{data.hianypotlasHatarideje}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Javítás határideje (max. 3 nap):</TableCell>
                        <TableCell>{data.javitasHatarideje}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Jóváhagyás napja:</TableCell>
                        <TableCell>{data.jovahagyasNapja}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Eljárás - Nemzeti</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Építés összege:</TableCell>
                        <TableCell>{data.epitesOsszege}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Építés EUR összege:</TableCell>
                        <TableCell>{data.epitesEurOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Építés koncesszió összege:</TableCell>
                        <TableCell>{data.epitesKoncesszioOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Építés koncesszió EUR összege:</TableCell>
                        <TableCell>{data.epitesKoncesszioEurOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Árubeszerzés összege:</TableCell>
                        <TableCell>{data.arubeszerzesOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Árubeszerzés EUR összege:</TableCell>
                        <TableCell>{data.arubeszerzesEurOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Szolgáltatás összege:</TableCell>
                        <TableCell>{data.szolgaltatasOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Szolgáltatás EUR összege:</TableCell>
                        <TableCell>{data.szolgaltatasEurOsszege}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Szolgáltatás koncesszió összege:</TableCell>
                        <TableCell>
                          {data.szolgaltatasKoncesszioOsszege}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          Szolgáltatás koncesszió EUR összege:
                        </TableCell>
                        <TableCell>
                          {data.szolgaltatasKoncesszioEurOsszege}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Eljárás - EU</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Építés összege:</TableCell>
                        <TableCell>{data.epitesOsszegeEU}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Építés EUR összege:</TableCell>
                        <TableCell>{data.epitesEurOsszegeEU}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Építés koncesszió összege:</TableCell>
                        <TableCell>{data.epitesKoncesszioOsszegeEU}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Építés koncesszió EUR összege:</TableCell>
                        <TableCell>
                          {data.epitesKoncesszioEurOsszegeEU}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Árubeszerzés összege:</TableCell>
                        <TableCell>{data.arubeszerzesOsszegeEU}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Árubeszerzés EUR összege:</TableCell>
                        <TableCell>{data.arubeszerzesEurOsszegeEU}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Ajánlatkérő Hirdetmény útján közzétett:
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: "justify" }}>
                    {data.ajanlatkeroHirdetmenyUtjanKozzetett}
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Ajánlatkérő Közbeszerzési Adatbázisban közzétett:
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: "justify" }}>
                    {data.ajanlatkeroKozbeszerzesiAdatbazisbanKozzetett}
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Jogorvoslat:</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ textAlign: "justify" }}>{data.jogorvoslat}</Box>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Dokumentumok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fájl neve</TableCell>
                        <TableCell>Kategória</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.docURL?.map((item) => (
                        <TableRow>
                          <TableCell>
                            <a
                              href={item.docURL}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <Chip
                                label={item.docName}
                                variant="outlined"
                                color="primary"
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "#f2f8ff",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  },
                                }}
                              />
                            </a>
                          </TableCell>
                          <TableCell>{item.docCategory}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Card
                sx={{
                  mt: "20px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Munkaóra kulcs:</Typography>
                <Tooltip title="Másolás">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.key);
                      enqueueSnackbar("A kulcs másolásra került.", {
                        variant: "success",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      });
                    }}
                  >
                    {data.key}
                  </Typography>
                </Tooltip>
              </Card>

              <Button
                sx={{ mt: "20px" }}
                variant="contained"
                onClick={() => handleDownload()}
              >
                Letöltés
              </Button>
              <Button
                sx={{ mt: "20px", ml: "20px" }}
                onClick={handlePrint}
                variant="contained"
              >
                Nyomtatás
              </Button>

              <Box sx={{ display: "none", displayPrint: "block" }}>
                <div ref={componentRef}>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Projekt adatok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Cég:</TableCell>
                            <TableCell>{data.cegnev}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cég Email címe:</TableCell>
                            <TableCell>{data.cegEmail}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Státusz:</TableCell>
                            <TableCell>{data.statusz}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Árajánlat típusa:</TableCell>
                            <TableCell>{data.arajanlatTipusa}</TableCell>
                          </TableRow>

                          {data.arajanlatTipusa === "Árajánlatot tevő" && (
                            <TableRow>
                              <TableCell>
                                Határidő lejáratát megelőző 5. nap:
                              </TableCell>
                              <TableCell>{data.hataridoLejarata}</TableCell>
                            </TableRow>
                          )}
                          {data.arajanlatTipusa === "Árajánlatot kérő" && (
                            <>
                              <TableRow>
                                <TableCell>Tudomásszerzés időpontja:</TableCell>
                                <TableCell>
                                  {data.tudomasszerzesIdopontja}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Határidő (30. nap) ideje:</TableCell>
                                <TableCell>
                                  {data.tudomasszerzesHatarido}
                                </TableCell>
                              </TableRow>
                            </>
                          )}

                          <TableRow>
                            <TableCell>Közbeszerzés típusa:</TableCell>
                            <TableCell>{data.kozbeszerzesTipusa}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Azonosító:</TableCell>
                            <TableCell>{data.azonosito}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Szerződéskötés dátuma:</TableCell>
                            <TableCell>{data.szerzodeskotesDatuma}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Beadás ideje:</TableCell>
                            <TableCell>{data.beadasIdeje}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Befejezés ideje:</TableCell>
                            <TableCell>{data.befejezes}</TableCell>
                          </TableRow>
                        </table>
                      </div>

                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Pénzügy
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Sikerdíj mértéke:</TableCell>
                            <TableCell>{data.sikerdijMerteke}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Sikerdíj:</TableCell>
                            <TableCell>{data.sikerdij}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Várható bevétel:</TableCell>
                            <TableCell>{data.varhatoBevetel}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Előleg:</TableCell>
                            <TableCell>{data.eloleg}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Projekt költségvetés:</TableCell>
                            <TableCell>{data.projektKoltsegvetes}</TableCell>
                          </TableRow>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="page-break"></div>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Eljárás - Nemzeti
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Építés összege:</TableCell>
                            <TableCell>{data.epitesOsszege}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Építés EUR összege:</TableCell>
                            <TableCell>{data.epitesEurOsszege}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Építés koncesszió összege:</TableCell>
                            <TableCell>
                              {data.epitesKoncesszioOsszege}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              Építés koncesszió EUR összege:
                            </TableCell>
                            <TableCell>
                              {data.epitesKoncesszioEurOsszege}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Árubeszerzés összege:</TableCell>
                            <TableCell>{data.arubeszerzesOsszege}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Árubeszerzés EUR összege:</TableCell>
                            <TableCell>{data.arubeszerzesEurOsszege}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Szolgáltatás összege:</TableCell>
                            <TableCell>{data.szolgaltatasOsszege}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Szolgáltatás EUR összege:</TableCell>
                            <TableCell>{data.szolgaltatasEurOsszege}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              Szolgáltatás koncesszió összege:
                            </TableCell>
                            <TableCell>
                              {data.szolgaltatasKoncesszioOsszege}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>
                              Szolgáltatás koncesszió EUR összege:
                            </TableCell>
                            <TableCell>
                              {data.szolgaltatasKoncesszioEurOsszege}
                            </TableCell>
                          </TableRow>
                        </table>
                      </div>

                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Eljárás - EU
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Építés összege:</TableCell>
                            <TableCell>{data.epitesOsszegeEU}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Építés EUR összege:</TableCell>
                            <TableCell>{data.epitesEurOsszegeEU}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Építés koncesszió összege:</TableCell>
                            <TableCell>
                              {data.epitesKoncesszioOsszegeEU}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Építés koncesszió EUR összege:
                            </TableCell>
                            <TableCell>
                              {data.epitesKoncesszioEurOsszegeEU}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Árubeszerzés összege:</TableCell>
                            <TableCell>{data.arubeszerzesOsszegeEU}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Árubeszerzés EUR összege:</TableCell>
                            <TableCell>
                              {data.arubeszerzesEurOsszegeEU}
                            </TableCell>
                          </TableRow>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="page-break"></div>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Dátumok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>
                              Hiánypótlás határideje (max. 5 nap):
                            </TableCell>
                            <TableCell>{data.hianypotlasHatarideje}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Javítás határideje (max. 3 nap):
                            </TableCell>
                            <TableCell>{data.javitasHatarideje}</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell>Jóváhagyás napja:</TableCell>
                            <TableCell>{data.jovahagyasNapja}</TableCell>
                          </TableRow>
                        </table>
                      </div>

                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Dokumentumok
                        </Typography>
                        <table>
                          {data.docURL?.map((item) => (
                            <TableRow>
                              <TableCell>
                                <a
                                  href={item.docURL}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <Chip
                                    label={item.docName}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        backgroundColor: "#f2f8ff",
                                        boxShadow:
                                          "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                      },
                                    }}
                                  />
                                </a>
                              </TableCell>
                              <TableCell>{item.docCategory}</TableCell>
                            </TableRow>
                          ))}
                        </table>
                      </div>

                      <div style={{ marginRight: "40px" }}>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Ajánlatkérő Hirdetmény útján közzétett:
                        </Typography>
                        <Box sx={{ textAlign: "justify" }}>
                          {data.ajanlatkeroHirdetmenyUtjanKozzetett}
                        </Box>
                      </div>

                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Ajánlatkérő Közbeszerzési Adatbázisban közzétett:
                        </Typography>

                        <Box sx={{ textAlign: "justify" }}>
                          {data.ajanlatkeroKozbeszerzesiAdatbazisbanKozzetett}
                        </Box>
                      </div>
                    </div>
                  </div>
                  <div className="page-break"></div>
                  <div>
                    <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                      Jogorvoslat:
                    </Typography>

                    <Box sx={{ textAlign: "justify" }}>
                      <Box sx={{ textAlign: "justify" }}>
                        {data.jogorvoslat}
                      </Box>
                    </Box>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate("add-todo", {
                      state: {
                        location: "kozbeszerzes",
                        context: "procurementData",
                        projektAzonosito: data.azonosito,
                        tennivalok: data.tennivalok,
                      },
                    });
                  }}
                >
                  Feladat hozzáadása
                </Button>
                <TodoList
                  tennivalok={data.tennivalok}
                  id={id}
                  db="kozbeszerzes"
                />
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WorkHour id={id} data={data.munkaidoNyilvantartas}></WorkHour>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ padding: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Levelezés</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Mail email={data.cegEmail} label={data.key}></Mail>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Rendszerelőzmények</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6">Rendszerelőzmények</Typography>
                {data.log && (
                  <Box
                    sx={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {data.log.map((item, index) => (
                      <Box sx={{ mt: index === 0 ? "0px" : "10px" }}>
                        <Typography style={{ fontWeight: "500" }}>
                          {convertLog(item)?.type}
                        </Typography>
                        <Typography>
                          {item.date}&nbsp; {convertLog(item)?.value}
                        </Typography>
                        <Divider></Divider>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Procurement;
