import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useData } from "../../contexts/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TodoList from "../TodoList";
import Mail from "../Mail";
import FileSaver from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";
import WorkHour from "../WorkHour";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const convertLog = (log) => {
  if (log.kind === "N") {
    return {
      type: "Új bevitt érték",
      value: log.path[0] + " → " + JSON.stringify(log.rhs),
    };
  }
  if (log.kind === "E") {
    const temp = log?.rhs === "" ? "üres mező" : log?.rhs;
    return {
      type: "Érték megváltozott",
      value: log.path + ": " + log.lhs + " → " + temp,
    };
  }
  if (log.kind === "A") {
    if (log.item.kind === "N") {
      return {
        type: "Új bevitt érték",
        date: log.date,
        value: log.path + " → " + log.item.rhs?.name,
      };
    }
    if (log.item.kind === "D") {
      return {
        type: "Érték törlésre került",
        date: log.date,
        value: log.path + " → " + log.item.lhs?.name,
      };
    }
  }
  if (log.kind === "upload") {
    return {
      type: "Új fájl került feltöltésre",
      date: log.date,
      value: log.name,
    };
  }
  if (log.kind === "delete") {
    return {
      type: "Egy fájl törlés került",
      date: log.date,
      value: log.name,
    };
  }
};

const Loan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useData().loanData.find((item) => item.key === id);
  const [value, setValue] = React.useState(0);
  const componentRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkItem = (name, label) => {
    if (data[name]) {
      return { value: data[name], label };
    } else {
      return { value: false, label };
    }
  };

  const handleDownload = () => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(
            pathStr
          );
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr
        .map((row) =>
          row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")
        )
        .join("\n");
    }

    let temp = JSON.parse(JSON.stringify(data))

    delete temp.tennivalok;
    delete temp.munkaidoNyilvantartas;
    delete temp.log;

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot(temp));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, "Hitel mentés.csv");
  };

  const group = () => {
    let array = [];
    array.push(checkItem("gepBeszerzes", "gépbeszerzés"));
    array.push(checkItem("ingatlanEpites", "ingatlan építés"));
    array.push(checkItem("ingatlanVasarlas", "ingatlan vásárlás"));
    array.push(checkItem("ingatlanFelujitas", "ingatlan felújítás"));
    array.push(checkItem("ingatlanKorszerusites", "ingatlan korszerűsítés"));
    array.push(checkItem("megujuloEnergia", "megújuló energia"));
    array.push(checkItem("forgoeszkoz", "forgóeszköz"));
    array.push(checkItem("folyoszamlahitel", "folyószámlahitel"));
    return array;
  };

  const group2 = () => {
    let array = [];
    array.push(checkItem("osszeallitando", "Összeállítandó"));
    array.push(checkItem("osszeallitott", "Összeállított"));
    array.push(checkItem("beadott", "Beadott"));
    array.push(checkItem("hianypotlas", "Hiánypótlás"));
    array.push(checkItem("szerzodeskotes", "Szerződéskötés"));
    array.push(checkItem("folyositott", "Folyósított"));
    return array;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Hitel adatai</Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  navigate("/loans/add", { state: data });
                }}
              >
                Adatok szerkesztése
              </Button>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Vissza
              </Button>
            </Box>
          </Box>
        </Card>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "rgba(0, 0, 0, 0.12)",
              mb: "30px",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              centered
            >
              <Tab label="Főoldal" {...a11yProps(0)} />
              <Tab label="Munkaóra nyilvántartás" {...a11yProps(1)} />
              <Tab label="Előzmények" {...a11yProps(2)} />
            </Tabs>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Projekt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {data && (
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Cég:</TableCell>
                          <TableCell>{data.cegnev}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Cég Email címe:</TableCell>
                          <TableCell>{data.cegEmail}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Projekt felelőse</TableCell>
                          <TableCell>{data.projektFelelose}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Projekt státusza</TableCell>
                          <TableCell>{data.statusz}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                  <Box sx={{ marginTop: "10px" }}>
                    <Typography variant="body2" sx={{ margin: "15px" }}>
                      Projekt célja:
                    </Typography>
                    {group().map((item, index) => {
                      if (item.value) {
                        return (
                          <Chip
                            key={index}
                            label={item.label}
                            variant="outlined"
                            color="primary"
                            sx={{ margin: "3px" }}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Box>
                  <Divider sx={{ mt: "10px" }} />
                  <Box sx={{ marginTop: "10px" }}>
                    <Typography variant="body2" sx={{ margin: "15px" }}>
                      Projekt státusza:
                    </Typography>
                    {group2().map((item, index) => {
                      if (item.value) {
                        return (
                          <Chip
                            key={index}
                            label={item.label}
                            variant="outlined"
                            color="primary"
                            sx={{ margin: "3px" }}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Pénzügy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Kért összeg</TableCell>
                        <TableCell>{data.kertOsszeg} Ft</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Jóváhagyott összeg</TableCell>
                        <TableCell>{data.jovahagyottOsszeg} Ft</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Jutalék</TableCell>
                        <TableCell>{data.jutalek} Ft</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Dátumok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Futamidő</TableCell>
                        <TableCell>{data.futamido}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Türelmi idő (hónapok száma)</TableCell>
                        <TableCell>{data.turelmiIdo}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Rendelkezésre tartási idő (határidő)
                        </TableCell>
                        <TableCell>{data.rendelkezesreTartasiIdo}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Adatok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Szerződésszám</TableCell>
                        <TableCell>{data.szerzodesszam}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Szerződéskötés</TableCell>
                        <TableCell>{data.szerzodeskotes}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Finanszírozó neve</TableCell>
                        <TableCell>{data.finanszirozoNeve}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Folyósítás feltételei</TableCell>
                        <TableCell>{data.folyositasFeltetelei}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Kondíció és részletek</TableCell>
                        <TableCell>{data.kondicioEsReszletek}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Adóstárs</TableCell>
                        <TableCell>{data.adostars}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Kondíció és részletek</TableCell>
                        <TableCell>{data.kondicioEsReszletek}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Kezes 1</TableCell>
                        <TableCell>{data.kezes1}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Kezes 2</TableCell>
                        <TableCell>{data.kezes2}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Zálogkötelezett 1</TableCell>
                        <TableCell>{data.zalogkotelezett1}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Zálogkötelezett 2</TableCell>
                        <TableCell>{data.zalogkotelezett2}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Benyújtás dátuma</TableCell>
                        <TableCell>{data.benyujtasDatuma}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ajánlott összeg</TableCell>
                        <TableCell>{data.ajanlottOsszeg}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Megjegyzés</TableCell>
                        <TableCell>{data.megjegyzes}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Fedezet</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Cím</TableCell>
                        <TableCell>Helyrajzi szám</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.fedezet.map((item) => (
                        <TableRow>
                          <TableCell>{item.address}</TableCell>
                          <TableCell>{item.hrsz}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Dokumentumok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fájl neve</TableCell>
                        <TableCell>Kategória</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.docURL?.map((item) => (
                        <TableRow>
                          <TableCell>
                            <a
                              href={item.docURL}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <Chip
                                label={item.docName}
                                variant="outlined"
                                color="primary"
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "#f2f8ff",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  },
                                }}
                              />
                            </a>
                          </TableCell>
                          <TableCell>{item.docCategory}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Card
                sx={{
                  mt: "20px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Munkaóra kulcs:</Typography>
                <Tooltip title="Másolás">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.key);
                      enqueueSnackbar("A kulcs másolásra került.", {
                        variant: "success",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      });
                    }}
                  >
                    {data.key}
                  </Typography>
                </Tooltip>
              </Card>

              <Button
                sx={{ mt: "20px" }}
                variant="contained"
                onClick={() => handleDownload()}
              >
                Letöltés
              </Button>
              <Button
                sx={{ mt: "20px", ml: "20px" }}
                onClick={handlePrint}
                variant="contained"
              >
                Nyomtatás
              </Button>

              <Box sx={{ display: "none", displayPrint: "block" }}>
                <div ref={componentRef}>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Projekt adatok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Cég:</TableCell>
                            <TableCell>{data.cegnev}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cég Email címe:</TableCell>
                            <TableCell>{data.cegEmail}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Projekt felelőse</TableCell>
                            <TableCell>{data.projektFelelose}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Projekt státusza</TableCell>
                            <TableCell>{data.statusz}</TableCell>
                          </TableRow>
                        </table>
                        <Box sx={{ marginTop: "10px" }}>
                          <Typography variant="body2" sx={{ margin: "15px" }}>
                            Projekt célja:
                          </Typography>
                          {group().map((item, index) => {
                            if (item.value) {
                              return (
                                <Chip
                                  key={index}
                                  label={item.label}
                                  variant="outlined"
                                  color="primary"
                                  sx={{ margin: "3px" }}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Box>
                        <Divider sx={{ mt: "10px" }} />
                        <Box sx={{ marginTop: "10px" }}>
                          <Typography variant="body2" sx={{ margin: "15px" }}>
                            Projekt státusza:
                          </Typography>
                          {group2().map((item, index) => {
                            if (item.value) {
                              return (
                                <Chip
                                  key={index}
                                  label={item.label}
                                  variant="outlined"
                                  color="primary"
                                  sx={{ margin: "3px" }}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Box>
                      </div>
                      <div></div>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Pénzügy
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Kért összeg</TableCell>
                            <TableCell>{data.kertOsszeg} Ft</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Jóváhagyott összeg</TableCell>
                            <TableCell>{data.jovahagyottOsszeg} Ft</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Jutalék</TableCell>
                            <TableCell>{data.jutalek} Ft</TableCell>
                          </TableRow>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Adatok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Szerződésszám</TableCell>
                            <TableCell>{data.szerzodesszam}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Szerződéskötés</TableCell>
                            <TableCell>{data.szerzodeskotes}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Finanszírozó neve</TableCell>
                            <TableCell>{data.finanszirozoNeve}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Folyósítás feltételei</TableCell>
                            <TableCell>{data.folyositasFeltetelei}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kondíció és részletek</TableCell>
                            <TableCell>{data.kondicioEsReszletek}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Adóstárs</TableCell>
                            <TableCell>{data.adostars}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kondíció és részletek</TableCell>
                            <TableCell>{data.kondicioEsReszletek}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kezes 1</TableCell>
                            <TableCell>{data.kezes1}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kezes 2</TableCell>
                            <TableCell>{data.kezes2}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Zálogkötelezett 1</TableCell>
                            <TableCell>{data.zalogkotelezett1}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Zálogkötelezett 2</TableCell>
                            <TableCell>{data.zalogkotelezett2}</TableCell>
                          </TableRow>
                        </table>
                      </div>

                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Dátumok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Futamidő</TableCell>
                            <TableCell>{data.futamido}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Türelmi idő (hónapok száma)</TableCell>
                            <TableCell>{data.turelmiIdo}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              Rendelkezésre tartási idő (határidő)
                            </TableCell>
                            <TableCell>
                              {data.rendelkezesreTartasiIdo}
                            </TableCell>
                          </TableRow>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="page-break"></div>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Fedezet
                        </Typography>
                        <table>
                          {data.fedezet.map((item) => (
                            <TableRow>
                              <TableCell>{item.address}</TableCell>
                              <TableCell>{item.hrsz}</TableCell>
                            </TableRow>
                          ))}
                        </table>
                      </div>
                      <div>
                        <Typography
                          variant="h6"
                          sx={{ ml: "20px", py: "15px" }}
                        >
                          Dokumentumok
                        </Typography>
                        <table>
                          {data.docURL?.map((item) => (
                            <TableRow>
                              <TableCell>
                                <a
                                  href={item.docURL}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <Chip
                                    label={item.docName}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        backgroundColor: "#f2f8ff",
                                        boxShadow:
                                          "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                      },
                                    }}
                                  />
                                </a>
                              </TableCell>
                              <TableCell>{item.docCategory}</TableCell>
                            </TableRow>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{}}
                  onClick={() => {
                    navigate("add-todo", {
                      state: {
                        location: "hitelek",
                        context: "loanData",
                        projektAzonosito: "Hitel",
                        tennivalok: data.tennivalok,
                      },
                    });
                  }}
                >
                  Feladat hozzáadása
                </Button>
                <TodoList
                  tennivalok={data.tennivalok}
                  id={id}
                  db="palyazatok"
                />
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WorkHour id={id} data={data.munkaidoNyilvantartas}></WorkHour>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ padding: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Levelezés</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Mail email={data.cegEmail} label={data.key}></Mail>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Rendszerelőzmények</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6">Rendszerelőzmények</Typography>
                {data.log && (
                  <Box
                    sx={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {data.log.map((item, index) => (
                      <Box sx={{ mt: index === 0 ? "0px" : "10px" }}>
                        <Typography style={{ fontWeight: "500" }}>
                          {convertLog(item)?.type}
                        </Typography>
                        <Typography>
                          {item.date}&nbsp; {convertLog(item)?.value}
                        </Typography>
                        <Divider></Divider>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Loan;
