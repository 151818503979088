import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListSubheader,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useData } from "../../contexts/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSnackbar } from "notistack";
import { getDatabase, push, ref, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { DeepDiff } from "deep-diff";
import moment from "moment";
import hu from "date-fns/locale/hu";
import { useAuth } from "../../contexts/UserContext";

const FormDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <DatePicker
            inputFormat="yyyy.MM.dd."
            mask="____.__.__."
            value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
            onChange={(value) => {
              field.onChange(value?.toLocaleDateString() ? value?.toLocaleDateString() : null);
            }}
            label={props.label}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          helperText={props.helperText}
          variant={props.variant}
          label={props.label}
          fullWidth
          InputProps={{
            endAdornment: props.end,
          }}
        />
      )}
    />
  );
};

const StyledLabel = (props) => {
  return (
    <Typography variant="body2" sx={{ mb: "10px" }}>
      <b>{props.bold} </b>
      {props.text}
    </Typography>
  );
};

const AddProcurement = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [docFiles, setdocFiles] = useState([]);
  const [data] = useState({
    arajanlatTipusa: "",
    arubeszerzesEurOsszege: "",
    arubeszerzesEurOsszegeEU: "",
    arubeszerzesOsszege: "",
    arubeszerzesOsszegeEU: "",
    azonosito: "",
    beadasIdeje: "",
    befejezes: "",
    eloleg: "",
    epitesEurOsszege: "",
    epitesEurOsszegeEU: "",
    epitesKoncesszioEurOsszege: "",
    epitesKoncesszioEurOsszegeEU: "",
    epitesKoncesszioOsszege: "",
    epitesKoncesszioOsszegeEU: "",
    epitesOsszege: "",
    epitesOsszegeEU: "",
    hianypotlasHatarideje: "",
    javitasHatarideje: "",
    jovahagyasNapja: "",
    projektKoltsegvetes: "",
    sikerdij: "",
    sikerdijMerteke: "",
    statusz: "",
    szerzodeskotesDatuma: "",
    szolgaltatasEurOsszege: "",
    szolgaltatasKoncesszioEurOsszege: "",
    szolgaltatasKoncesszioOsszege: "",
    szolgaltatasOsszege: "",
    varhatoBevetel: "",
    ajanlatkeroHirdetmenyUtjanKozzetett: "",
    ajanlatkeroKozbeszerzesiAdatbazisbanKozzetett: "",
    jogorvoslat: "",
    hataridoLejarata: null,
    tudomasszerzesHatarido: null,
    tudomasszerzesIdopontja: null,
    cegnev: "",
    cegEmail: "",
    kozbeszerzesTipusa: "",
    adoszamaArubeszerzes1: "",
    adoszamaArubeszerzes1EU: "",
    adoszamaArubeszerzes2: "",
    adoszamaArubeszerzes2EU: "",
    adoszamaEpites: "",
    adoszamaEpites1: "",
    adoszamaEpites1EU: "",
    adoszamaEpites2: "",
    adoszamaEpites2EU: "",
    adoszamaNemzetiEpites: "",
    adoszamaNemzetiNemzetiAru: "",
    adoszamaNemzetiSzolgaltatas: "",
    adoszamaSzolgaltatas: "",
    ajanlatIdopontjaArubeszerzes1: "",
    ajanlatIdopontjaArubeszerzes1EU: "",
    ajanlatIdopontjaArubeszerzes2: "",
    ajanlatIdopontjaArubeszerzes2EU: "",
    ajanlatIdopontjaEpites: "",
    ajanlatIdopontjaEpites1: "",
    ajanlatIdopontjaEpites1EU: "",
    ajanlatIdopontjaEpites2: "",
    ajanlatIdopontjaEpites2EU: "",
    ajanlatIdopontjaNemzetiAru: "",
    ajanlatIdopontjaNemzetiEpites: "",
    ajanlatIdopontjaNemzetiSzolgaltatas: "",
    ajanlatIdopontjaSzolgaltatas: "",
    ajanlatOsszegeArubeszerzes1: "",
    ajanlatOsszegeArubeszerzes1EU: "",
    ajanlatOsszegeArubeszerzes2: "",
    ajanlatOsszegeArubeszerzes2EU: "",
    ajanlatOsszegeEpites: "",
    ajanlatOsszegeEpites1: "",
    ajanlatOsszegeEpites1EU: "",
    ajanlatOsszegeEpites2: "",
    ajanlatOsszegeEpites2EU: "",
    ajanlatOsszegeNemzetiAru: "",
    ajanlatOsszegeNemzetiSzolgaltatas: "",
    ajanlatOsszegeSzolgaltatas: "",
    elsoEllenajanlatNemzetiSzolgaltatas: "",
    eurArfolyamArubeszerzes1: "",
    eurArfolyamArubeszerzes1EU: "",
    eurArfolyamArubeszerzes2: "",
    eurArfolyamArubeszerzes2EU: "",
    eurArfolyamEpites: "",
    eurArfolyamEpites1: "",
    eurArfolyamEpites1EU: "",
    eurArfolyamEpites2: "",
    eurArfolyamEpites2EU: "",
    masodikEllenajanlatNemzetiSzolgaltatas: "",
    nyertesSzallitoNeveEpites: "",
    nyertesSzallitoNeveNemzetiAru: "",
    nyertesSzallitoNeveNemzetiEpites: "",
    nyertesSzallitoNeveSzolgaltatas: "",
    szallitoNeveArubeszerzes1: "",
    szallitoNeveArubeszerzes1EU: "",
    szallitoNeveArubeszerzes2: "",
    szallitoNeveArubeszerzes2EU: "",
    szallitoNeveEpites1: "",
    szallitoNeveEpites1EU: "",
    szallitoNeveEpites2: "",
    szallitoNeveEpites2EU: "",
  });
  const auth = useAuth();

  const { handleSubmit, control } = useForm({
    defaultValues: state ? state : data,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [type, settype] = useState(state ? state.type : "");
  const { customerData } = useData();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileSelect = (event, name, company) => {
    const files = event.target.files;
    const temp = [];

    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }

    for (let index = 0; index < temp.length; index++) {
      if (temp[index] !== undefined) {
        if (docFiles.length > 0) {
          if (docFiles.filter((e) => e.docName === temp[index].name).length > 0) {
            enqueueSnackbar("Ilyen elnevezésű fájl már létezik!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          } else {
            const tempArray = [...docFiles];
            tempArray.push({
              file: temp[index],
              docCategory: name,
              docName: temp[index].name,
              company,
            });
            setdocFiles(tempArray);
          }
        } else {
          const tempArray = [...docFiles];
          tempArray.push({
            file: temp[index],
            docCategory: name,
            docName: temp[index].name,
            company,
          });
          setdocFiles(tempArray);
        }
      }
    }
    handleClose();
  };

  const handleDelete = (index, doc) => {
    const date = new Date().toLocaleString() + "";
    const temp = [...docFiles];
    temp.splice(
      temp.findIndex((item) => item.docName === doc.docName),
      1
    );
    setdocFiles(temp);

    if (doc.docURL !== undefined && doc.import === undefined) {
      const db = getDatabase();
      const storage = getStorage();
      const dataRef = ref(db, "kozbeszerzes/" + state.key);
      const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + doc.docName);
      update(dataRef, { docURL: temp });
      deleteObject(docRef);

      const tempLog = [...state.log];
      tempLog.push({ kind: "delete", name: doc.docName, date: date });
      state.log = tempLog;
    }
  };

  const onSubmit = (formData) => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    let key = "";
    formData.type = type;

    if (state) {
      const dataRef = ref(database, "kozbeszerzes/" + state.key);
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          delete formData[key];
        }
      });

      if (DeepDiff(state, formData) !== undefined) {
        let temp;
        if (state?.log) {
          temp = [...state.log];
        } else {
          temp = [];
        }
        const log = DeepDiff(state, formData);
        log.forEach((element) => {
          element.date = date;
          if (element.path[0] !== "log") {
            temp.push(element);
          }
        });
        formData.log = temp;
      }

      formData.modositasDatuma = date;
      update(dataRef, formData);
      if (docFiles.length > 0) {
        const storage = getStorage();
        let urls = [];
        docFiles.forEach((item) => {
          if (item.docURL === undefined) {
            const str = storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName);
            const tempLog = [...formData.log];
            tempLog.push({ kind: "upload", name: item.docName, date: date });
            update(ref(database, "kozbeszerzes/" + state.key), {
              log: tempLog,
            });
            uploadBytes(str, item.file).then(
              () => {
                getDownloadURL(storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName))
                  .then((url) => {
                    urls.push({
                      docName: item.docName,
                      docURL: url,
                      docCategory: item.docCategory,
                      company: item.company ? item.company : "",
                    });
                  })
                  .then(() => {
                    update(ref(database, "kozbeszerzes/" + state.key), {
                      docURL: urls,
                    });
                  });
              },
              (err) => {
                console.log(err);
              }
            );
          } else {
            urls.push(item);
            update(ref(database, "kozbeszerzes/" + state.key), {
              docURL: urls,
            });
          }
        });
      } else {
        update(ref(database, "kozbeszerzes/" + state.key), {
          docURL: [],
        });
      }
      navigate(-1);
    } else {
      formData.rogzitesDatuma = date;
      let count = 0;
      const dataRef = ref(database, "/kozbeszerzes");
      try {
        push(dataRef, formData).then(async (snap) => {
          key = snap.key;
          if (docFiles.length > 0) {
            const storage = getStorage();
            let urls = [];

            const upload = new Promise((resolve, reject) => {
              docFiles.forEach((file, index) => {
                if (file.import) {
                  urls.push({
                    docName: file.docName,
                    docURL: file.docURL,
                    docCategory: file.docCategory,
                    import: true,
                  });
                  count = count + 1;
                  if (count === docFiles.length) {
                    resolve();
                  }
                } else {
                  const str = storageRef(storage, "dokumentumok/" + key + "_" + file.docName);
                  uploadBytes(str, file.file).then(() => {
                    getDownloadURL(storageRef(storage, "dokumentumok/" + key + "_" + file.docName)).then((url) => {
                      urls.push({
                        docName: file.docName,
                        docURL: url,
                        docCategory: file.docCategory,
                        company: file.company ? file.company : "",
                      });
                      count = count + 1;
                      if (count === docFiles.length) {
                        resolve();
                      }
                    });
                  });
                }
              });
            });
            upload.then(() => {
              update(ref(database, "kozbeszerzes/" + key), {
                docURL: urls,
              });
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
      navigate(-1);
    }
  };

  useEffect(() => {
    if (state?.docURL) {
      setdocFiles(state.docURL);
    }
  }, [state?.docURL]);

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{}}>
              {state ? "Közbeszerzés szerkesztése" : "Új közbeszerzés"}
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
              Vissza
            </Button>
          </Box>
        </Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Cég kiválasztása
                  </Typography>
                  <FormControl sx={{ width: "100%", mb: "30px" }}>
                    <InputLabel id="demo-simple-select-label">Cég</InputLabel>
                    <Controller
                      name="cegnev"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Cég" fullWidth>
                          {customerData &&
                            customerData.map((item, index) => (
                              <MenuItem key={index} value={item.cegnev}>
                                {item.cegnev}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                {(auth.role === "Admin" || auth.role === "Irodavezető") && (
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      width="200px"
                      label="Cég Email címe"
                      name="cegEmail"
                      helperText="Több email esetén vesszővel elválasztva"
                    />
                  </Grid>
                )}
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="demo-simple-select-label">Státusz</InputLabel>
                      <Controller
                        name="statusz"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Státusz">
                            <MenuItem value={"Hiánypótlás"}>Hiánypótlás</MenuItem>
                            <MenuItem value={"Javított dokumentumok EMIR-be"}>Javított dokumentumok EMIR-be</MenuItem>
                            <MenuItem value={"Feltételesen támogató"}>Feltételesen támogató</MenuItem>
                            <MenuItem value={"Eljárás megindítása"}>Eljárás megindítása</MenuItem>
                            <MenuItem value={"Bontás"}>Bontás</MenuItem>
                            <MenuItem value={"Előzetes vitarendezés"}>Előzetes vitarendezés</MenuItem>
                            <MenuItem value={"Kérelem elfogadása"}>Kérelem elfogadása</MenuItem>
                            <MenuItem value={"Kérelem elutasítása"}>Kérelem elutasítása</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="demo-simple-select-label">Árajánlat típusa</InputLabel>
                      <Controller
                        name="arajanlatTipusa"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              settype(e.target.value);
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Árajánlat típusa"
                          >
                            <MenuItem value={"Árajánlatot kérő"}>Árajánlatot kérő</MenuItem>
                            <MenuItem value={"Árajánlatot tevő"}>Árajánlatot tevő</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {type === "Árajánlatot kérő" && (
                      <Box
                        sx={{
                          border: "2px solid #f0f0f0",
                          borderRadius: "8px",
                          p: "10px",
                        }}
                      >
                        Tudomásszerzés követő 30 nap
                        <Divider sx={{ my: "14px" }}></Divider>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2" sx={{ mb: "8px" }}>
                              Tudomásszerzés időpontja:
                            </Typography>
                            <FormDate control={control} label="Dátum" name="tudomasszerzesIdopontja" />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2" sx={{ mb: "8px" }}>
                              Határidő (30. nap) ideje:
                            </Typography>
                            <FormDate control={control} label="Dátum" name="tudomasszerzesHatarido" />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    {type === "Árajánlatot tevő" && (
                      <Box
                        sx={{
                          border: "2px solid #f0f0f0",
                          borderRadius: "8px",
                          p: "10px",
                        }}
                      >
                        Határidő lejáratát megelőző 5. nap
                        <Divider sx={{ my: "14px" }}></Divider>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormDate control={control} label="Dátum" name="hataridoLejarata" />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120 }} fullWidth>
                      <InputLabel>Közbeszerzés típusa</InputLabel>
                      <Controller
                        name="kozbeszerzesTipusa"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} variant="filled" label="Árajánlat típusa">
                            <ListSubheader sx={{ fontWeight: "800" }}>Egyszakaszos:</ListSubheader>
                            <MenuItem sx={{ ml: "15px" }} value={"Nyílt (feltétel nélküli vagy nincs tárgyalás)"}>
                              Nyílt (feltétel nélküli vagy nincs tárgyalás)
                            </MenuItem>
                            <MenuItem sx={{ ml: "15px" }} value={"Hirdetmény nélküli tárgyalásos (feltételes vagy tárgyalásos)"}>
                              Hirdetmény nélküli tárgyalásos (feltételes vagy tárgyalásos)
                            </MenuItem>
                            <Divider></Divider>
                            <ListSubheader sx={{ fontWeight: "800" }}>Több szakaszos:</ListSubheader>

                            <ListSubheader sx={{ ml: "15px" }}>Kétszakaszos:</ListSubheader>
                            <MenuItem value={"Meghívásos (feltétel nélküli vagy nincs tárgyalás)"} sx={{ ml: "30px" }}>
                              Meghívásos (feltétel nélküli vagy nincs tárgyalás)
                            </MenuItem>
                            <MenuItem value={"Tárgyalásos (feltételes vagy tárgyalásos)"} sx={{ ml: "30px" }}>
                              Tárgyalásos (feltételes vagy tárgyalásos)
                            </MenuItem>
                            <ListSubheader sx={{ ml: "15px" }}>Háromszakaszos:</ListSubheader>
                            <MenuItem value={"Versenypárbeszéd (feltételes vagy nincs tárgyalás)"} sx={{ ml: "30px" }}>
                              Versenypárbeszéd (feltételes vagy nincs tárgyalás)
                            </MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Azonositó" name="azonosito" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Szerződéskötés dátuma" name="szerzodeskotesDatuma" width="100%" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Beadás ideje" name="beadasIdeje" width="100%" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Befejezés ideje" name="befejezes" width="100%" />
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Eljárás - Nemzeti
                </Typography>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Építés összege" name="epitesOsszege" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Építés EUR összege" name="epitesEurOsszege" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Építés koncesszió" text=" összege 100M:" />

                    <FormTextField control={control} label="összeg" name="epitesKoncesszioOsszege" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Építés koncesszió" text=" EUR összege:" />
                    <FormTextField control={control} label="összeg" name="epitesKoncesszioEurOsszege" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Nyertes szállító neve" name="nyertesSzallitoNeveNemzetiEpites" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaNemzetiEpites" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="EUR árfolyam" name="eurArfolyamEpites" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaNemzetiEpites" />
                  </Grid>
                  <Grid item xs={12}>
                    1. Ellenajánlat
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveEpites1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaEpites1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeEpites1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamEpites1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaEpites1" />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    2. Ellenajánlat
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveEpites2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaEpites2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeEpites2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamEpites2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaEpites2" />
                  </Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: "10px" }}></Divider>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Árubeszerzés" text=" összege 15M Ft:" />
                    <FormTextField control={control} label="összeg" name="arubeszerzesOsszege" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Árubeszerzés" text="EUR összege:" />
                    <FormTextField control={control} label="összeg" name="arubeszerzesEurOsszege" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Nyertes szállító neve" name="nyertesSzallitoNeveNemzetiAru" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaNemzetiNemzetiAru" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeNemzetiAru" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaNemzetiAru" />
                  </Grid>
                  <Grid item xs={12}>
                    1. Ellenajánlat
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveArubeszerzes1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaArubeszerzes1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeArubeszerzes1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamArubeszerzes1" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaArubeszerzes1" />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    2. Ellenajánlat
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveArubeszerzes2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaArubeszerzes2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeArubeszerzes2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamArubeszerzes2" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaArubeszerzes2" />
                  </Grid>

                  <Grid item xs={6}></Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: "10px" }}></Divider>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Szolgáltatás" text="összege max. 15M:" />
                    <FormTextField control={control} label="összeg" name="szolgaltatasOsszege" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Szolgáltatás" text="EUR összege:" />
                    <FormTextField control={control} label="összeg" name="szolgaltatasEurOsszege" />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Szolgáltatás koncesszió" text="összege max. 30M:" />
                    <FormTextField control={control} label="összeg" name="szolgaltatasKoncesszioOsszege" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Szolgáltatás koncesszió" text="EUR összege:" />
                    <FormTextField control={control} label="összeg" name="szolgaltatasKoncesszioEurOsszege" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Nyertes szállító neve" name="nyertesSzallitoNeveNemzetiAru" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaNemzetiSzolgaltatas" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeNemzetiSzolgaltatas" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaNemzetiSzolgaltatas" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField control={control} label="1. Ellenajánlat" name="elsoEllenajanlatNemzetiSzolgaltatas" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField control={control} label="2. Ellenajánlat" name="masodikEllenajanlatNemzetiSzolgaltatas" />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Pénzügy
                </Typography>
                <Grid container spacing={2}>
                  {auth.role === "Admin" && (
                    <>
                      <Grid item xs={12} md={6}>
                        <FormTextField control={control} label="Sikerdíj mértéke" name="sikerdijMerteke" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormTextField control={control} label="Sikerdíj" name="sikerdij" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormTextField control={control} label="Várható bevétel" name="varhatoBevetel" />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Előleg" name="eloleg" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Projekt költségvetés" name="projektKoltsegvetes" />
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Dátumok
                </Typography>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={6}>
                    <Box sx={{ mb: "10px" }}>
                      <span style={{ fontWeight: "500" }}>Hiánypótlás</span> határideje <br></br>(max. 5 nap):
                    </Box>
                    <FormDate control={control} label="Dátum" name="hianypotlasHatarideje" width="100%" />
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ mb: "10px" }}>
                      <span style={{ fontWeight: "500" }}>Javítás</span> határideje <br></br>(max. 3 nap):
                    </Box>
                    <FormDate control={control} label="Dátum" name="javitasHatarideje" width="100%" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Jóváhagyás napja" name="jovahagyasNapja" width="100%" />
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Eljárás - EU
                </Typography>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Építés" text="összege max 1.704.296.000:" />
                    <FormTextField control={control} label="összeg" name="epitesOsszegeEU" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Építés" text="EUR összege max 5.350.000 EUR:" />
                    <FormTextField control={control} label="összeg" name="epitesEurOsszegeEU" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Építés koncesszió" text="összege max 1.704.296.000 HUF:" />
                    <FormTextField control={control} label="összeg" name="epitesKoncesszioOsszegeEU" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Építés koncesszió" text="EUR összege max 5.350.000 EUR:" />
                    <FormTextField control={control} label="összeg" name="epitesKoncesszioEurOsszegeEU" />
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Nyertes szállító neve" name="nyertesSzallitoNeveEpites" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaEpites" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeEpites" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaEpites" />
                  </Grid>
                  <Grid item xs={12}>
                    1. Ellenajánlat
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveEpites1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaEpites1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeEpites1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamEpites1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaEpites1EU" />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    2. Ellenajánlat
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveEpites2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaEpites2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeEpites2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamEpites2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaEpites2EU" />
                  </Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: "10px" }}></Divider>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Árubeszerzés" text="összege 68.171.840 HUF:" />
                    <FormTextField control={control} label="összeg" name="arubeszerzesOsszegeEU" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledLabel bold="Árubeszerzés" text="EUR összege 214.000 EUR:" />
                    <FormTextField control={control} label="összeg" name="arubeszerzesEurOsszegeEU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Nyertes szállító neve" name="nyertesSzallitoNeveSzolgaltatas" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaSzolgaltatas" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeSzolgaltatas" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaSzolgaltatas" />
                  </Grid>
                  <Grid item xs={12}>
                    1. Ellenajánlat
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveArubeszerzes1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaArubeszerzes1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeArubeszerzes1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamArubeszerzes1EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaArubeszerzes1EU" />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    2. Ellenajánlat
                  </Grid>

                  <Grid item xs={6}>
                    <FormTextField control={control} label="Szállító neve" name="szallitoNeveArubeszerzes2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="adószáma" name="adoszamaArubeszerzes2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Ajánlat összege" name="ajanlatOsszegeArubeszerzes2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} label="Eur árfolyam" name="eurArfolyamArubeszerzes2EU" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} label="Ajánlat időppontja" name="ajanlatIdopontjaArubeszerzes2EU" />
                  </Grid>

                  <Grid item xs={6}></Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: "10px" }}></Divider>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ p: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt fájlok
                </Typography>
                <Box>
                  <Button onClick={handleClick} variant="outlined" endIcon={<FileUploadIcon />}>
                    Projekt fájlok
                  </Button>
                </Box>
                <Box sx={{ marginTop: "10px" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fájl neve</TableCell>
                          <TableCell>Kategória</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {docFiles &&
                          docFiles.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {item.docName}
                                {item.company && (
                                  <>
                                    <br />
                                    Cég: {item.company}
                                  </>
                                )}
                              </TableCell>
                              <TableCell>{item.docCategory}</TableCell>
                              <TableCell sx={{}}>
                                <IconButton onClick={() => handleDelete(index, item)}>
                                  <DeleteIcon></DeleteIcon>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                  <MenuItem value={"Dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Dokumentumok");
                        }}
                      />
                      Dokumentumok
                    </label>
                  </MenuItem>
                  <MenuItem value={"Benyújtott dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file2"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file2"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Benyújtott dokumentumok");
                        }}
                      />
                      Benyújtott dokumentumok
                    </label>
                  </MenuItem>
                  <MenuItem value={"Hiánypótlás értesítő"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file16"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file16"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Hiánypótlás értesítő");
                        }}
                      />
                      Hiánypótlás értesítő
                    </label>
                  </MenuItem>
                  <MenuItem value={"Hiánypótlási dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file17"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file17"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Hiánypótlási dokumentumok");
                        }}
                      />
                      Hiánypótlási dokumentumok
                    </label>
                  </MenuItem>
                  <MenuItem value={"Bírálat"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file18"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file18"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Bírálat");
                        }}
                      />
                      Bírálat
                    </label>
                  </MenuItem>
                  <MenuItem value={"Szerződés - kiküldött"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file19"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file19"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Szerződés - kiküldött");
                        }}
                      />
                      Szerződés - kiküldött
                    </label>
                  </MenuItem>
                  <MenuItem value={"Szerződés - aláírt"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file20"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file20"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Szerződés - aláírt");
                        }}
                      />
                      Szerződés - aláírt
                    </label>
                  </MenuItem>
                </Menu>
              </Card>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Card sx={{ p: "20px", mt: "20px" }}>
                <Typography sx={{ fontWeight: "500", fontSize: "1.1em" }}>Ajánlatkérő Hirdetmény útján közzétett:</Typography>
                <Divider sx={{ my: "10px" }}></Divider>
                <Controller
                  name="ajanlatkeroHirdetmenyUtjanKozzetett"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" {...field}>
                        <FormControlLabel value="az előzetes tájékoztatót" control={<Radio />} label="az előzetes tájékoztatót" />
                        <FormControlLabel
                          value="a nyílt eljárást megindító ajánlati felhívást"
                          control={<Radio />}
                          label="a nyílt eljárást megindító ajánlati felhívást"
                        />
                        <FormControlLabel
                          value="a meghívásos, illetve tárgyalásos eljárást, valamint versenypárbeszédet, illetve
                          innovációs partnerséget megindító részvételi felhívást, kivéve az előzetes
                          tájékoztatót tartalmazó hirdetménnyel, valamint a külön jogszabály szerinti
                          időszakos előzetes tájékoztatót tartalmazó hirdetménnyel meghirdetett
                          meghívásos és tárgyalásos eljárás, továbbá külön jogszabály szerinti
                          előminősítési hirdetménnyel meghirdetett meghívásos és tárgyalásos eljárás,
                          versenypárbeszéd, illetve innovációs partnerség során küldött közvetlen
                          részvételi felhívást; (ha a dőlt betűs részre a kurzorral ráállna, akkor ez az infó
                          rész megjelenne (ha megoldható…)"
                          control={<Radio />}
                          label={
                            <>
                              a meghívásos, illetve tárgyalásos eljárást, valamint versenypárbeszédet, illetve innovációs partnerséget megindító
                              részvételi felhívást,{" "}
                              <span style={{ fontWeight: "600" }}>
                                kivéve az előzetes tájékoztatót tartalmazó hirdetménnyel, valamint a külön jogszabály szerinti időszakos előzetes
                                tájékoztatót tartalmazó hirdetménnyel meghirdetett meghívásos és tárgyalásos eljárás, továbbá külön jogszabály
                                szerinti előminősítési hirdetménnyel meghirdetett meghívásos és tárgyalásos eljárás, versenypárbeszéd, illetve
                                innovációs partnerség során küldött közvetlen részvételi felhívást; (ha a dőlt betűs részre a kurzorral ráállna, akkor
                                ez az infó rész megjelenne (ha megoldható…)
                              </span>
                            </>
                          }
                        />
                        <FormControlLabel
                          value="a 117. §-ban szabályozott saját beszerzési szabályok szerint folytatott eljárást
                          megindító felhívást; szintén csak az eljárást megindító rész lenne a felsorolás, a
                          kiemelt pedig az infó"
                          control={<Radio />}
                          label={
                            <>
                              <span style={{ fontWeight: "600" }}>a 117. §-ban szabályozott saját beszerzési szabályok szerint folytatott</span>{" "}
                              eljárást megindító felhívást; szintén csak az eljárást megindító rész lenne a felsorolás, a kiemelt pedig az infó
                            </>
                          }
                        />
                        <FormControlLabel
                          value="időszakos előzetes tájékoztatót és az előminősítési hirdetményt"
                          control={<Radio />}
                          label="időszakos előzetes tájékoztatót és az előminősítési hirdetményt"
                        />
                        <FormControlLabel value="a tervpályázati kiírást" control={<Radio />} label="a tervpályázati kiírást" />
                        <FormControlLabel
                          value="a koncessziós beszerzési eljárás 128. § és a 129. § (2) bekezdése szerinti eljárás
                          kivételével"
                          control={<Radio />}
                          label={
                            <>
                              a koncessziós beszerzési eljárás{" "}
                              <span style={{ fontWeight: "600" }}>128. § és a 129. § (2) bekezdése szerinti eljárás kivételével</span>
                            </>
                          }
                        />
                        <FormControlLabel
                          value="az eljárás eredményéről szóló tájékoztató"
                          control={<Radio />}
                          label="az eljárás eredményéről szóló tájékoztató"
                        />
                        <FormControlLabel
                          value="a tervpályázati eljárás eredményéről szóló tájékoztató"
                          control={<Radio />}
                          label="a tervpályázati eljárás eredményéről szóló tájékoztató"
                        />
                        <FormControlLabel
                          value="a szerződés módosításáról szóló tájékoztató"
                          control={<Radio />}
                          label="a szerződés módosításáról szóló tájékoztató"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ p: "20px", mt: "20px" }}>
                <Typography sx={{ fontWeight: "500", fontSize: "1.1em" }}>Ajánlatkérő Közbeszerzési Adatbázisban közzétett:</Typography>
                <Divider sx={{ my: "10px" }}></Divider>

                <Controller
                  name="ajanlatkeroKozbeszerzesiAdatbazisbanKozzetett"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" {...field}>
                        <FormControlLabel
                          value="a közbeszerzési tervet, valamint annak módosítását az elfogadást követően
                          haladéktalanul"
                          control={<Radio />}
                          label={
                            <>
                              a közbeszerzési tervet, valamint annak módosítását az{" "}
                              <span style={{ fontWeight: "600" }}> elfogadást követően haladéktalanul</span>
                            </>
                          }
                        />

                        <FormControlLabel
                          value="vitarendezési kérelem kézhezvételét követően haladéktalanul"
                          control={<Radio />}
                          label={
                            <>
                              vitarendezési kérelem <span style={{ fontWeight: "600" }}>kézhezvételét követően haladéktalanul</span>
                            </>
                          }
                        />

                        <FormControlLabel
                          value="megkötött szerződéseket a szerződéskötést követően haladéktalanul a 9. § (1)
                          bekezdés h)-i) pontjának, valamint a 12. § (1)-(5) bekezdésének alkalmazásával"
                          control={<Radio />}
                          label={
                            <>
                              megkötött szerződéseket{" "}
                              <span style={{ fontWeight: "600" }}>
                                a szerződéskötést követően haladéktalanul a 9. § (1) bekezdés h)-i) pontjának, valamint a 12. § (1)-(5) bekezdésének
                                alkalmazásával
                              </span>
                            </>
                          }
                        />

                        <FormControlLabel
                          value="a részvételi jelentkezések és az ajánlatok elbírálásáról szóló összegezést, a
                          részvételre jelentkezőknek vagy az ajánlattevőknek való megküldéssel
                          egyidejűleg"
                          control={<Radio />}
                          label={
                            <>
                              a részvételi jelentkezések és az ajánlatok elbírálásáról szóló összegezést,{" "}
                              <span style={{ fontWeight: "600" }}>
                                a részvételre jelentkezőknek vagy az ajánlattevőknek való megküldéssel egyidejűleg
                              </span>
                            </>
                          }
                        />

                        <FormControlLabel
                          value="a közbeszerzési eljárást megindító hirdetmény (hirdetmény nélkül induló eljárások
                            esetében felhívásra)"
                          control={<Radio />}
                          label="a közbeszerzési eljárást megindító hirdetmény (hirdetmény nélkül induló eljárások
                            esetében felhívásra)"
                        />

                        <FormControlLabel
                          value="a külön jogszabályban meghatározott éves statisztikai összegzés"
                          control={<Radio />}
                          label="a külön jogszabályban meghatározott éves statisztikai összegzés"
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ p: "20px", mt: "20px" }}>
                <Typography sx={{ fontWeight: "500", fontSize: "1.1em" }}>Jogorvoslat:</Typography>
                <Divider sx={{ my: "10px" }}></Divider>

                <Controller
                  name="jogorvoslat"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" {...field}>
                        <Box
                          sx={{
                            fontWeight: "500",
                            fontSize: "1.1em",
                            ml: "10px",
                          }}
                        >
                          Javítás
                        </Box>
                        <FormControlLabel
                          sx={{ ml: "20px" }}
                          value="részvételi szakasz eredményének megküldését követő tíz napon"
                          control={<Radio />}
                          label={
                            <>
                              részvételi szakasz eredményének megküldését követő <span style={{ fontWeight: "600" }}>tíz napon</span>
                            </>
                          }
                        />

                        <FormControlLabel
                          sx={{ ml: "20px" }}
                          value="Az ajánlatkérő az eredményhirdetéstől számított huszadik napig egy alkalommal"
                          control={<Radio />}
                          label={
                            <>
                              Az ajánlatkérő az eredményhirdetéstől számított <span style={{ fontWeight: "600" }}>huszadik napig </span>
                              egy alkalommal
                            </>
                          }
                        />

                        <FormControlLabel
                          sx={{ ml: "20px" }}
                          value="többszakaszos eljárások részvételi szakaszában az ajánlatkérő a részvételi
                          jelentkezések elbírálásáról készített írásbeli összegezést az ajánlattételi határidő
                          lejártáig"
                          control={<Radio />}
                          label={
                            <>
                              többszakaszos eljárások részvételi szakaszában az ajánlatkérő a részvételi jelentkezések elbírálásáról készített
                              írásbeli összegezést <span style={{ fontWeight: "600" }}>az ajánlattételi határidő lejártáig</span>
                            </>
                          }
                        />
                        <Box
                          sx={{
                            fontWeight: "500",
                            fontSize: "1.1em",
                            ml: "10px",
                            mt: "15px",
                          }}
                        >
                          Előzetes vitarendezési kérelem
                        </Box>
                        <FormControlLabel
                          sx={{ ml: "20px" }}
                          value="ajánlattevő a jogsértő eseményről való
                          tudomásszerzést követő 3 munkanapon belül"
                          control={<Radio />}
                          label={<>ajánlattevő a jogsértő eseményről való tudomásszerzést követő 3 munkanapon belül</>}
                        />

                        <FormControlLabel
                          sx={{ ml: "20px" }}
                          value="felhívás vagy a dokumentáció, akkor az ajánlattevő vagy kamara az
                          ajánlattételi/részvételi határidőt megelőző 10. napig, gyorsított, vagy hirdetmény
                          nélküli tárgyalásos eljárásban a részvételi vagy ajánlattételi határidőig, továbbá a
                          meghívásos vagy a tárgyalásos eljárást meghirdető előzetes tájékoztatóban a
                          részvételi szándék jelzésére meghatározott határidőig."
                          control={<Radio />}
                          label="felhívás vagy a dokumentáció, akkor az ajánlattevő vagy kamara az
                          ajánlattételi/részvételi határidőt megelőző 10. napig, gyorsított, vagy hirdetmény
                          nélküli tárgyalásos eljárásban a részvételi vagy ajánlattételi határidőig, továbbá a
                          meghívásos vagy a tárgyalásos eljárást meghirdető előzetes tájékoztatóban a
                          részvételi szándék jelzésére meghatározott határidőig."
                        />

                        <FormControlLabel
                          sx={{ mt: "10px" }}
                          value="15 napon belül kérelmező tudomására jutásától"
                          control={<Radio />}
                          label={
                            <>
                              <span style={{ fontWeight: "600" }}>15 napon belül</span> kérelmező tudomására jutásától
                            </>
                          }
                        />

                        <FormControlLabel
                          value="10 napon belül közbeszerzési eljárást lezáró jogsértő döntés"
                          control={<Radio />}
                          label={
                            <>
                              <span style={{ fontWeight: "600" }}>10 napon belül</span> közbeszerzési eljárást lezáró jogsértő döntés
                            </>
                          }
                        />

                        <FormControlLabel
                          value="5 napon belül nemzeti eljárásrendben a 115.§ szerinti hirdetmény nélküli eljárásban"
                          control={<Radio />}
                          label={
                            <>
                              <span style={{ fontWeight: "600" }}>5 napon belül</span> nemzeti eljárásrendben a 115.§ szerinti hirdetmény nélküli
                              eljárásban
                            </>
                          }
                        />

                        <FormControlLabel
                          value="ajánlattételi, illetőleg részvételi határidő lejártát megelőző ötödik napig  kivéve, ha az
                          előbb ismertetett határidők lennének hosszabbak, mert akkor azokat kell
                          alkalmazni."
                          control={<Radio />}
                          label={
                            <>
                              ajánlattételi, illetőleg részvételi határidő lejártát{" "}
                              <span style={{ fontWeight: "600" }}>
                                megelőző ötödik napig kivéve, ha az előbb ismertetett határidők lennének hosszabbak, mert akkor azokat kell
                                alkalmazni.
                              </span>
                            </>
                          }
                        />

                        <FormControlLabel
                          sx={{ mt: "10px" }}
                          value="ajánlatkérő saját maga ellen indított jogorvoslatának intézményét. Az ajánlati,
                          ajánlattételi vagy részvételi felhívással, a közbeszerzési dokumentumokkal, illetve ezek
                          módosításával kapcsolatban az ajánlatkérő a saját jogsértésével szemben az ajánlatokról
                          készített írásbeli összegezés megküldéséig jogosult kérelmet előterjeszteni."
                          control={<Radio />}
                          label={
                            <>
                              <span style={{ fontWeight: "600" }}>ajánlatkérő saját maga ellen</span> indított jogorvoslatának intézményét. Az
                              ajánlati, ajánlattételi vagy részvételi felhívással, a közbeszerzési dokumentumokkal, illetve ezek módosításával
                              kapcsolatban az ajánlatkérő a saját jogsértésével szemben az ajánlatokról készített{" "}
                              <span style={{ fontWeight: "600" }}>írásbeli összegezés megküldéséig</span> jogosult kérelmet előterjeszteni.
                            </>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", mt: "20px" }}>
            <Button type="submit" variant="contained" endIcon={<AddIcon />}>
              {state ? "Adatok mentése" : "Hozzáadás"}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default AddProcurement;
