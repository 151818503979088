import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const WorkHour = (props) => {
  const [data, setdata] = useState([]);


  const sumOfHour = () => {
    function timestrToSec(timestr) {
      var parts = timestr.split(":");
      return parts[0] * 3600 + parts[1] * 60 + +parts[2];
    }

    function pad(num) {
      if (num < 10) {
        return "0" + num;
      } else {
        return "" + num;
      }
    }

    function formatTime(seconds) {
      return [
        Math.floor(seconds / 3600),
        pad(Math.floor(seconds / 60) % 60),
        pad(seconds % 60),
      ].join(":");
    }

    let sum = 0;
    for (let item of data) {
      sum = sum + timestrToSec(item.time);
    }
    return formatTime(sum);
  };

  useEffect(() => {
    if (props.data) {
      const temp = [];
      for (const [key, value] of Object.entries(props.data)) {
        temp.push({ date: key.replaceAll("-", "."), time: value });
      }
      setdata(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={{ mt: "20px", padding: "20px" }}>
      <Typography variant="h6">Munkaóra nyilvántartás</Typography>
      {/* <Box
        sx={{
          display: "flex",
          gap: "20px",
          mt: "30px",
          flexWrap: "wrap",
          alignItems: "baseline",
        }}
      >
        <TextField
          label="Hét száma"
          value={value.week}
          onChange={(e) => setvalue({ ...value, week: e.target.value })}
        />
        <TextField
          label="Munka"
          sx={{ width: "300px" }}
          value={value.work}
          onChange={(e) => setvalue({ ...value, work: e.target.value })}
        />
        <TextField
          label="Munkaidő (perc)"
          value={value.minute}
          onChange={(e) => setvalue({ ...value, minute: e.target.value })}
        />
        <Button
          variant="contained"
          sx={{ height: "35px" }}
          onClick={() => handleSubmit()}
        >
          Hozzáadás
        </Button>
      </Box> */}
      <Table sx={{ mt: "30px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Nap</TableCell>
            <TableCell>Munkaidő</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && (
            <>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>{item.time}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <b>Összesen</b>
                </TableCell>
                <TableCell>
                  <b>{sumOfHour()}</b>
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

export default WorkHour;
