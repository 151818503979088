import React from "react";
import { Outlet } from "react-router";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  return (
    <>
      <Sidebar />
      <div style={{ marginLeft: "190px" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;
