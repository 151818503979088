import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useData } from "../../contexts/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSnackbar } from "notistack";
import { getDatabase, push, ref, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { DeepDiff } from "deep-diff";
import moment from "moment";
import Budget from "./Budget";
import Milestone from "./Milestone";
import Missing from "./Missing";
import Clarifying from "./Clarifying";
import MissingDocs from "./MissingDocs";
import ClarifyingDocs from "./ClarifyingDocs";
import hu from "date-fns/locale/hu";
import { useAuth } from "../../contexts/UserContext";

const statusChoices = [
  "benyújtott",
  "1. hiánypótlás",
  "2. hiánypótlás ",
  "3. hiánypótlás",
  "4. hiánypótlás",
  "5. hiánypótlás",
  "1. tisztázó kérdés",
  "2. tisztázó kérdés",
  "3. tisztázó kérdés",
  "4. tisztázó kérdés",
  "Jogosultsági döntés pozitív",
  "Jogsultsági döntés negatív",
  "Szerződéskötés alatt",
  "Támogatói Okirat",
  "Elszámolásra előkészíteni",
  "Elszámolás benyújtásra került",
  "Elszámolás 1. hiánypótlása",
  "Elszámolás 2. hiánypótlása",
  "Elszámolás 3. hiánypótlása",
  "Elszámolás 4. hiánypótlása",
  "Elszámolás 5. hiánypótlása",
  "Elszámolás 1. tisztázó kérdés",
  "Elszámolás 2. tisztázó kérdés",
  "Elszámolás 3. tisztázó kérdés",
  "Elszámolás 4. tisztázó kérdés",
  "Elszámolás 5. tisztázó kérdés",
  "1. fenntartási időszaki jelentés esedékes",
  "1. fenntartási időszaki jelentés 1. hiánypótlása",
  "1. fenntartási időszaki jelentés 1. hiánypótlása teljesítve",
  "1. fenntartási időszaki jelentés 2. hiánypótlása",
  "1. fenntartási időszaki jelentés 2. hiánypótlása teljesítve",
  "1. fenntartási időszaki jelentés 1. tisztázó kérdés",
  "1. fenntartási időszaki jelentés 1. tisztázó kérdése teljesítve",
  "1. fenntartási időszaki jelentés 2. tisztázó kérdés",
  "1. fenntartási időszaki jelentés 2. tisztázó kérdése teljesítve",
  "1. fenntartási időszaki jelentés 3. tisztázó kérdés",
  "1. fenntartási időszaki jelentés 3. tisztázó kérdése teljesítve",
  "1. fenntartási időszaki jelentés teljesítve",
  "2. fenntartási időszaki jelentés esedékes ",
  "2. fenntartási időszaki jelentés 1. hiánypótlása",
  "2. fenntartási időszaki jelentés 1. hiánypótlása teljesítve",
  "2. fenntartási időszaki jelentés 2. hiánypótlása",
  "2. fenntartási időszaki jelentés 2. hiánypótlása teljesítve",
  "2. fenntartási időszaki jelentés 1. tisztázó kérdés",
  "2. fenntartási időszaki jelentés 1. tisztázó kérdése teljesítve",
  "2. fenntartási időszaki jelentés 2. tisztázó kérdés",
  "2. fenntartási időszaki jelentés 2. tisztázó kérdése teljesítve",
  "2. fenntartási időszaki jelentés 3. tisztázó kérdés",
  "2. fenntartási időszaki jelentés 3. tisztázó kérdése teljesítve",
  "2. fenntartási időszaki jelentés teljesítve",
  "Záró fenntartási időszaki jelentés esedékes",
  "Záró fenntartási időszaki jelentés 1. hiánypótlása",
  "Záró fenntartási időszaki jelentés 1. hiánypótlása teljesítve",
  "Záró fenntartási időszaki jelentés 2. hiánypótlása",
  "Záró fenntartási időszaki jelentés 2. hiánypótlása teljesítve",
  "Záró fenntartási időszaki jelentés 1. tisztázó kérdés",
  "Záró fenntartási időszaki jelentés 1. tisztázó kérdés teljesítve",
  "Záró fenntartási időszaki jelentés 2. tisztázó kérdés",
  "Záró fenntartási időszaki jelentés 2. tisztázó kérdés teljesítve",
  "Záró fenntartási időszaki jelentés teljesítve",
  "Lezárult pályázat",
];

const defaultdata = {
  statusz: "",
  projektAzonosito: "",
  cegnev: "",
  hitelOsszege: "",
  koltsegvetes: "",
  oneroOsszege: "",
  benyujtasIdopontja: null,
  hianypotlasHatarideje: null,
  tamogatoiOkiratKelte: null,
  tisztazoKerdesHatarideje: null,
  elolegMegerkezesenekDatuma: null,
  zaroElszamolasEsedekessege: null,
  elszamolasHianypotlasanakHatarideje: null,
  elszamolasTisztazoKerdesenekHatarideje: null,
  projektTervezettKezdete: null,
  projektTervezettBefejezese: null,
  projektTenylegesKezdete: null,
  projektTenylegesBefejezese: null,
  arfolyamEvVegen: "",
  arfolyamEvVegenEur: "",
  arfolyamOkiratKeltekor: "",
  palyazatiElolegOsszege: "",
  projektFelelose: "",
  tamogatasEurOsszege: "",
  tamogatasOsszege: "",
  cegEmail: "",
  projektMappaKesz: false,
  szerzodesmodositas: false,
  visszaerkezettAzAtadasatveteliFalse: false,
  visszaerkezettAzAtadasatveteliTrue: false,
  nyertesSzallitoNeve: "",
  adoszama: "",
  ajanlatOsszege: "",
  ajanlatIdopontja: "",
  projektCime: "",
  projektEgyeb: "",
  tamogatasMerteke: "",
  onero: "",
  projektKoltsegekElszamolasanakKezdete: "",
  palyazatBeadasKezedete: "",
  kapcsolattartoNeve: "",
  cegvezetoNeve: "",
  tamogatasiElolegOsszege: "",
  tamogatasiElolegFolyositasanakNapja: null,
  tamogatasiElolegElszamolandoMerteke: "",
  tamogatasiElolegElszamolandoOsszege: "",
  tamogatasiElolegElszamolasanakNapja: null,
  fenntartasiIdoszakiJelentesElso: "",
  fenntartasiIdoszakiJelentesMasodik: "",
  fenntartasiIdoszakiJelentesHarmadik: "",
  projektElszamolhazoKoltsege: "",
  tamogatasOsszege2: "",
  hianypotlasAtvetele: "",
  fenntartasiHianypotlasAtvetele: "",
  fenntartasiHianypotlasHatarideje: "",
  fenntartasiTisztazoKerdesAtvetele: "",
  fenntartasiTisztazoKerdesHatarideje: "",
  fenntartasiHianypotlasAtveteleMasodik: "",
  fenntartasiHianypotlasHataridejeMasodik: "",
  fenntartasiTisztazoKerdesAtveteleMasodik: "",
  fenntartasiTisztazoKerdesHataridejeMasodik: "",
  fenntartasiHianypotlasAtveteleHarmadik: "",
  fenntartasiHianypotlasHataridejeHarmadik: "",
  fenntartasiTisztazoKerdesAtveteleHarmadik: "",
  fenntartasiTisztazoKerdesHataridejeHarmadik: "",
  tamogatoiOkiratHatalybaLepese: "",
  tamogatasiElolegbenyujtasanakNapja: null,
  tamogatasiElolegelszamolasBenyujtasa: null,
  tamogatasiElolegElszamoltElolegOsszege: "",
  fenntartasiIdoszakiJelentesBekuldeseElso: null,
  fenntartasiIdoszakiJelentesBekuldeseMasodik: null,
  fenntartasiIdoszakiJelentesBekuldeseHarmadik: null,
  fenntartasiJelentesJovahagyasNapja: null,
  fenntartasiJelentesJovahagyasNapjaMasodik: null,
  fenntartasiJelentesJovahagyasNapjaHarmadik: null,
  oneroMerteke: "",
  projektCelja: "",
  fenntartasiHianypotlasBekuldesenekNapja: null,
  fenntartasiTisztazoKerdesBekuldesenekNapja: null,
  benyujtottKerelem: "",
  elszamolasJovahagyasanakDatuma: null,
};

const FormCheckBox = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          onClick={() => props.setforce && props.setforce(!props.force)}
          control={<Checkbox checked={field.value} onChange={field.onChange} />}
          label={props.label}
        />
      )}
    />
  );
};

const FormTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <TextField
            InputLabelProps={props.style}
            {...field}
            value={field.value ? field.value : ""}
            helperText={props.helperText}
            label={props.label}
            fullWidth
            InputProps={{
              endAdornment: props.end,
            }}
            type={props.type}
            inputProps={props.inputProps}
          />
        );
      }}
    />
  );
};

const FormDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <DatePicker
            inputFormat="yyyy.MM.dd."
            mask="____.__.__."
            value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
            onChange={(value) => {
              field.onChange(value?.toLocaleDateString() ? value?.toLocaleDateString() : null);
            }}
            label={props.label}
            renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FromFile = (props) => {
  return (
    <label
      htmlFor={props.name}
      style={{
        width: "100%",
        padding: "6px 16px 6px 16px",
        cursor: "pointer",
      }}
    >
      <input
        multiple
        style={{ display: "none" }}
        accept="application/pdf, .xlsx, .xls,.docx"
        id={props.name}
        type="file"
        onChange={(event) => {
          props.handleFileSelect(event, props.name);
        }}
      />
      {props.name}
    </label>
  );
};

const MissingDocs5 = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{}]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Támogatás összege"
                      defaultValue={item.tamogatasOsszege}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].tamogatasOsszege = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Támogatás EUR összege"
                      defaultValue={item.tamogatasEUROsszege}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].tamogatasEUROsszege = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "8px" }}>
                      Támogatói okirat kelte:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.tamogatoiOkiratKelte ? moment(item.tamogatoiOkiratKelte, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].tamogatoiOkiratKelte = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Dátum"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "8px" }}>
                      Támogatói okirat hatályba lépése:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.tamogatoiOkiratHatalybaLepese ? moment(item.tamogatoiOkiratHatalybaLepese, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].tamogatoiOkiratHatalybaLepese = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Dátum"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: "5px" }}></Divider>
                  </Grid>
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const MissingDocs6 = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{ name: "" }]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>{index + 1}. Biztosíték</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Biztosíték típusa"
                      defaultValue={item.biztositekTipusa}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].biztositekTipusa = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      label="Megjegyzés"
                      defaultValue={item.biztositekMegjegyzes}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].biztositekMegjegyzes = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: "5px" }}></Divider>
                  </Grid>
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const Input = memo(({ index, onChange, value }) => {
  const [data, setdata] = useState("");
  return (
    <TextField
      value={data}
      label="Cég neve:"
      onChange={(e) => setdata(e.target.value)}
      onBlur={() => onChange(data)}
      onKeyDown={(e) => e.stopPropagation()}
    />
  );
});

const AddApplications = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { handleSubmit, control, getValues, setValue } = useForm({
    defaultValues: state ? state : defaultdata,
  });
  const { customerData } = useData();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [docFiles, setdocFiles] = useState([]);
  const [missingDocs, setmissingDocs] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.hianypotlasDokumentumai);
  const [missingDocs2, setmissingDocs2] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.tisztazoKerdesDokumentumai);
  const [missingDocs5, setmissingDocs5] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.tamogatas);
  const [missingDocs6, setmissingDocs6] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.biztositek);
  const [missingDocs7, setmissingDocs7] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.koltsegvetesReszei);
  const [missingDocs8, setmissingDocs8] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.elszamolasMerfoldko);
  const [missingDocs9, setmissingDocs9] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.elszamolasHianypotlas);
  const [missingDocs10, setmissingDocs10] = useState(useData().applicationsData.find((item) => item?.key === state?.key)?.elszamolasTisztazoKerdes);

  const [force, setforce] = useState(false);
  const { userList } = useData();
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setdialog] = useState(false);
  const [dialog2, setdialog2] = useState(false);
  const [company, setcompany] = useState("");
  const auth = useAuth();

  useEffect(() => {
    if (state?.docURL) {
      setdocFiles(state.docURL);
    }
  }, [state?.docURL]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCegSelect = (item) => {
    if (item.docURL) {
      const temp = [...docFiles].filter((item) => {
        return item.import === undefined;
      });
      item.docURL.forEach((item) => {
        temp.push({
          docName: item.docName,
          docCategory: item.docCategory,
          docURL: item.docURL,
          import: true,
        });
        setdocFiles(temp);
      });
    }
  };

  const handleFileSelect = (event, name, company) => {
    const files = event.target.files;
    const tempArray = [...docFiles];
    const temp = [];

    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }

    for (let index = 0; index < temp.length; index++) {
      if (temp[index] !== undefined) {
        if (docFiles.length > 0) {
          if (docFiles.filter((e) => e.docName === temp[index].name).length > 0) {
            enqueueSnackbar("Ilyen elnevezésű fájl már létezik!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          } else {
            tempArray.push({
              file: temp[index],
              docCategory: name,
              docName: temp[index].name,
              company,
            });
          }
        } else {
          tempArray.push({
            file: temp[index],
            docCategory: name,
            docName: temp[index].name,
            company,
          });
        }
      }
    }
    setdocFiles(tempArray);

    handleClose();
    setcompany("");
  };

  const handleDelete = (index, doc) => {
    const date = new Date().toLocaleString() + "";
    const temp = [...docFiles];
    temp.splice(
      temp.findIndex((item) => item.docName === doc.docName),
      1
    );
    setdocFiles(temp);
    if (doc.docURL !== undefined && doc.import === undefined) {
      const db = getDatabase();
      const storage = getStorage();
      const dataRef = ref(db, "palyazatok/" + state.key);
      const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + doc.docName);
      update(dataRef, { docURL: temp });
      deleteObject(docRef);

      const tempLog = [...state.log];
      tempLog.push({ kind: "delete", name: doc.docName, date: date });
      state.log = tempLog;
    }
  };

  const handleSupportDocDelete = (index, doc) => {
    const date = new Date().toLocaleString() + "";
    const temp = [...docFiles];
    temp.splice(
      temp.findIndex((item) => item.docName === doc.docName),
      1
    );
    setdocFiles(temp);

    if (doc.docURL !== undefined) {
      const db = getDatabase();
      const storage = getStorage();
      const dataRef = ref(db, "palyazatok/" + state.key);
      const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + doc.docName);
      update(dataRef, { docURL: temp });
      deleteObject(docRef);
      const tempLog = state.log === undefined ? [] : [...state.log];
      tempLog.push({ kind: "delete", name: doc.docName, date: date });
      state.log = tempLog;
    }
  };

  const onSubmit = (formData) => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    let key = "";
    formData.hianypotlasDokumentumai = missingDocs;
    formData.tisztazoKerdesDokumentumai = missingDocs2;
    formData.tamogatas = missingDocs5;
    formData.biztositek = missingDocs6 ? missingDocs6 : [];
    formData.koltsegvetesReszei = missingDocs7;
    formData.elszamolasMerfoldko = missingDocs8 ? missingDocs8 : [];
    formData.elszamolasHianypotlas = missingDocs9;
    formData.elszamolasTisztazoKerdes = missingDocs10;

    if (state) {
      const dataRef = ref(database, "palyazatok/" + state.key);
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          delete formData[key];
        }
      });

      if (DeepDiff(state, formData) !== undefined) {
        let temp;
        if (state?.log) {
          temp = [...state.log];
        } else {
          temp = [];
        }
        const log = DeepDiff(state, formData);
        log.forEach((element) => {
          element.date = date;
          if (element.path[0] !== "log") {
            temp.push(element);
          }
        });
        formData.log = temp;
      }

      formData.modositasDatuma = date;
      update(dataRef, formData);
      if (docFiles.length > 0) {
        const storage = getStorage();
        let urls = [];
        // if (state?.docURL) {
        //   urls = [...state.docURL];
        // } else {
        //   urls = [];
        // }
        docFiles.forEach((item) => {
          if (item.docURL === undefined) {
            const str = storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName);
            const tempLog = [...formData.log];
            tempLog.push({ kind: "upload", name: item.docName, date: date });
            update(ref(database, "palyazatok/" + state.key), {
              log: tempLog,
            });
            uploadBytes(str, item.file).then(
              () => {
                getDownloadURL(storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName))
                  .then((url) => {
                    urls.push({
                      docName: item.docName,
                      docURL: url,
                      docCategory: item.docCategory,
                      company: item.company ? item.company : "",
                      nev: item.nev ? item.nev : "",
                      osszeg: item.osszeg ? item.osszeg : "",
                    });
                  })
                  .then(() => {
                    update(ref(database, "palyazatok/" + state.key), {
                      docURL: urls,
                    });
                  });
              },
              (err) => {
                console.log(err);
              }
            );
          } else {
            urls.push(item);
            update(ref(database, "palyazatok/" + state.key), {
              docURL: urls,
            });
          }
        });
      } else {
        update(ref(database, "palyazatok/" + state.key), {
          docURL: [],
        });
      }
      navigate(-1);
    } else {
      formData.rogzitesDatuma = date;
      let count = 0;
      const dataRef = ref(database, "/palyazatok");
      try {
        push(dataRef, formData).then(async (snap) => {
          key = snap.key;
          if (docFiles.length > 0) {
            const storage = getStorage();
            let urls = [];

            const upload = new Promise((resolve, reject) => {
              docFiles.forEach((file, index) => {
                if (file.import) {
                  urls.push({
                    docName: file.docName,
                    docURL: file.docURL,
                    docCategory: file.docCategory,
                    import: true,
                  });
                  count = count + 1;
                  if (count === docFiles.length) {
                    resolve();
                  }
                } else {
                  const str = storageRef(storage, "dokumentumok/" + key + "_" + file.docName);
                  uploadBytes(str, file.file).then(() => {
                    getDownloadURL(storageRef(storage, "dokumentumok/" + key + "_" + file.docName)).then((url) => {
                      urls.push({
                        docName: file.docName,
                        docURL: url,
                        docCategory: file.docCategory,
                        company: file.company ? file.company : "",
                        nev: file.nev ? file.nev : "",
                        osszeg: file.osszeg ? file.osszeg : "",
                      });
                      count = count + 1;
                      if (count === docFiles.length) {
                        resolve();
                      }
                    });
                  });
                }
              });
            });
            upload.then(() => {
              update(ref(database, "palyazatok/" + key), {
                docURL: urls,
              });
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
      navigate(-1);
    }
  };

  const getStatusList = () => {
    if (state?.statusz) {
      if (!statusChoices.includes(state?.statusz)) {
        statusChoices.push(state?.statusz);
      }
    }
    return statusChoices;
  };

  return (
    <>
      <Container sx={{ marginTop: "20px" }}>
        <Box sx={{ padding: "20px" }}>
          <Card sx={{ padding: "20px", marginBottom: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5">{state ? "Pályázat szerkesztése" : "Új pályázat bevitele"}</Typography>
              <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
                Vissza
              </Button>
            </Box>
          </Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginBottom: "20px" }}>
                      Cég kiválasztása
                    </Typography>
                    <FormControl sx={{ width: "100%", mb: "30px" }}>
                      <InputLabel id="demo-simple-select-label">Cég</InputLabel>
                      <Controller
                        name="cegnev"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Cég" fullWidth>
                            <MenuItem
                              value=""
                              onClick={() => {
                                const temp = [...docFiles].filter((item) => {
                                  return item.import === undefined;
                                });
                                setdocFiles(temp);
                              }}
                            >
                              <em>Egyik sem</em>
                            </MenuItem>
                            {customerData &&
                              customerData.map((item, index) => (
                                <MenuItem key={index} value={item.cegnev} onClick={() => handleCegSelect(item)}>
                                  {item.cegnev}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {(auth.role === "Admin" || auth.role === "Irodavezető") && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormTextField
                          control={control}
                          label="Cégvezető neve"
                          name="cegvezetoNeve"
                          helperText="Több email esetén vesszővel elválasztva"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField control={control} label="Kapcsolattartó neve" name="kapcsolattartoNeve" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          control={control}
                          width="200px"
                          label="Cég Email címe"
                          name="cegEmail"
                          helperText="Több email esetén vesszővel elválasztva"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Card>
                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Beérkezett dokumentumok
                  </Typography>
                  <Button onClick={handleClick} variant="outlined" endIcon={<FileUploadIcon />}>
                    Dokumentum kiválasztása
                  </Button>
                  <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                    <MenuItem value={"Aláírási címpéldány"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Aláírási címpéldány"} />
                    </MenuItem>
                    <MenuItem value={"Társasági szerződés"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Társasági szerződés"} />
                    </MenuItem>
                    <MenuItem value={"Alapító okirat"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Alapító okirat"} />
                    </MenuItem>
                    <MenuItem value={"Aláírás minta"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Aláírás minta"} />
                    </MenuItem>
                    <MenuItem value={"Aláírási címpéldány"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Aláírási címpéldány"} />
                    </MenuItem>
                    <MenuItem value={"Tagjegyzék"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Tagjegyzék"} />
                    </MenuItem>
                    <MenuItem value={"TAO bevallás"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"TAO bevallás"} />
                    </MenuItem>
                    <MenuItem
                      value={"Disztribútori"}
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        setdialog(true);
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                          padding: "6px 16px 6px 16px",
                          cursor: "pointer",
                        }}
                      >
                        Disztribútori
                      </Box>
                    </MenuItem>
                    <Dialog open={dialog} onClose={() => setdialog(false)}>
                      <Card
                        sx={{
                          padding: "80px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Input onChange={setcompany} />
                        <label
                          htmlFor="contained-button-file8"
                          style={{
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file8"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Disztribútori", company);
                              setdialog(false);
                            }}
                          />
                          <Button component="span" variant="contained">
                            Fájl kiválasztása
                          </Button>
                        </label>
                      </Card>
                    </Dialog>
                    <MenuItem
                      value={"Árajánlat"}
                      sx={{ padding: "0px" }}
                      onClick={() => {
                        setdialog2(true);
                      }}
                    >
                      <Box
                        style={{
                          width: "100%",
                          padding: "6px 16px 6px 16px",
                          cursor: "pointer",
                        }}
                      >
                        Árajánlat
                      </Box>
                    </MenuItem>
                    <Dialog open={dialog2} onClose={() => setdialog2(false)}>
                      <Card
                        sx={{
                          padding: "80px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Input onChange={setcompany} />
                        <label
                          htmlFor="contained-button-file9"
                          style={{
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file9"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Árajánlat", company);
                              setdialog2(false);
                            }}
                          />
                          <Button component="span" variant="contained">
                            Fájl kiválasztása
                          </Button>
                        </label>
                      </Card>
                    </Dialog>
                    <MenuItem value={"Képek telephelyről"} sx={{ padding: "0px" }}>
                      <label
                        htmlFor="contained-button-file10"
                        style={{
                          width: "100%",
                          padding: "6px 16px 6px 16px",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="image/*"
                          id="contained-button-file10"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Képek telephelyről");
                          }}
                        />
                        Képek telephelyről
                      </label>
                    </MenuItem>
                    <MenuItem value={"Egyéb"} sx={{ padding: "0px" }}>
                      <FromFile handleFileSelect={handleFileSelect} name={"Egyéb"} />
                    </MenuItem>
                  </Menu>
                  <Box sx={{ marginTop: "10px" }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Fájl neve</TableCell>
                            <TableCell>Kategória</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return (
                                  item.docCategory !== "Támogatói Okirat" &&
                                  item.docCategory !== "Nyertes árajánlat" &&
                                  item.docCategory !== "Ellenajánlatok 1." &&
                                  item.docCategory !== "Ellenajánlatok 2." &&
                                  item.docCategory !== "Nyertes árajánlat" &&
                                  item.docCategory !== "Elszámolás tisztázó kérdés" &&
                                  item.docCategory !== "Elszámolás hiánypótlás" &&
                                  item.docCategory !== "1. Fenntartási hiánypótlás" &&
                                  item.docCategory !== "2. Fenntartási hiánypótlás" &&
                                  item.docCategory !== "3. Fenntartási hiánypótlás" &&
                                  item.docCategory !== "1. Fenntartási tisztázó kérdés" &&
                                  item.docCategory !== "2. Fenntartási tisztázó kérdés" &&
                                  item.docCategory !== "3. Fenntartási tisztázó kérdés" &&
                                  item.docCategory !== "Támogatási előleg" &&
                                  item?.admin === undefined &&
                                  item.docCategory !== "Tisztázó kérdés" &&
                                  item.docCategory !== "Hiánypótlás" &&
                                  !item.docCategory.includes("Nyertes árajánlat") &&
                                  !item.docCategory.includes("Ellenajánlat") &&
                                  !item.docCategory.includes("Elszámolás hiánypótlás") &&
                                  !item.docCategory.includes("Elszámolás tisztázó kérdés") &&
                                  !item.docCategory.includes("Fenntartási jelentés") &&
                                  !item.docCategory.includes("Tisztázó kérdés") &&
                                  !item.docCategory.includes("Hiánypótlás") &&
                                  !item.docCategory.includes("Elszámolás") &&
                                  !item.docCategory.includes("Benyújtott kérelem") &&
                                  !item.docCategory.includes("Jóváhagyó határozat") &&
                                  !item.docCategory.includes("Fenntartási időszaki jelentés fáj") &&
                                  !item.docCategory.includes("2. Fenntartási időszaki jelentés fájl")
                                );
                              })
                              .map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {item.docName}
                                    {item.company && (
                                      <>
                                        <br />
                                        Cég: {item.company}
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell>{item.docCategory}</TableCell>
                                  <TableCell sx={{}}>
                                    <IconButton onClick={() => handleDelete(index, item)}>
                                      <DeleteIcon></DeleteIcon>
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Card>

                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Projekt adatok
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label-user">Projekt felelőse</InputLabel>
                        <Controller
                          name="projektFelelose"
                          control={control}
                          render={({ field }) => (
                            <Select {...field} labelId="demo-simple-select-label-user" id="demo-simple-select-user" label="Projekt felelőse">
                              {userList &&
                                userList.map((item, index) => (
                                  <MenuItem key={index} value={item.email}>
                                    {item.email}
                                  </MenuItem>
                                ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <FormTextField control={control} name="statusz" label="Projekt státusza" select></FormTextField> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label-user">Projekt státusza</InputLabel>
                        <Controller
                          name="statusz"
                          control={control}
                          render={({ field }) => (
                            <Select {...field} label="Projekt státusza" defaultValue="">
                              {getStatusList().map((item, index) => (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormDate control={control} label="Pályázat beadás kezdete" name="palyazatBeadasKezedete" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormTextField control={control} label="Projekt azonosító" name="projektAzonosito" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField control={control} label="A pályázat címe" name="projektCime" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormTextField control={control} label="Projekt célja" name="projektCelja" />
                    </Grid>
                    <Grid container spacing={2} item>
                      <Grid item xs={12} sx={{ marginTop: "15px" }}>
                        <Typography>Projekt célja:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormCheckBox control={control} name="eszkozBeszerzes" label="eszközbeszerzés" />
                        <FormCheckBox control={control} name="ingatlanEpites" label="ingatlan építés" />
                        <FormCheckBox control={control} name="ingatlanVasarlas" label="ingatlan vásárlás" />

                        <FormCheckBox control={control} name="felujitas" label="felújítás" />
                        <FormCheckBox control={control} name="korszerusites" label="korszerűsítés" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormCheckBox control={control} name="megujuloEnergia" label="megújuló energia" />
                        <FormCheckBox control={control} name="munkaeroFelvetel" label="munkaerő felvétel" />
                        <FormCheckBox control={control} name="munkaeroMegtartas" label="munkaerő megtartás" />
                        <FormCheckBox control={control} name="kutatasFejlesztes" label="kutatás fejlesztés" />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField control={control} name="projektEgyeb" label="Egyéb" />
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>

                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Költségvetés részei
                  </Typography>
                  <Budget
                    handleFileSelect={handleFileSelect}
                    docFiles={docFiles}
                    set={setmissingDocs7}
                    data={missingDocs7}
                    handleSupportDocDelete={handleSupportDocDelete}
                  />
                </Card>

                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Támogatás
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormTextField control={control} name="benyujtottKerelem" label="Benyújtott kérelem" />
                    </Grid>
                    <Grid item xs={12} md={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-support-kerelem"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-support-kerelem"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Benyújtott kérelem");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Benyújtott kérelem
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "Benyújtott kérelem";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={index + 1 + ". " + doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-support"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-support"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Támogatói Okirat");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Támogatói Okirat
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "Támogatói Okirat";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={index + 1 + ". " + doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                      <MissingDocs5 data={missingDocs5} set={setmissingDocs5} />
                    </Grid>
                    {/* <Grid item md={6}></Grid>

                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        name="tamogatasOsszege"
                        label="Támogatás összege"
                        end={<InputAdornment position="end">Ft</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        name="tamogatasEurOsszege"
                        label="Támogatás EUR összege"
                        end={<InputAdornment position="end">€</InputAdornment>}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ mt: "23px" }}>
                      <FormDate control={control} name="tamogatoiOkiratKelte" label="Támogatói Okirat kelte" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="caption">Támogatói Okirat hatályba lépésének napja</Typography>
                      <FormDate control={control} name="tamogatoiOkiratHatalybaLepese" label="Dátum" />
                    </Grid> */}

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ mb: "8px" }}>
                        Deviza árfolyam támogatói Okirat keltekor:
                      </Typography>
                      <FormTextField control={control} name="arfolyamOkiratKeltekor" label="Árfolyam" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ mb: "8px" }}>
                        Jóváhagyás dec. 31-én érvényes dev. árf.:
                      </Typography>
                      <FormTextField control={control} name="arfolyamEvVegen" label="Árfolyam" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ mb: "8px" }}>
                        Év végi árfolyam szerinti támogatás EUR összege:
                      </Typography>
                      <FormTextField
                        control={control}
                        name="arfolyamEvVegenEur"
                        label="Árfolyam"
                        end={<InputAdornment position="end">€</InputAdornment>}
                      />
                    </Grid>
                  </Grid>
                </Card>
                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Időpontok
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                      <FormDate control={control} name="benyujtasIdopontja" label="Pályázat benyújtásának időpontja" width="300px" />
                      <IconButton
                        style={{
                          maxWidth: "30px",
                          maxHeight: "30px",
                          minWidth: "30px",
                          minHeight: "30px",
                          marginLeft: "10px",
                        }}
                        onClick={() => setValue("benyujtasIdopontja", null)}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <FormDate
                        width="100%"
                        control={control}
                        label="A Projekt költségek elszámolhatóságának kezdő időpontja:"
                        name="projektKoltsegekElszamolasanakKezdete"
                      />
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                      <FormDate control={control} name="projektTervezettKezdete" label="Projekt tervezett kezdete" />
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                      <FormDate control={control} name="projektTervezettBefejezese" label="Projekt tervezett befejezése" />
                    </Grid>
                    {getValues("modosultTervezettKezdet") === true ? (
                      getValues("modosultTervezettBefejezes") === true ? (
                        <>
                          <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                            <FormDate control={control} name="projektTenylegesKezdete" label="Projekt tényleges kezdete" />
                          </Grid>
                          <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                            <FormDate control={control} name="projektTenylegesBefejezese" label="Projekt tényleges befejezése" />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                            <FormDate control={control} name="projektTenylegesKezdete" label="Projekt tényleges kezdete" />
                          </Grid>
                          <Grid item xs={12} md={6}></Grid>
                        </>
                      )
                    ) : (
                      getValues("modosultTervezettBefejezes") === true && (
                        <>
                          <Grid item xs={12} md={6}></Grid>
                          <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                            <FormDate control={control} name="projektTenylegesBefejezese" label="Projekt tényleges befejezése" />
                          </Grid>
                        </>
                      )
                    )}
                    <Grid item xs={12} md={6}>
                      <FormCheckBox force={force} setforce={setforce} control={control} name="modosultTervezettKezdet" label="Módosult kezdet" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormCheckBox
                        force={force}
                        setforce={setforce}
                        control={control}
                        name="modosultTervezettBefejezes"
                        label="Módosult befejezés"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" style={{ margin: "20px 0px 0px 15px" }}>
                        Hiánypótlás:
                      </Typography>
                    </Grid>

                    <Grid item xs={12} container justifyContent="center">
                      <MissingDocs
                        data={missingDocs}
                        set={setmissingDocs}
                        type="Hiánypótlás"
                        handleFileSelect={handleFileSelect}
                        docFiles={docFiles}
                        handleSupportDocDelete={handleSupportDocDelete}
                      />
                    </Grid>
                    <Typography variant="subtitle1" style={{ margin: "20px 0px 0px 15px" }}>
                      Tisztázó kérdés:
                    </Typography>
                    <Grid item xs={12} container justifyContent="center">
                      <ClarifyingDocs
                        data={missingDocs2}
                        set={setmissingDocs2}
                        type="Tisztázó kérdés"
                        handleFileSelect={handleFileSelect}
                        docFiles={docFiles}
                        handleSupportDocDelete={handleSupportDocDelete}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* --------------- */}
              {/* Right grid side */}
              {/* --------------- */}

              <Grid item xs={12} md={6}>
                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Pénzügy
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Projekt költségvetése"
                        name="koltsegvetes"
                        end={<InputAdornment position="end">Ft</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Projekt elszámolható költsége"
                        name="projektElszamolhazoKoltsege"
                        style={{ style: { fontSize: 14 } }}
                        end={<InputAdornment position="end">Ft</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Támogatás összege"
                        name="tamogatasOsszege2"
                        end={<InputAdornment position="end">Ft</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Támogatás mértéke"
                        name="tamogatasMerteke"
                        type="number"
                        end={<InputAdornment position="end">%</InputAdornment>}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Önerő összege"
                        name="oneroOsszege"
                        end={<InputAdornment position="end">Ft</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Önerő mértéke"
                        name="oneroMerteke"
                        type="number"
                        end={<InputAdornment position="end">%</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField control={control} label="Önerő" name="onero" end={<InputAdornment position="end">Ft</InputAdornment>} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormTextField
                        control={control}
                        label="Hitel összege"
                        name="hitelOsszege"
                        end={<InputAdornment position="end">Ft</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckBox force={force} setforce={setforce} control={control} name="modosultKoltsegvetes" label="Módosított költségvetés" />
                    </Grid>
                    {getValues("modosultKoltsegvetes") === true && (
                      <>
                        {" "}
                        <Grid item xs={12} md={6}>
                          <FormTextField
                            control={control}
                            label="Projekt költségvetése"
                            name="Modosultkoltsegvetes"
                            end={<InputAdornment position="end">Ft</InputAdornment>}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormTextField
                            control={control}
                            label="Projekt elszámolható költsége"
                            name="ModosultprojektElszamolhazoKoltsege"
                            style={{ style: { fontSize: 14 } }}
                            end={<InputAdornment position="end">Ft</InputAdornment>}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormTextField
                            control={control}
                            label="Támogatás mértéke"
                            name="ModosulttamogatasMerteke"
                            type="number"
                            end={<InputAdornment position="end">%</InputAdornment>}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormTextField
                            control={control}
                            label="Támogatás összege"
                            name="ModosulttamogatasOsszege2"
                            end={<InputAdornment position="end">Ft</InputAdornment>}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormTextField
                            control={control}
                            label="Önerő összege"
                            name="ModosultoneroOsszege"
                            end={<InputAdornment position="end">Ft</InputAdornment>}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormTextField control={control} label="Önerő" name="onero" end={<InputAdornment position="end">Ft</InputAdornment>} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormTextField
                            control={control}
                            label="Hitel összege"
                            name="ModosulthitelOsszege"
                            end={<InputAdornment position="end">Ft</InputAdornment>}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Card>

                {/* <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Szállító
                  </Typography>
                  <MissingDocs2 set={setmissingDocs3} data={missingDocs3} />
                </Card> */}

                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Támogatási előleg
                  </Typography>
                  <Grid container spacing={2} alignItems="flex-start">
                    <Grid item xs={12} md={6}>
                      <FormTextField control={control} label="Támogatási előleg összege" name="tamogatasiElolegOsszege" />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ marginTop: { md: "-29px" } }}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Támogatási előleg <b>igénylés</b> napja:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="tamogatasiElolegbenyujtasanakNapja" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Támogatási előleg megérkezésének napja:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="tamogatasiElolegFolyositasanakNapja" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Támogatási előleg elszámolandó <b>mértéke</b>:
                      </Typography>
                      <FormTextField
                        control={control}
                        label="Mértéke"
                        name="tamogatasiElolegElszamolandoMerteke"
                        type="number"
                        end={<InputAdornment position="end">%</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Támogatási előleg <b>elszámolásának</b> napja:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="tamogatasiElolegElszamolasanakNapja"></FormDate>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Támogatási előleg <b>elszámolandó összege</b>:
                      </Typography>
                      <FormTextField control={control} label="Összege" name="tamogatasiElolegElszamolandoOsszege" />
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-missingDocs20"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-missingDocs20"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Támogatási előleg");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Fájlfeltöltés
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "Támogatási előleg";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Támogatási <b>előleg</b> elszámolás benyújtása:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="tamogatasiElolegelszamolasBenyujtasa"></FormDate>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mt: "19px" }}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Elszámolt <b>előleg</b> összege:
                      </Typography>
                      <FormTextField control={control} label="Összege" name="tamogatasiElolegElszamoltElolegOsszege" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Biztosíték</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckBox
                        force={force}
                        setforce={setforce}
                        control={control}
                        name="biztositekNyujtasaKotelezo"
                        label="Biztosíték nyújtása kötelező"
                      />
                    </Grid>
                    {getValues("biztositekNyujtasaKotelezo") === true && (
                      <Grid item xs={12} container justifyContent="center">
                        <MissingDocs6 data={missingDocs6} set={setmissingDocs6} />
                      </Grid>
                    )}
                  </Grid>
                </Card>

                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Elszámolás
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <FormCheckBox force={force} setforce={setforce} control={control} name="mvhPalyazat" label="MVH pályázat" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormCheckBox force={force} setforce={setforce} control={control} name="penzugyminiszteriumos" label="Pénzügyminisztériumos" />
                    </Grid>

                    {getValues("mvhPalyazat") === true && (
                      <Grid item xs={12}>
                        <Milestone data={missingDocs8} set={setmissingDocs8} />
                      </Grid>
                    )}

                    {getValues("penzugyminiszteriumos") === true && (
                      <>
                        <Grid item xs={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Elszámolás benyújtásának <b>TO szerinti</b> időpontja:
                          </Typography>
                          <FormDate control={control} label="Dátum" name="elszamolasBenyujtasanakTervezettIdopontja" />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Elszámolás benyújtásának időpontja:
                          </Typography>
                          <FormDate control={control} label="Dátum" name="elszamolasBenyujtasanakIdopontja" />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Elszámolásra benyújtott összeg:
                          </Typography>
                          <FormTextField control={control} label="Összege" name="elszamolasraBenyujtottOsszeg" />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-id1-elszamolas"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-id1-elszamolas"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Elszámolás");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Elszámolás dokumentum
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "Elszámolás";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Záró elszámolás esedékessége beruházás dokumentuma alapján:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="zaroElszamolasEsedekessege" />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider style={{ margin: "15px 0px 15px 0px" }} />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: "500", fontSize: "1.05rem" }}>Hiánypótlás</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Missing
                        data={missingDocs9}
                        set={setmissingDocs9}
                        handleFileSelect={handleFileSelect}
                        docFiles={docFiles}
                        handleSupportDocDelete={handleSupportDocDelete}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: "500", fontSize: "1.05rem" }}>Tisztázó kérdés</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Clarifying
                        data={missingDocs10}
                        set={setmissingDocs10}
                        handleFileSelect={handleFileSelect}
                        docFiles={docFiles}
                        handleSupportDocDelete={handleSupportDocDelete}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2">Elszámolás jóváhagyásának dátuma:</Typography>
                      <FormDate control={control} label="Dátum" name="elszamolasJovahagyasanakDatuma"></FormDate>
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-elszamolasJovagyasa"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-elszamolasJovagyasa"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Jóváhagyó határozat");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Jóváhagyó határozat
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "Jóváhagyó határozat";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>
                  </Grid>
                </Card>

                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Fenntartási időszak
                  </Typography>

                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        <b>1.</b> Fenntartási időszaki jelentés beküldésének <b>határideje</b>:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="fenntartasiIdoszakiJelentesElso"></FormDate>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Fenntartási időszaki jelentés beküldésének <b>napja</b>:
                      </Typography>
                      <FormDate control={control} label="Dátum" name="fenntartasiIdoszakiJelentesBekuldeseElso"></FormDate>
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-id1"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-id1"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "1. Fenntartási jelentés");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Fenntartási időszaki jelentés
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "1. Fenntartási jelentés";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Hiánypótlás átvétele:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasAtvetele" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Hiánypótlás határideje:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasHatarideje" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Hiánypótlás beküldésének időpontja:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasBekuldesenekNapja" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-missingDocs3"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-missingDocs3"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "1. Fenntartási hiánypótlás");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Hiánypótlás
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "1. Fenntartási hiánypótlás";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Tisztázó kérdés átvétele:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesAtvetele" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Tisztázó kérdés határideje:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesHatarideje" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Tisztázó kérdés beküldésének időpontja:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesBekuldesenekNapja" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-missingDocs5"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-missingDocs5"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "1. Fenntartási tisztázó kérdés");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Tisztázó kérdés
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "1. Fenntartási tisztázó kérdés";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                        Fenntartási időszaki jelentés jóváhagyásának napja:
                      </Typography>
                      <Box>
                        <FormDate control={control} label="Dátum" name="fenntartasiJelentesJovahagyasNapja" />
                      </Box>
                    </Grid>

                    <Grid item xs={12} container justifyContent="start" alignItems="center">
                      <label
                        htmlFor="contained-button-file-missingDocs5f"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-missingDocs5f"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Fenntartási időszaki jelentés fájl");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                          Fájl feltöltés
                        </Button>
                      </label>
                      {docFiles &&
                        docFiles
                          .filter((item) => {
                            return item.docCategory === "Fenntartási időszaki jelentés fájl";
                          })
                          .map((doc, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={doc.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, doc)}
                            />
                          ))}
                    </Grid>

                    <Grid item xs={12}>
                      <Divider style={{ margin: "15px 0px 15px 0px" }} />
                    </Grid>

                    <Grid item xs={12}>
                      <FormCheckBox
                        force={force}
                        setforce={setforce}
                        control={control}
                        name="masodikFenntartasiJelentesIgen"
                        label="2. Fenntartási időszaki jelentés"
                      />
                    </Grid>

                    {getValues("masodikFenntartasiJelentesIgen") === true && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            <b>2.</b> Fenntartási időszaki jelentés beküldésének <b>határideje</b>:
                          </Typography>
                          <FormDate control={control} label="Dátum" name="fenntartasiIdoszakiJelentesMasodik"></FormDate>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Fenntartási időszaki jelentés beküldésének <b>napja</b>:
                          </Typography>
                          <FormDate control={control} label="Dátum" name="fenntartasiIdoszakiJelentesBekuldeseMasodik"></FormDate>
                        </Grid>

                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-id2"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-id2"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "2. Fenntartási jelentés");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Fenntartási időszaki jelentés
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "2. Fenntartási jelentés";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Hiánypótlás átvétele:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasAtveteleMasodik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Hiánypótlás határideje:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasHataridejeMasodik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-missingDocs8"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-missingDocs8"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "2. Fenntartási hiánypótlás");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Hiánypótlás
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "2. Fenntartási hiánypótlás";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Tisztázó kérdés átvétele:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesAtveteleMasodik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Tisztázó kérdés határideje:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesHataridejeMasodik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-missingDocs6"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-missingDocs6"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "2. Fenntartási tisztázó kérdés");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Tisztázó kérdés
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "2. Fenntartási tisztázó kérdés";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Fenntartási időszaki jelentés jóváhagyásának napja:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiJelentesJovahagyasNapjaMasodik" />
                          </Box>
                        </Grid>
                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-missingDocs5f2"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-missingDocs5f2"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "2. Fenntartási időszaki jelentés fájl");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Fájl feltöltés
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "2. Fenntartási időszaki jelentés fájl";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Divider style={{ margin: "15px 0px 15px 0px" }} />
                    </Grid>

                    <Grid item xs={12}>
                      <FormCheckBox
                        force={force}
                        setforce={setforce}
                        control={control}
                        name="harmadikFenntartasiJelentesIgen"
                        label="Záró fenntartási időszaki jelentés"
                      />
                    </Grid>

                    {getValues("harmadikFenntartasiJelentesIgen") && (
                      <>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            <b>Záró</b> fenntartási időszaki jelentés beküldésének <b>határideje</b>:
                          </Typography>
                          <FormDate control={control} label="Dátum" name="fenntartasiIdoszakiJelentesHarmadik"></FormDate>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Fenntartási időszaki jelentés beküldésének <b>napja</b>:
                          </Typography>
                          <FormDate control={control} label="Dátum" name="fenntartasiIdoszakiJelentesBekuldeseHarmadik"></FormDate>
                        </Grid>

                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-id3"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-id3"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "3. Fenntartási jelentés");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Fenntartási időszaki jelentés
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "3. Fenntartási jelentés";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Hiánypótlás átvétele:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasAtveteleHarmadik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Hiánypótlás határideje:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiHianypotlasHataridejeHarmadik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-missingDocs9"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-missingDocs9"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "3. Fenntartási hiánypótlás");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Hiánypótlás
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "3. Fenntartási hiánypótlás";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Tisztázó kérdés átvétele:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesAtveteleHarmadik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Tisztázó kérdés határideje:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiTisztazoKerdesHataridejeHarmadik" />
                          </Box>
                        </Grid>

                        <Grid item xs={12} container justifyContent="start" alignItems="center">
                          <label
                            htmlFor="contained-button-file-missingDocs10"
                            style={{
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <input
                              multiple
                              style={{ display: "none" }}
                              accept="application/pdf, .xlsx, .xls,.docx"
                              id="contained-button-file-missingDocs10"
                              type="file"
                              onChange={(event) => {
                                handleFileSelect(event, "3. Fenntartási tisztázó kérdés");
                              }}
                            />
                            <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                              Tisztázó kérdés
                            </Button>
                          </label>
                          {docFiles &&
                            docFiles
                              .filter((item) => {
                                return item.docCategory === "3. Fenntartási tisztázó kérdés";
                              })
                              .map((doc, index) => (
                                <Chip
                                  key={index}
                                  sx={{ marginTop: "10px" }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => handleSupportDocDelete(index, doc)}
                                />
                              ))}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="body2" sx={{ marginBottom: "8px" }}>
                            Fenntartási időszaki jelentés jóváhagyásának napja:
                          </Typography>
                          <Box>
                            <FormDate control={control} label="Dátum" name="fenntartasiJelentesJovahagyasNapjaHarmadik" />
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent={"flex-end"}>
              <Button type="submit" variant="contained">
                {state ? "Adatok mentése" : "Hozzáadás"}
              </Button>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AddApplications;
