import React from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router";
import LoginPage from "./components/LoginPage";
import Main from "./components/Main";
import { useAuth } from "./contexts/UserContext";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Customers from "./components/Customer/Customers";
import Layout from "./components/Layout";
import NoMatch from "./components/NoMatch";
import AddCustomer from "./components/Customer/AddCustomer";
import Customer from "./components/Customer/Customer";
import Applications from "./components/Application/Applications";
import AddApplications from "./components/Application/AddApplications";
import Colleagues from "./components/Colleagues";
import Application from "./components/Application/Application";
import AddTodoApplications from "./components/AddTodoApplications";
import Postal from "./components/Postal";
import Loans from "./components/Loan/Loans";
import AddLoan from "./components/Loan/AddLoan";
import Loan from "./components/Loan/Loan";
import Managements from "./components/Management/Managements";
import Management from "./components/Management/Management";
import AddManagement from "./components/Management/AddManagement";
import Work from "./components/Work";
import Procurements from "./components/Procurement/Procurements";
import Procurement from "./components/Procurement/Procurement";
import AddProcurement from "./components/Procurement/AddProcurement";
import Template from "./components/Template";

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  if (!auth.currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  let auth = useAuth();

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<Main />} />
        <Route path="customers" element={<Outlet />}>
          <Route index element={<Customers />} />
          <Route path="add" element={<AddCustomer />} />
          <Route path=":id" element={<Customer />} />
          <Route path=":id/add-todo" element={<AddTodoApplications />} />
        </Route>
        <Route path="applications" element={<Outlet />}>
          <Route index element={<Applications />} />
          <Route path="add" element={<AddApplications />} />
          <Route path=":id" element={<Application />} />
          <Route path=":id/add-todo" element={<AddTodoApplications />} />
        </Route>
        {auth.role === "Admin" && (
          <Route path="colleagues" element={<Outlet />}>
            <Route index element={<Colleagues />} />
          </Route>
        )}
        <Route path="postal" element={<Postal />} />
        <Route path="loans" element={<Outlet />}>
          <Route index element={<Loans />} />
          <Route path="add" element={<AddLoan />} />
          <Route path=":id" element={<Loan />} />
          <Route path=":id/add-todo" element={<AddTodoApplications />} />
        </Route>
        <Route path="managements" element={<Outlet />}>
          <Route index element={<Managements />} />
          <Route path="add" element={<AddManagement />} />
          <Route path=":id" element={<Management />} />
          <Route path=":id/add-todo" element={<AddTodoApplications />} />
        </Route>
        <Route path="procurements" element={<Outlet />}>
          <Route index element={<Procurements />} />
          <Route path="add" element={<AddProcurement />} />
          <Route path=":id" element={<Procurement />} />
          <Route path=":id/add-todo" element={<AddTodoApplications />} />
        </Route>
        <Route path="work" element={<Work />} />
        <Route path="template" element={<Template />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
