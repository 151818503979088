import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { setPersistence, browserSessionPersistence } from "firebase/auth";
import axios from "axios";
import { settings } from "../config";

const url = settings.url;
const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true); /// loading !!!
  const [role, setrole] = useState();
  const [name, setName] = useState();

  // function login(email, password) {
  //   setPersistence(auth, browserSessionPersistence);
  //   return signInWithEmailAndPassword(auth, email, password).then((result) => {
  //     setCurrentUser(result.user);
  //   });
  // }

  function testMail() {
    currentUser.getIdToken().then((token) => {
      axios({
        method: "get",
        url: url + "/testmail",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        console.log(res.data);
      });
    });
  }

  function testWorkHour() {
    currentUser.getIdToken().then((token) => {
      axios({
        method: "get",
        url: url + "/testworkhour",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    });
  }

  async function mFa(pass, email, password) {
    const fetch = await axios({
      method: "post",
      url: url + "/verification",
      data: {
        token: pass,
        email: email,
      },
    }).then((res) => {
      return res;
    });

    if (fetch.data === true) {
      setPersistence(auth, browserSessionPersistence);
      const result = await signInWithEmailAndPassword(auth, email, password)
        .then((result) => {
          setCurrentUser(result.user);
          return true;
        })
        .catch((err) => {
          return false;
        });
      return result;
    } else {
      return false;
    }
  }

  function logout() {
    return auth.signOut();
  }

  function setUserName(t) {
    setName(t);
  }

  function getUserName() {
    return name;
  }

  useEffect(() => {
    if (currentUser) {
      currentUser.getIdToken().then((token) => {
        axios({
          method: "post",
          url: url + "/checkrole",
          data: {
            uid: currentUser.uid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setrole(res.data.role);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const value = {
    currentUser,
    // login,
    role,
    logout,
    setUserName,
    getUserName,
    mFa,
    testMail,
    testWorkHour,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
