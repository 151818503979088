import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import hu from "date-fns/locale/hu";

const Budget = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{}]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                sx={{ mt: "-23px" }}
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "flex-end",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item container spacing={2} sx={{ ml: "5px" }}>
                    <Grid item xs={12}>
                      <Typography  style={{ fontWeight: "500", fontSize:"1.05rem" }}>{index + 1}. Költség</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{index + 1}. Költség</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Költség"
                          value={item.koltseg ? item.koltseg : ""}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].koltseg = e.target.value;
                            setdata(temp);
                          }}
                        >
                          <MenuItem value={"Eszköz"}>Eszköz</MenuItem>
                          <MenuItem value={"Építés"}>Építés</MenuItem>
                          <MenuItem value={"Képzés"}>Képzés</MenuItem>
                          <MenuItem value={"Szolgáltatás"}>Szolgáltatás</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        defaultValue={item.megnevezes}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].megnevezes = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Szállító neve"
                        defaultValue={item.neve}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].neve = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Adószáma"
                        defaultValue={item.adoszam}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].adoszam = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                        <DatePicker
                          inputFormat="yyyy.MM.dd."
                          mask="____.__.__."
                          value={item.datum ? moment(item.datum, "YYYY-MM-DD") : null}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].datum = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                            setdata(temp);
                          }}
                          label="Ajánlat dátuma"
                          renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label2">Devizás az ügylet</InputLabel>
                        <Select
                          labelId="demo-simple-select-label2"
                          label="Devizás az ügylet"
                          value={item.devizas ? item.devizas : ""}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].devizas = e.target.value;
                            setdata(temp);
                          }}
                        >
                          <MenuItem value={"Igen"}>Igen</MenuItem>
                          <MenuItem value={"Nem"}>Nem</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {data[index].devizas === "Igen" ? (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Deviza"
                            value={item.deviza}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].deviza = e.target.value;
                              setdata(temp);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Ajánlat napján érvényes árfolyam"
                            value={item.ajanlatNapjanArfolyam}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].ajanlatNapjanArfolyam = e.target.value;
                              setdata(temp);
                            }}
                            InputLabelProps={{ style: { fontSize: 12 } }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        label="Ajánlat összege HUF"
                        value={item.ajanlatOsszegeHUF}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].ajanlatOsszegeHUF = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    {data[index].devizas === "Igen" ? (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Ajánlat összege devizában"
                            value={item.ajanlatOsszegeDevizaban}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].ajanlatOsszegeDevizaban = e.target.value;
                              setdata(temp);
                            }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", ml: "20px" }}>
                      <label htmlFor={index + 1 + "z"}>
                        <input
                          id={index + 1 + "z"}
                          type="file"
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          onChange={(event) => {
                            console.log(index + 1 + ". Nyertes árajánlat");
                            props.handleFileSelect(event, index + 1 + ". Nyertes árajánlat");
                          }}
                        />
                        <Button variant="outlined" component="span">
                          Nyertes árajánlat kiválasztása
                        </Button>
                      </label>
                      <Box sx={{ marginTop: "" }}>
                        {props.docFiles &&
                          props.docFiles
                            .filter((item) => {
                              return item.docCategory === index + 1 + ". Nyertes árajánlat";
                            })
                            .map((doc, index) => (
                              <Box>
                                <Chip
                                  key={index}
                                  sx={{
                                    mt: "10px",
                                    mb: "10px",
                                  }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => props.handleSupportDocDelete(index, doc)}
                                />
                              </Box>
                            ))}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ ml: "20px" }}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item container spacing={2} sx={{ ml: "5px" }}>
                    <Grid item xs={12}>
                      <Typography sx={{ mb: "10px" }}>Ellenajánlat 1.</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label1">{index + 1}. Költség</InputLabel>
                        <Select
                          labelId="demo-simple-select-label1"
                          label="Költség"
                          value={item.koltseg1 ? item.koltseg1 : ""}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].koltseg1 = e.target.value;
                            setdata(temp);
                          }}
                        >
                          <MenuItem value={"Eszköz"}>Eszköz</MenuItem>
                          <MenuItem value={"Építés"}>Építés</MenuItem>
                          <MenuItem value={"Képzés"}>Képzés</MenuItem>
                          <MenuItem value={"Szolgáltatás"}>Szolgáltatás</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        defaultValue={item.megnevezes1}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].megnevezes1 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Szállító neve"
                        defaultValue={item.neve1}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].neve1 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Adószáma"
                        defaultValue={item.adoszam1}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].adoszam1 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                        <DatePicker
                          inputFormat="yyyy.MM.dd."
                          mask="____.__.__."
                          value={item.datum1 ? moment(item.datum1, "YYYY-MM-DD") : null}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].datum1 = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                            setdata(temp);
                          }}
                          label="Ajánlat dátuma"
                          renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label3">Devizás az ügylet</InputLabel>
                        <Select
                          labelId="demo-simple-select-label3"
                          label="Devizás az ügylet"
                          value={item.devizas1 ? item.devizas1 : ""}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].devizas1 = e.target.value;
                            setdata(temp);
                          }}
                        >
                          <MenuItem value={"Igen"}>Igen</MenuItem>
                          <MenuItem value={"Nem"}>Nem</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {data[index].devizas1 === "Igen" ? (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Deviza"
                            value={item.deviza1}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].deviza1 = e.target.value;
                              setdata(temp);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Ajánlat napján érvényes árfolyam"
                            value={item.ajanlatNapjanArfolyam1}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].ajanlatNapjanArfolyam1 = e.target.value;
                              setdata(temp);
                            }}
                            InputLabelProps={{ style: { fontSize: 12 } }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        label="Ajánlat összege HUF"
                        value={item.ajanlatOsszegeHUF1}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].ajanlatOsszegeHUF1 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    {data[index].devizas1 === "Igen" ? (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Ajánlat összege devizában"
                            value={item.ajanlatOsszegeDevizaban1}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].ajanlatOsszegeDevizaban1 = e.target.value;
                              setdata(temp);
                            }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", ml: "20px" }}>
                      <label htmlFor={index + 1 + "zmz"}>
                        <input
                          id={index + 1 + "zmz"}
                          type="file"
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          onChange={(event) => {
                            props.handleFileSelect(event, index + 1 + ". Ellenajánlat 1.");
                          }}
                        />
                        <Button variant="outlined" component="span">
                          Ellenajánlat 1. kiválasztása
                        </Button>
                      </label>
                      <Box sx={{ marginTop: "" }}>
                        {props.docFiles &&
                          props.docFiles
                            .filter((item) => {
                              return item.docCategory === index + 1 + ". Ellenajánlat 1.";
                            })
                            .map((doc, index) => (
                              <Box>
                                <Chip
                                  key={index + "11"}
                                  sx={{
                                    mt: "10px",
                                    mb: "10px",
                                  }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => props.handleSupportDocDelete(index, doc)}
                                />
                              </Box>
                            ))}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sx={{ ml: "20px" }}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item container spacing={2} sx={{ ml: "5px" }}>
                    <Grid item xs={12}>
                      <Typography sx={{ mb: "10px" }}>Ellenajánlat 2.</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label4">{index + 1}. Költség</InputLabel>
                        <Select
                          labelId="demo-simple-select-label4"
                          label="Költség"
                          value={item.koltseg2 ? item.koltseg2 : ""}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].koltseg2 = e.target.value;
                            setdata(temp);
                          }}
                        >
                          <MenuItem value={"Eszköz"}>Eszköz</MenuItem>
                          <MenuItem value={"Építés"}>Építés</MenuItem>
                          <MenuItem value={"Képzés"}>Képzés</MenuItem>
                          <MenuItem value={"Szolgáltatás"}>Szolgáltatás</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Megnevezés"
                        defaultValue={item.megnevezes2}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].megnevezes2 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Szállító neve"
                        defaultValue={item.neve2}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].neve2 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Adószáma"
                        defaultValue={item.adoszam2}
                        onBlur={(e) => {
                          const temp = [...data];
                          temp[index].adoszam2 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                        <DatePicker
                          inputFormat="yyyy.MM.dd."
                          mask="____.__.__."
                          value={item.datum2 ? moment(item.datum2, "YYYY-MM-DD") : null}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].datum2 = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                            setdata(temp);
                          }}
                          label="Ajánlat dátuma"
                          renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label5">Devizás az ügylet</InputLabel>
                        <Select
                          labelId="demo-simple-select-label5"
                          label="Devizás az ügylet"
                          value={item.devizas2 ? item.devizas2 : ""}
                          onChange={(e) => {
                            const temp = [...data];
                            temp[index].devizas2 = e.target.value;
                            setdata(temp);
                          }}
                        >
                          <MenuItem value={"Igen"}>Igen</MenuItem>
                          <MenuItem value={"Nem"}>Nem</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {data[index].devizas2 === "Igen" ? (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Deviza"
                            value={item.deviza2}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].deviza2 = e.target.value;
                              setdata(temp);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Ajánlat napján érvényes árfolyam"
                            value={item.ajanlatNapjanArfolyam2}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].ajanlatNapjanArfolyam2 = e.target.value;
                              setdata(temp);
                            }}
                            InputLabelProps={{ style: { fontSize: 12 } }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid item xs={6}>
                      <TextField
                        label="Ajánlat összege HUF"
                        value={item.ajanlatOsszegeHUF2}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].ajanlatOsszegeHUF2 = e.target.value;
                          setdata(temp);
                        }}
                      />
                    </Grid>
                    {data[index].devizas2 === "Igen" ? (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            label="Ajánlat összege devizában"
                            value={item.ajanlatOsszegeDevizaban2}
                            onChange={(e) => {
                              const temp = [...data];
                              temp[index].ajanlatOsszegeDevizaban2 = e.target.value;
                              setdata(temp);
                            }}
                            InputLabelProps={{ style: { fontSize: 14 } }}
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", flexDirection: "column", ml: "20px" }}>
                      <label htmlFor={index + 1 + "zmy"}>
                        <input
                          id={index + 1 + "zmy"}
                          type="file"
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          onChange={(event) => {
                            props.handleFileSelect(event, index + 1 + ". Ellenajánlat 2.");
                          }}
                        />
                        <Button variant="outlined" component="span">
                          Ellenajánlat 2. kiválasztása
                        </Button>
                      </label>
                      <Box sx={{ marginTop: "" }}>
                        {props.docFiles &&
                          props.docFiles
                            .filter((item) => {
                              return item.docCategory === index + 1 + ". Ellenajánlat 2.";
                            })
                            .map((doc, index) => (
                              <Box>
                                <Chip
                                  key={index + "22"}
                                  sx={{
                                    mt: "10px",
                                    mb: "10px",
                                  }}
                                  label={doc.docName}
                                  variant="outlined"
                                  color="primary"
                                  onDelete={() => props.handleSupportDocDelete(index, doc)}
                                />
                              </Box>
                            ))}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: "5px" }}></Divider>
                  </Grid>
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    border: "1px solid rgb(178, 217, 255)",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Budget;
