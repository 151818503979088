import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { getDatabase, push, ref, set, update } from "firebase/database";
import { useLocation, useNavigate } from "react-router";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { settings } from "../../config";
import { useAuth } from "../../contexts/UserContext";
import { DeepDiff } from "deep-diff";
import { useData } from "../../contexts/DataContext";
import Description from "./Description";

const url = settings.url;

const Telephely = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{ telephely: "", regio: "", cim: "", helyrajziSzam: "" }]);
  const { currentUser } = useAuth();

  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleGet = (index) => {
    const varos = data[index].telephely;
    currentUser.getIdToken().then((token) => {
      axios({
        method: "post",
        url: url + "/regio",
        data: {
          varos,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const temp = [...data];
          temp[index].regio = res.data.regio;
          setdata(temp);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <>
      {data.map((item, index) => (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }} key={index}>
          {index > 0 ? (
            <IconButton
              onClick={() => {
                const temp = [...data];
                temp.splice(index, 1);
                setdata(temp);
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : null}
          <Box sx={{ width: "280px" }}>
            <TextField
              sx={{
                marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
              }}
              label="Telephely"
              value={item.telephely}
              onChange={(e) => {
                const temp = [...data];
                temp[index].telephely = e.target.value;
                setdata(temp);
              }}
              onBlur={() => handleGet(index)}
            />
            <TextField
              sx={{
                mt: "8px",
                marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
              }}
              label="Cím"
              value={item.cim}
              onChange={(e) => {
                const temp = [...data];
                temp[index].cim = e.target.value;
                setdata(temp);
              }}
            />
            <TextField
              sx={{
                mt: "8px",
                marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
              }}
              label="Helyrajzi szám"
              value={item.helyrajziSzam}
              onChange={(e) => {
                const temp = [...data];
                temp[index].helyrajziSzam = e.target.value;
                setdata(temp);
              }}
            />
          </Box>
          <Typography sx={{}}>Régió: {item.regio}</Typography>
        </Box>
      ))}
      <Button
        variant="outlined"
        sx={{ marginTop: "15px" }}
        onClick={() => {
          const temp = [...data];
          temp.push({ telephely: "", regio: "" });
          setdata(temp);
        }}
      >
        + Telephely
      </Button>
    </>
  );
};

const AddCustomer = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data] = useState({
    cegnev: "",
    szekhely: "",
    telephely: "",
    adoszam: "",
    cegjegyzekszam: "",
    statusz: "",
    kapcsolattarto: "",
    kapcsolattartotel: "",
    kapcsolattartoemail: "",
    ugyintezo: "",
    konstrukcio: "",
    ajanlo: "",
    regio: "",
    Cégbemutatás: "",
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [docFiles, setdocFiles] = useState([]);
  const { handleSubmit, control } = useForm({
    defaultValues: state ? state : data,
  });
  const [telephelyek, settelephelyek] = useState();
  const { recommendationData } = useData();
  const { customerData } = useData();
  const [recommData, setrecommData] = useState("");
  const [description, setdescription] = useState(useData().customerData.find((item) => item?.key === state?.key)?.cegbemutato);
  const [duplicationSearchData, setduplicationSearchData] = useState();
  const [phoneWarning, setphoneWarning] = useState(false);
  const [emailWarning, setemailWarning] = useState(false);
  const [selectIsOpen, setselectIsOpen] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (state?.docURL) {
      const temp = [];
      state.docURL.forEach((item) => {
        temp.push({ [item.docCategory]: { name: item.docName } });
        setdocFiles(temp);
      });
    }
  }, [state?.docURL]);

  useEffect(() => {
    if (state?.telephelyek) {
      settelephelyek(state.telephelyek);
    }
  }, [state?.telephelyek]);

  useEffect(() => {
    const phones = [];
    const emails = [];

    for (let i of customerData) {
      if (i.kapcsolattartotel !== "" && i.kapcsolattartotel !== undefined) {
        phones.push(i.kapcsolattartotel);
      }
      if (i.kapcsolattartoemail !== "" && i.kapcsolattartoemail !== undefined) {
        emails.push(i.kapcsolattartoemail);
      }
    }

    setduplicationSearchData({ emails, phones });
    console.log({ emails, phones });
  }, [customerData]);

  const searchForDuplicate = (data, type) => {
    if (state === null) {
      if (type === "phone") {
        if (duplicationSearchData.phones.includes(data)) {
          setphoneWarning(true);
        } else {
          setphoneWarning(false);
        }
      }
      if (type === "email") {
        if (duplicationSearchData.emails.includes(data)) {
          setemailWarning(true);
        } else {
          setemailWarning(false);
        }
      }
    }
  };

  const onSubmit = (formData) => {
    const database = getDatabase();
    let key = "";
    const date = new Date().toLocaleString() + "";
    formData.telephelyek = telephelyek;
    formData.cegbemutato = description;

    for (let item of formData.cegbemutato) {
      if (item.locked === false) {
        item.locked = true;
        item.date = date;
      }
    }

    if (formData.Cégbemutatás === undefined) {
      formData.Cégbemutatás = "";
    }

    if (state) {
      const dataRef = ref(database, "ugyfelek/" + state.key);

      if (DeepDiff(state, formData) !== undefined) {
        let temp;
        if (state?.log) {
          temp = [...state.log];
        } else {
          temp = [];
        }
        const log = DeepDiff(state, formData);
        log.forEach((element) => {
          element.date = date;
          if (element.path[0] !== "log") {
            temp.push(element);
          }
        });
        formData.log = temp;
      }

      delete formData.docURL;
      formData.modositasDatuma = date;
      update(dataRef, formData);
      if (docFiles) {
        const storage = getStorage();
        let urls;
        if (state?.docURL) {
          urls = [...state.docURL];
        } else {
          urls = [];
        }
        docFiles.forEach((file) => {
          if (file[Object.keys(file)].size !== undefined) {
            const str = storageRef(storage, "dokumentumok/" + state.key + "_" + file[Object.keys(file)].name);
            uploadBytes(str, file[Object.keys(file)]).then(
              () => {
                getDownloadURL(storageRef(storage, "dokumentumok/" + state.key + "_" + file[Object.keys(file)].name))
                  .then((url) => {
                    urls.push({
                      docName: file[Object.keys(file)].name,
                      docURL: url,
                      docCategory: Object.keys(file)[0],
                    });
                  })
                  .then(() => {
                    update(ref(database, "ugyfelek/" + state.key), {
                      docURL: urls,
                    });
                  });
              },
              (err) => {
                console.log(err);
              }
            );
          }
        });
      }
      navigate(-1);
    } else {
      const dataRef = ref(database, "ugyfelek/");
      formData.rogzitesDatuma = date;
      try {
        push(dataRef, formData).then((snap) => {
          key = snap.key;
          if (docFiles) {
            const storage = getStorage();
            let urls;
            if (state?.docURL) {
              urls = [...state.docURL];
            } else {
              urls = [];
            }
            // console.log(urls);
            docFiles.forEach((file) => {
              const str = storageRef(storage, "dokumentumok/" + key + "_" + file[Object.keys(file)].name);
              uploadBytes(str, file[Object.keys(file)]).then(
                () => {
                  getDownloadURL(storageRef(storage, "dokumentumok/" + key + "_" + file[Object.keys(file)].name))
                    .then((url) => {
                      urls.push({
                        docName: file[Object.keys(file)].name,
                        docURL: url,
                        docCategory: Object.keys(file)[0],
                      });
                    })
                    .then(() => {
                      // console.log(urls);
                      update(ref(database, "ugyfelek/" + key), {
                        docURL: urls,
                      });
                    });
                },
                (err) => {
                  console.log(err);
                }
              );
            });
          }
        });
        navigate("/customers");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileSelect = (event, name) => {
    const files = event.target.files;
    const temp = [];
    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }

    for (let index = 0; index < temp.length; index++) {
      if (temp[index] !== undefined) {
        const tempArray = docFiles;
        tempArray.push({ [name]: temp[index] });
        setdocFiles(tempArray);
      }
    }

    handleClose();
  };

  const handleDelete = (index, name) => {
    const date = new Date().toLocaleString() + "";
    if (state?.docURL) {
      const filteredArray = (state.docURL = state?.docURL.filter((item) => {
        return item.docName !== name;
      }));

      if (docFiles[index][Object.keys(docFiles[index])[0]].size === undefined) {
        const db = getDatabase();
        const storage = getStorage();
        const dataRef = ref(db, "ugyfelek/" + state.key);
        const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + name);
        update(dataRef, { docURL: filteredArray });

        const tempLog = [...state.log];
        tempLog.push({ kind: "delete", name: name, date: date });
        update(dataRef, { log: tempLog });
        deleteObject(docRef);
      }
    }
    const temp = docFiles;
    temp.splice(index, 1);
    setdocFiles([...temp]);
  };

  const handleAddRecomm = () => {
    if (recommData !== "") {
      const db = getDatabase();
      const dbRef = ref(db, "ajanlo");
      recommendationData.push(recommData);
      set(dbRef, recommendationData);
      setrecommData("");
    }
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{}}>
              {state ? "Ügyfél szerkesztése" : "Új ügyfél bevitele"}
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
              Vissza
            </Button>
          </Box>
        </Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    marginBottom: "15px",
                  }}
                >
                  <Typography variant="h6">Cég adatok</Typography>
                  <FormControl sx={{ width: "245px" }}>
                    <InputLabel id="demo-simple-select-label">Státusz</InputLabel>
                    <Controller
                      name="statusz"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Státusz">
                          <MenuItem value={"új érdeklődő"}>új érdeklődő</MenuItem>
                          <MenuItem value={"visszahívás egyeztetésre"}>visszahívás egyeztetésre</MenuItem>
                          <MenuItem value={"adatokat várok"}>adatokat várok</MenuItem>
                          <MenuItem value={"előminősítés és visszajelzés"}>előminősítés és visszajelzés</MenuItem>
                          <MenuItem value={"pályázat keresése"}>pályázat keresése</MenuItem>
                          <MenuItem value={"finanszírozás keresése"}>finanszírozás keresése</MenuItem>
                          <MenuItem value={"közbeszerzés kiírása"}>közbeszerzés kiírása</MenuItem>
                          <MenuItem value={"kapcsolat keresése"}>kapcsolat keresése</MenuItem>
                          <MenuItem value={"pályázat keresése"}>pályázat keresése</MenuItem>
                          <MenuItem value={"szerződött"}>szerződött</MenuItem>
                          <MenuItem value={"szerződéskötés alatt"}>szerződéskötés alatt</MenuItem>
                          <MenuItem value={"sikeres (kifutott)"}>sikeres (kifutott)</MenuItem>
                          <MenuItem value={"alkalmatlan"}>alkalmatlan</MenuItem>
                          <MenuItem value={"nem szerződött"}>nem szerződött</MenuItem>
                          <MenuItem value={"eltűnt"}>eltűnt</MenuItem>
                          <MenuItem value={"kockázatos"}>kockázatos</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller name="cegnev" control={control} render={({ field }) => <TextField {...field} label="Cégnév" fullWidth />} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller name="szekhely" control={control} render={({ field }) => <TextField {...field} label="Székhely" fullWidth />} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller name="adoszam" control={control} render={({ field }) => <TextField {...field} label="Adószám" fullWidth />} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="cegjegyzekszam"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Cégjegyzékszám/Nyilvántartási"
                          fullWidth
                          inputProps={{ style: { fontSize: 15.5 } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: 15.5 } }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Controller
                      name="Cégbemutatás"
                      control={control}
                      render={({ field }) => <TextField {...field} label="Cégbemutatás" fullWidth multiline minRows={4} />}
                    /> */}
                    <Description data={description} set={setdescription} oldData={state} />
                  </Grid>
                </Grid>
              </Card>
              <Grid item xs={12}>
                <Card sx={{ padding: "20px", marginTop: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Céges dokumentumok
                  </Typography>

                  <Box>
                    <Button
                      endIcon={<FileUploadIcon />}
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      variant="outlined"
                    >
                      Dokumentum kiválasztása
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem value={"Alapító okirat"} sx={{ padding: "0px" }}>
                        <label
                          htmlFor="contained-button-file"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Alapító okirat");
                            }}
                          />
                          Alapító okirat
                        </label>
                      </MenuItem>
                      <MenuItem value={"Társasági szerződés"} sx={{ padding: "0px" }}>
                        <label
                          htmlFor="contained-button-file2"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file2"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Társasági szerződés");
                            }}
                          />
                          Társasági szerződés
                        </label>
                      </MenuItem>
                      <MenuItem value={"Aláírás minta"} sx={{ padding: "0px" }}>
                        <label
                          htmlFor="contained-button-file3"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file3"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Aláírás minta");
                            }}
                          />
                          Aláírás minta
                        </label>
                      </MenuItem>
                      <MenuItem value={"Aláírási címpéldány"} sx={{ padding: "0px" }} s>
                        <label
                          htmlFor="contained-button-file4"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file4"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Aláírási címpéldány");
                            }}
                          />
                          Aláírási címpéldány
                        </label>
                      </MenuItem>
                      <MenuItem value={"Személyes okmányok"} sx={{ padding: "0px" }}>
                        <label
                          htmlFor="contained-button-file5"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file5"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Személyes okmányok");
                            }}
                          />
                          Személyes okmányok
                        </label>
                      </MenuItem>
                      <MenuItem value={"Árajánlatok"} sx={{ padding: "0px" }}>
                        <label
                          htmlFor="contained-button-file6"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file6"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Árajánlatok");
                            }}
                          />
                          Árajánlatok
                        </label>
                      </MenuItem>
                      <MenuItem value={"Egyéb"} sx={{ padding: "0px" }}>
                        <label
                          htmlFor="contained-button-file7"
                          style={{
                            width: "100%",
                            padding: "6px 16px 6px 16px",
                            cursor: "pointer",
                          }}
                        >
                          <input
                            multiple
                            style={{ display: "none" }}
                            accept="application/pdf, .xlsx, .xls,.docx"
                            id="contained-button-file7"
                            type="file"
                            onChange={(event) => {
                              handleFileSelect(event, "Egyéb");
                            }}
                          />
                          Egyéb
                        </label>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Fájl neve</TableCell>
                            <TableCell>Kategória</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {docFiles &&
                            docFiles.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item[Object.keys(item)].name}</TableCell>
                                <TableCell>{Object.keys(item)}</TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleDelete(index, item[Object.keys(item)].name)}>
                                    <DeleteIcon></DeleteIcon>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              {(auth.role === "Admin" || auth.role === "Irodavezető") && (
                <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Kapcsolattartó
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="kapcsolattarto"
                        control={control}
                        render={({ field }) => <TextField {...field} label="Cég kapcsolattartója" fullWidth />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="kapcsolattartotel"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Kapcsolattartó telefonszáma"
                            fullWidth
                            color={phoneWarning ? "warning" : ""}
                            focused={phoneWarning}
                            helperText={phoneWarning ? "Ez a telefonszám már szerepel más ügyfélnél." : null}
                            onBlur={() => {
                              searchForDuplicate(field.value, "phone");
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="kapcsolattartoemail"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Kapcsolattartó email címe"
                            fullWidth
                            color={emailWarning ? "warning" : ""}
                            focused={emailWarning}
                            helperText={emailWarning ? "Ez az email már szerepel más ügyfélnél." : null}
                            onBlur={() => {
                              searchForDuplicate(field.value, "email");
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="ugyintezo"
                        control={control}
                        render={({ field }) => <TextField {...field} label="Cég ügyintézője" fullWidth />}
                      />
                    </Grid>
                  </Grid>
                </Card>
              )}
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Igény
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="demo-simple-select-label">Konstrukció</InputLabel>
                      <Controller
                        name="konstrukcio"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Konstrukció">
                            <MenuItem value={"hitel"}>hitel</MenuItem>
                            <MenuItem value={"pályázat"}>pályázat</MenuItem>
                            <MenuItem value={"projektmenedzsment"}>projektmenedzsment</MenuItem>
                            <MenuItem value={"közbeszerzés"}>közbeszerzés</MenuItem>
                            <MenuItem value={"kapcsolati tőke"}>kapcsolati tőke</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="demo-simple-select-label">Ajánló</InputLabel>
                      <Controller
                        name="ajanlo"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Ajánló"
                            open={selectIsOpen}
                            onOpen={() => setselectIsOpen(true)}
                            onClose={() => setselectIsOpen(false)}
                          >
                            <MenuItem
                              onClickCapture={(e) => {
                                e.stopPropagation();
                              }}
                              onKeyDown={(e) => e.stopPropagation()}
                            >
                              <TextField
                                helperText="Enter"
                                label="Hozzáadás"
                                variant="standard"
                                value={recommData}
                                onChange={(e) => {
                                  setrecommData(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleAddRecomm();
                                  }
                                }}
                              />
                            </MenuItem>
                            {recommendationData?.map((item, i) => {
                              return (
                                <MenuItem value={item} key={"N" + i}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "0px" }}>
                  Telephely és régió
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Telephely set={settelephelyek} data={state && state?.telephelyek}></Telephely>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} container justifyContent={"flex-end"}>
              <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                {state ? "Adatok mentése" : "Hozzáadás"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default AddCustomer;
