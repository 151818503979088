import React from "react";

const NoMatch = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height:"100vh",
      }}
    >
      <div
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          backgroundColor: "white",
          padding:"50px",
          borderRadius:"8px",
        }}
      >
        A keresett oldal nem található.
      </div>
    </div>
  );
};

export default NoMatch;
