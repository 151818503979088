import { Alert, Button, Card, Container, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useData } from "../contexts/DataContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { getDatabase, ref, update } from "firebase/database";
import TodoList from "./TodoList";
import hu from "date-fns/locale/hu";

const AddTodoApplications = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const projektAzonosito = state.projektAzonosito ? state.projektAzonosito : "";
  const tennivalok = state.tennivalok;
  const [value, setValue] = React.useState(null);
  const [text, settext] = React.useState("");
  const [user, setuser] = React.useState("");
  const [error, seterror] = React.useState(false);
  const navigate = useNavigate();
  const { userList } = useData();

  console.log(tennivalok);

  const handleSubmit = (e) => {
    e.preventDefault();
    const date = new Date().toLocaleString() + "";


    if (value !== null && text !== "") {
      const db = getDatabase();
      const dataRef = ref(db, state.location + "/" + id);
      const todo = [
        {
          datum: value.toLocaleDateString(),
          szoveg: text,
          bevitelDatuma: date,
          felelos: user,
        },
      ];
      if (tennivalok !== undefined) {
        const temp = tennivalok;
        temp.push(...todo);
        update(dataRef, { tennivalok: temp });
      } else {
        update(dataRef, { tennivalok: todo });
      }
    } else {
      seterror(true);
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: "20px" }}>
      <Card sx={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Typography variant="h6">Feladat hozzáadása</Typography>
          </div>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Typography>{projektAzonosito}</Typography>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Vissza
            </Button>
          </Box>
        </Box>
      </Card>
      <Box>
        <Card sx={{ padding: "20px", marginTop: "20px" }}>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box sx={{ my: "20px" }}>
                <FormControl style={{ width: "250px" }}>
                  <InputLabel id="demo-simple-select-label">Felelős</InputLabel>
                  <Select
                    value={user}
                    label="Felelős"
                    onChange={(e) => {
                      setuser(e.target.value);
                    }}
                  >
                    {userList &&
                      userList.map((item, index) => (
                        <MenuItem key={index} value={item.email}>
                          {item.email}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                  <DatePicker
                    inputFormat="yyyy.MM.dd."
                    mask="____.__.__."
                    label="Dátum"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <TextField
                  value={text}
                  onChange={(e) => settext(e.target.value)}
                  variant="filled"
                  id="outlined-multiline-static"
                  label="Szöveg"
                  multiline
                  minRows={9}
                  sx={{ width: "800px" }}
                />
              </Box>
              <Button type="submit" variant="outlined" sx={{ marginTop: "20px" }}>
                Feladat hozzáadása
              </Button>
            </Box>
            {error && (
              <Alert severity="warning" sx={{ marginTop: "20px", textAlign: "center" }}>
                Hiányzó adat(ok)!
              </Alert>
            )}
          </form>
        </Card>
        <TodoList
          tennivalok={useData()[state.context].find((item) => item.key === id).tennivalok}
          name={projektAzonosito}
          id={id}
          db={state.location}
        />
      </Box>
    </Container>
  );
};

export default AddTodoApplications;
