import { Box, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import hu from "date-fns/locale/hu";

const Text = (props) => {
  return (
    <Grid item xs={6}>
      <Typography variant="body2" sx={{ mb: props.label === undefined ? "" : "8px" }}>
        {props.label}
      </Typography>
      <TextField
        label={props.smallLabel}
        defaultValue={props.defaultData}
        onBlur={(e) => {
          const temp = [...props.data];
          temp[props.index][props.name] = e.target.value;
          props.setdata(temp);
        }}
      />
    </Grid>
  );
};

const Equipment = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{}]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
              ml: data.length !== 1 ? "-20px" : "",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                sx={{ mt: "-20px" }}
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "flex-end",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2} alignItems={"flex-end"}>
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    label={
                      <>
                        {index + 1}. <b>{props.type}</b> összege max. HUF
                      </>
                    }
                    defaultData={item.osszegHUF}
                    smallLabel={"Összeg"}
                    name={"osszegHUF"}
                  />
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    label={
                      <>
                        {index + 1}. <b>{props.type}</b> összege max. EUR
                      </>
                    }
                    defaultData={item.osszegEUR}
                    smallLabel={"Összeg"}
                    name={"osszegEUR"}
                  />
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.nyertesSzallito}
                    smallLabel={"Nyertes szállító neve"}
                    name={"nyertesSzallito"}
                  />
                  <Text data={data} setdata={setdata} index={index} defaultData={item.adoszama} smallLabel={"Adószáma"} name={"adoszama"} />
                  <Text data={data} setdata={setdata} index={index} defaultData={item.EURArfolyam} smallLabel={"EUR árfolyam"} name={"EURArfolyam"} />
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.eszamolasTervezettIdopontja ? moment(item.eszamolasTervezettIdopontja, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].eszamolasTervezettIdopontja = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Ajánlat időpontja"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                  <Grid item xs={12}>
                    1. Ellenajánlat
                  </Grid>
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.szallitoNeve1}
                    smallLabel={"Szállító neve"}
                    name={"szallitoNeve1"}
                  />
                  <Text data={data} setdata={setdata} index={index} defaultData={item.adoszama1} smallLabel={"Adószáma"} name={"adoszama1"} />
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.ajanlatOsszege1}
                    smallLabel={"Ajánlat összege"}
                    name={"ajanlatOsszege1"}
                  />
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.EURarfolyam1}
                    smallLabel={"EUR árfolyam"}
                    name={"EURarfolyam1"}
                  />
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.ajanlatIdopontja1 ? moment(item.ajanlatIdopontja1, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].ajanlatIdopontja1 = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Ajánlat időpontja"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: "5px" }} />
                  </Grid>

                  <Grid item xs={12}>
                    2. Ellenajánlat
                  </Grid>
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.szallitoNeve2}
                    smallLabel={"Szállító neve"}
                    name={"szallitoNeve2"}
                  />
                  <Text data={data} setdata={setdata} index={index} defaultData={item.adoszama2} smallLabel={"Adószáma"} name={"adoszama2"} />
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.ajanlatOsszege2}
                    smallLabel={"Ajánlat összege"}
                    name={"ajanlatOsszege2"}
                  />
                  <Text
                    data={data}
                    setdata={setdata}
                    index={index}
                    defaultData={item.EURarfolyam2}
                    smallLabel={"EUR árfolyam"}
                    name={"EURarfolyam2"}
                  />
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.ajanlatIdopontja2 ? moment(item.ajanlatIdopontja2, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].ajanlatIdopontja2 = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Ajánlat időpontja"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                    marginTop: "35px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Equipment;
