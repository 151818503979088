import {  Button, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import FileSaver from "file-saver";
import React from "react";
import { useData } from "../../contexts/DataContext";

const Notifications = (props) => {
  const data = useData()?.notificationData.filter((item) => item.palyazatKulcs === props.id);


  const handleDownload = () => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr.map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")).join("\n");
    }

    let temp = JSON.parse(JSON.stringify(data))
    let cegNeve = "";

    for (let item of temp) {
      delete item.result;
      delete item.palyazatKulcs;

      cegNeve = item.cegnev;
    }

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot(temp));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, cegNeve + " Kiküldött értesítők mentése.csv");
  };

  return (
    <Card sx={{ mt: "20px", p: "20px" }}>
      <Typography variant="h6" gutterBottom>
        Kiküldött értesítők
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Dátum</TableCell>
            <TableCell>Típus</TableCell>
            <TableCell>Címzett e-mail</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, i) => (
            <TableRow key={i}>
              <TableCell>{item.date}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{item.result.envelope.to}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {data.length > 0 ? (
        <Button sx={{ mt: "20px" }} variant="contained" onClick={handleDownload}>
          Letöltés
        </Button>
      ) : null}
    </Card>
  );
};

export default Notifications;
