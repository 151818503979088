import { Card, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";


const Chronology = (props) => {
  const data = props.data;
  //  const notificationData = useData()?.notificationData.filter((item) => item.palyazatKulcs === props.id);
  const [showData, setshowData] = useState();

  useEffect(() => {
    const sumData = [];
    if (data !== undefined) {
      // eslint-disable-next-line no-unused-vars
      if (data.megjegyzesek) {
        for (let [key, value] of Object.entries(data.megjegyzesek)) {
          sumData.push({ date: value.datum, type: "comment", showData: value.komment });
        }
      }
      if (data.tennivalok) {
        for (let item of data?.tennivalok) {
          sumData.push({ date: item.bevitelDatuma, type: "todo", showData: item.szoveg });
        }
      }
      // for (let item of notificationData) {
      //   sumData.push({ date: item.date, showData: item.type, type: "notification" });
      // }


      sumData.sort(function (a, b) {
        let aDate = "";
        let bDate = "";
        if (a.date.includes(":")) {
          aDate = moment(a.date,'YYYY. MM. DD. ').toDate();
        } else {
          aDate = new Date(a.date.replaceAll(" ", "").replaceAll(".", "-").slice(0, -1));
        }
        if (b.date.includes(":")) {
          bDate = moment(b.date,'YYYY. MM. DD. ').toDate();
        } else {
          bDate = new Date(b.date.replaceAll(" ", "").replaceAll(".", "-").slice(0, -1));
        }
        return aDate - bDate;
      });

      setshowData(sumData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ p: "20px" }}>
      <Card sx={{ p: "20px" }}>
        <Typography variant="h6">Kronológia</Typography>
        <Typography variant="body2">Bevitel dátuma szerint</Typography>
        <Box sx={{ mt: "20px" }}>
          {showData?.map((item, i) => (
            <Box key={i}>
              <Typography sx={{ fontWeight: "500" }}>{item.type === "comment" ? "Megjegyzés" : "Feladat"}</Typography>
              <Typography variant="body2" sx={{ my: "2px" }}>
                {item.date}
              </Typography>
              <Typography>{item.showData}</Typography>
              <Divider sx={{ mb: "20px" }}></Divider>
            </Box>
          ))}
          {showData?.length === 0 && <Box>Nincsenek bevitt adatok</Box>}
        </Box>
      </Card>
    </Box>
  );
};

export default Chronology;
