import React, { useEffect, useState } from "react";
import { useData } from "../contexts/DataContext";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Stats = () => {
  const data = useData().applicationsData;
  const [showData, setshowData] = useState();
  const [monthView, setmonthView] = useState(false);
  const [monthData, setmonthData] = useState();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Pályázatok ",
      },
    },
  };

  const GroupByMonth = (array) => {
    let result = [];
    array.reduce(function (res, value) {
      if (new Date().getFullYear() === new Date(value.Month.replaceAll(". ", "-").replaceAll(".", "")).getFullYear()) {
        const month = new Date(value.Month).getMonth() + 1;
        if (!res[month]) {
          res[month] = { x: month.toString(), y: 0 };
          result.push(res[month]);
        }
        res[month].y += value.value;
      }
      return res;
    }, {});
    return result;
  };

  useEffect(() => {
    if (data !== undefined) {
      const arr = [];
      const arr2 = [];
      const temp = data.filter((item) => {
        return (
          (item.sikerdijVarhatoEsedekessege !== "" && item.sikerdijVarhatoEsedekessege !== undefined) ||
          (item.sikerdijOsszege !== "" && item.sikerdijOsszege !== undefined)
        );
      });

      for (let item of temp) {
        arr.push({
          x: item.sikerdijVarhatoEsedekessege,
          y: item.sikerdijOsszege,
        });
      }

      for (let item of temp) {
        arr2.push({
          Month: item.sikerdijVarhatoEsedekessege,
          value: Number(item.sikerdijOsszege),
        });
      }

      setmonthData(GroupByMonth(arr2));
      setshowData(arr);
    }
  }, [data]);

  return (
    <div>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={monthView} onChange={() => setmonthView(!monthView)} />} label="Hónapokra bontás" />
      </FormGroup>
      <Line
        options={options}
        data={{
          datasets: [
            {
              label: "Sikerdíj összege",
              data: monthView ? monthData : showData,
              borderColor: "#9f8ade",
              backgroundColor: "#c2b4ed",
            },
          ],
        }}
      />
    </div>
  );
};

export default Stats;
