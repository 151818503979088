import { Button, Card, Container, TextField } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Outlet, useNavigate } from "react-router";
import ProcurementTable from "./ProcurementTable";

const Procurements = () => {
  const navigation = useNavigate();
  const [query, setQuery] = useState("");

  return (
    <div style={{ marginLeft: "", marginTop: "40px" }}>
      <Container maxWidth="lg">
        <Card
          sx={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ width: "300px" }}
            label="Keresés"
            onChange={(event) => {
              setQuery(String.prototype.toLowerCase.call(event.target.value));
            }}
          />
          <Button
            endIcon={<AddIcon />}
            sx={{ height: "40px" }}
            variant="contained"
            onClick={() => {
              navigation("add");
            }}
          >
            Adat bevitele
          </Button>
        </Card>
        <Card sx={{ padding: "20px", marginTop: "20px" }}>
          <ProcurementTable query={query}></ProcurementTable>
        </Card>
      </Container>
      <Outlet></Outlet>
    </div>
  );
};

export default Procurements;
