import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useData } from "../../contexts/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSnackbar } from "notistack";
import { getDatabase, push, ref, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { DeepDiff } from "deep-diff";
import moment from "moment";
import hu from "date-fns/locale/hu";
import MultipleDate from "./MultipleDate";
import Submitted from "./Submitted";
import { useAuth } from "../../contexts/UserContext";

const FormCheckBox = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          onClick={() => props.setforce && props.setforce(!props.force)}
          control={<Checkbox checked={field.value} onChange={field.onChange} />}
          label={props.label}
        />
      )}
    />
  );
};

const FormDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <DatePicker
            inputFormat="yyyy.MM.dd."
            mask="____.__.__."
            value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
            onChange={(value) => {
              field.onChange(value?.toLocaleDateString() ? value?.toLocaleDateString() : null);
            }}
            label={props.label}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <TextField
          multiline={props.multiline}
          helperText={props.helperText}
          {...field}
          label={props.label}
          fullWidth
          InputProps={{
            endAdornment: props.end,
          }}
        />
      )}
    />
  );
};

const MissingDocs = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{ address: "", hrsz: "" }]);

  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <TextField
                sx={{
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
                label="Cím"
                value={item.address}
                onChange={(e) => {
                  const temp = [...data];
                  temp[index].address = e.target.value;
                  setdata(temp);
                }}
              />

              <TextField
                label="Helyrajzi szám"
                value={item.hrsz}
                onChange={(e) => {
                  const temp = [...data];
                  temp[index].hrsz = e.target.value;
                  setdata(temp);
                }}
              />

              {index + 1 === data.length && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  style={{
                    maxWidth: "30px",
                    maxHeight: "30px",
                    minWidth: "30px",
                    minHeight: "30px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({ address: "", hrsz: "" });
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const AddLoan = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userList } = useData();
  const [docFiles, setdocFiles] = useState([]);
  const [data] = useState({
    ados: "",
    adostars: "",
    finanszirozoNeve: "",
    folyositasFeltetelei: "",
    futamido: "",
    jovahagyottOsszeg: "",
    jutalek: "",
    kertOsszeg: "",
    kezes1: "",
    kezes2: "",
    kondicioEsReszletek: "",
    projektFelelose: "",
    rendelkezesreTartasiIdo: "",
    turelmiIdo: "",
    zalogkotelezett1: "",
    zalogkotelezett2: "",
    statusz: "",
    cegnev: "",
    cegEmail: "",
    szerzodesszam: "",
    ajanlottOsszeg: "",
    megjegyzes: "",
    benyujtasDatuma: "",
  });
  const { handleSubmit, control } = useForm({
    defaultValues: state ? state : data,
  });
  const [fedezet, setfedezet] = useState(useData().loanData.find((item) => item?.key === state?.key)?.fedezet);
  const [elszamolasHataridok, setelszamolasHataridok] = useState(useData().loanData.find((item) => item?.key === state?.key)?.elszamolasHataridok);
  const [benyujtasraKerult, setbenyujtasraKerult] = useState(useData().loanData.find((item) => item?.key === state?.key)?.benyujtasraKerult);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { customerData } = useData();
  const auth = useAuth();

  useEffect(() => {
    if (state?.docURL) {
      setdocFiles(state.docURL);
    }
  }, [state?.docURL]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFileSelect = (event, name, company) => {
    const files = event.target.files;
    const tempArray = [...docFiles];
    const temp = [];

    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }

    for (let index = 0; index < temp.length; index++) {
      if (temp[index] !== undefined) {
        if (docFiles.length > 0) {
          if (docFiles.filter((e) => e.docName === temp[index].name).length > 0) {
            enqueueSnackbar("Ilyen elnevezésű fájl már létezik!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          } else {
            tempArray.push({
              file: temp[index],
              docCategory: name,
              docName: temp[index].name,
            });
          }
        } else {
          tempArray.push({
            file: temp[index],
            docCategory: name,
            docName: temp[index].name,
          });
        }
      }
    }
    setdocFiles(tempArray);

    handleClose();
  };

  const handleDelete = (index, doc) => {
    const date = new Date().toLocaleString() + "";
    const temp = [...docFiles];
    temp.splice(index, 1);
    setdocFiles(temp);

    if (doc.docURL !== undefined && doc.import === undefined) {
      const db = getDatabase();
      const storage = getStorage();
      const dataRef = ref(db, "palyazatok/" + state.key);
      const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + doc.docName);
      update(dataRef, { docURL: temp });
      deleteObject(docRef);

      const tempLog = [...state.log];
      tempLog.push({ kind: "delete", name: doc.docName, date: date });
      state.log = tempLog;
    }
  };

  const onSubmit = (formData) => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    let key = "";
    formData.fedezet = fedezet;
    formData.elszamolasHataridok = elszamolasHataridok;
    formData.benyujtasraKerult = benyujtasraKerult;

    if (state) {
      const dataRef = ref(database, "hitelek/" + state.key);
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          delete formData[key];
        }
      });

      if (DeepDiff(state, formData) !== undefined) {
        let temp;
        if (state?.log) {
          temp = [...state.log];
        } else {
          temp = [];
        }
        const log = DeepDiff(state, formData);
        log.forEach((element) => {
          element.date = date;
          if (element.path[0] !== "log") {
            temp.push(element);
          }
        });
        formData.log = temp;
      }

      formData.modositasDatuma = date;
      update(dataRef, formData);
      if (docFiles.length > 0) {
        const storage = getStorage();
        let urls = [];
        // if (state?.docURL) {
        //   urls = [...state.docURL];
        // } else {
        //   urls = [];
        // }
        docFiles.forEach((item) => {
          if (item.docURL === undefined) {
            const str = storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName);
            const tempLog = [...formData.log];
            tempLog.push({ kind: "upload", name: item.docName, date: date });
            update(ref(database, "hitelek/" + state.key), {
              log: tempLog,
            });
            uploadBytes(str, item.file).then(
              () => {
                getDownloadURL(storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName))
                  .then((url) => {
                    urls.push({
                      docName: item.docName,
                      docURL: url,
                      docCategory: item.docCategory,
                      company: item.company ? item.company : "",
                    });
                  })
                  .then(() => {
                    update(ref(database, "hitelek/" + state.key), {
                      docURL: urls,
                    });
                  });
              },
              (err) => {
                console.log(err);
              }
            );
          } else {
            urls.push(item);
            update(ref(database, "hitelek/" + state.key), {
              docURL: urls,
            });
          }
        });
      } else {
        update(ref(database, "hitelek/" + state.key), {
          docURL: [],
        });
      }
      navigate(-1);
    } else {
      formData.rogzitesDatuma = date;
      let count = 0;
      const dataRef = ref(database, "/hitelek");
      try {
        push(dataRef, formData).then(async (snap) => {
          key = snap.key;
          if (docFiles.length > 0) {
            const storage = getStorage();
            let urls = [];

            const upload = new Promise((resolve, reject) => {
              docFiles.forEach((file, index) => {
                if (file.import) {
                  urls.push({
                    docName: file.docName,
                    docURL: file.docURL,
                    docCategory: file.docCategory,
                    import: true,
                  });
                  count = count + 1;
                  if (count === docFiles.length) {
                    resolve();
                  }
                } else {
                  const str = storageRef(storage, "dokumentumok/" + key + "_" + file.docName);
                  uploadBytes(str, file.file).then(() => {
                    getDownloadURL(storageRef(storage, "dokumentumok/" + key + "_" + file.docName)).then((url) => {
                      urls.push({
                        docName: file.docName,
                        docURL: url,
                        docCategory: file.docCategory,
                        company: file.company ? file.company : "",
                      });
                      count = count + 1;
                      if (count === docFiles.length) {
                        resolve();
                      }
                    });
                  });
                }
              });
            });
            upload.then(() => {
              update(ref(database, "hitelek/" + key), {
                docURL: urls,
              });
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
      navigate(-1);
    }
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{}}>
              {state ? "Hitel szerkesztése" : "Új hitel bevitele"}
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
              Vissza
            </Button>
          </Box>
        </Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Cég kiválasztása
                  </Typography>
                  <FormControl sx={{ width: "100%", mb: "30px" }}>
                    <InputLabel id="demo-simple-select-label">Cég</InputLabel>
                    <Controller
                      name="cegnev"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Cég" fullWidth>
                          {customerData &&
                            customerData.map((item, index) => (
                              <MenuItem key={index} value={item.cegnev}>
                                {item.cegnev}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                {(auth.role === "Admin" || auth.role === "Irodavezető") && (
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      width="200px"
                      label="Cég Email címe"
                      name="cegEmail"
                      helperText="Több email esetén vesszővel elválasztva"
                    />
                  </Grid>
                )}
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-user">Projekt felelőse</InputLabel>
                      <Controller
                        name="projektFelelose"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} fullWidth labelId="demo-simple-select-label-user" id="demo-simple-select-user" label="Projekt felelőse">
                            {userList &&
                              userList.map((item, index) => (
                                <MenuItem key={index} value={item.email}>
                                  {item.email}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} name="statusz" label="Projekt státusza" />
                  </Grid>
                  <Grid container spacing={2} item>
                    <Grid item xs={12} sx={{ marginTop: "15px" }}>
                      <Typography>Projekt célja:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormCheckBox control={control} name="gepbeszerzes" label="gépbeszerzés" />
                      <FormCheckBox control={control} name="ingatlanVasarlas" label="ingatlan vásárlás" />
                      <FormCheckBox control={control} name="ingatlanEpites" label="ingatlan építés" />

                      <FormCheckBox control={control} name="ingatlanKorszerusites" label="ingatlan korszerűsítés" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormCheckBox control={control} name="ingatlanFelujitas" label="ingatlan felújítás" />
                      <FormCheckBox control={control} name="megújulóEnergia" label="megújuló energia" />
                      <FormCheckBox control={control} name="forgoeszkoz" label="forgóeszköz" />
                      <FormCheckBox control={control} name="folyoszamlahitel" label="folyószámlahitel" />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: "10px" }} />
                <Grid container spacing={2} item>
                  <Grid item xs={12} sx={{ marginTop: "15px" }}>
                    <Typography>Projekt státusza:</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <FormCheckBox control={control} name="osszeallitando" label="Összeállítandó" />
                    <FormCheckBox control={control} name="osszeallitott" label="Összeállított" />
                    <FormCheckBox control={control} name="beadott" label="Beadott" />
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
                    <FormCheckBox control={control} name="hianypotlas" label="Hiánypótlás" />
                    <FormCheckBox control={control} name="szerzodeskotes" label="Szerződéskötés" />
                    <FormCheckBox control={control} name="folyositott" label="Folyósított" />
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Fedezet
                </Typography>
                <MissingDocs data={fedezet} set={setfedezet}></MissingDocs>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt fájlok
                </Typography>
                <Button onClick={handleClick} variant="outlined" endIcon={<FileUploadIcon />}>
                  Dokumentum kiválasztása
                </Button>
                <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                  <MenuItem value={"Aláírási címpéldány"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Aláírási címpéldány");
                        }}
                      />
                      Aláírási címpéldány
                    </label>
                  </MenuItem>
                  <MenuItem value={"Társasági szerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file2"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file2"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Társasági szerződés");
                        }}
                      />
                      Társasági szerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Tagjegyzék"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file6"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file6"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Tagjegyzék");
                        }}
                      />
                      Tagjegyzék
                    </label>
                  </MenuItem>
                  <MenuItem value={"Hitel dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file16"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file16"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Hitel dokumentumok");
                        }}
                      />
                      Hitel dokumentumok
                    </label>
                  </MenuItem>
                  <MenuItem value={"Kiküldött megbízásiszerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file17"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file17"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Kiküldött megbízásiszerződés");
                        }}
                      />
                      Kiküldött megbízásiszerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Aláírt szerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file18"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file18"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Aláírt szerződés");
                        }}
                      />
                      Aláírt szerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Hitelszerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file19"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file19"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Hitelszerződés");
                        }}
                      />
                      Hitelszerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Egyéb fájlok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file20"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file20"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Egyéb fájlok");
                        }}
                      />
                      Egyéb fájlok
                    </label>
                  </MenuItem>
                </Menu>
                <Box sx={{ marginTop: "10px" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fájl neve</TableCell>
                          <TableCell>Kategória</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {docFiles &&
                          docFiles.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {item.docName}
                                {item.company && (
                                  <>
                                    <br />
                                    Cég: {item.company}
                                  </>
                                )}
                              </TableCell>
                              <TableCell>{item.docCategory}</TableCell>
                              <TableCell sx={{}}>
                                <IconButton onClick={() => handleDelete(index, item)}>
                                  <DeleteIcon></DeleteIcon>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Pénzügy
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField control={control} label="Kért összeg" name="kertOsszeg" end={<InputAdornment position="end">Ft</InputAdornment>} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      control={control}
                      label="Jóváhagyott összeg"
                      name="jovahagyottOsszeg"
                      end={<InputAdornment position="end">Ft</InputAdornment>}
                    />
                  </Grid>
                  {auth.role === "Admin" && (
                    <Grid item xs={12} md={6}>
                      <FormTextField control={control} label="Jutalék" name="jutalek" end={<InputAdornment position="end">Ft</InputAdornment>} />
                    </Grid>
                  )}
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Dátumok
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Futamidő" name="futamido" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Türelmi idő (hónapok száma)" name="turelmiIdo" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormDate control={control} label="Rendelkezésre tartási idő (határidő)" name="rendelkezesreTartasiIdo" />
                  </Grid>
                  <Grid item xs={12} container>
                    <MultipleDate data={elszamolasHataridok} set={setelszamolasHataridok} />
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Adatok
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Szerződésszám" name="szerzodesszam" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormDate control={control} label="Szerződéskötés" name="szerzodeskotes" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField control={control} label="Folyósítás feltételei" name="folyositasFeltetelei" />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField control={control} label="Kondíció és részletek" name="kondicioEsReszletek" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Adós" name="ados" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Adóstárs" name="adostars" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Kezes 1" name="kezes1" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Kezes 2" name="kezes2" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Zálogkötelezett 1" name="zalogkotelezett1" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Zálogkötelezett 2" name="zalogkotelezett2" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Benyújtásra került</Typography>
                  </Grid>
                  {/* <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Finanszírozó neve" name="finanszirozoNeve" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormDate control={control} label="Benyújtás dátuma" name="benyujtasDatuma" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      control={control}
                      label="Ajánlott összeg"
                      name="ajanlottOsszeg"
                      end={<InputAdornment position="end">Ft</InputAdornment>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormTextField multiline={true} control={control} label="Megjegyzés" name="megjegyzes" />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Submitted data={benyujtasraKerult} set={setbenyujtasraKerult} />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button type="submit" variant="contained" endIcon={<AddIcon />}>
              {state ? "Adatok mentése" : "Hozzáadás"}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default AddLoan;
