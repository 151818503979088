import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useData } from "../../contexts/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileSaver from "file-saver";
import { useReactToPrint } from "react-to-print";
import Mail from "../Mail";
import { useSnackbar } from "notistack";
import { getDatabase, push, ref, remove, update } from "firebase/database";
import TodoList from "../TodoList";
import DeleteIcon from "@mui/icons-material/Delete";
import Chronology from "../Application/Chronology";

const convertLog = (log) => {
  if (log.kind === "N") {
    return {
      type: "Új bevitt érték",
      value: log.path[0] + " → " + JSON.stringify(log.rhs),
    };
  }
  if (log.kind === "E") {
    const temp = log.rhs === "" ? "üres mező" : log.rhs;
    return {
      type: "Érték megváltozott",
      value: log.path + ": " + log.lhs + " → " + temp,
    };
  }
  if (log.kind === "A") {
    if (log.item.kind === "N") {
      return {
        type: "Új bevitt érték",
        date: log.date,
        value: log.path + " → " + log.item.rhs.name,
      };
    }
    if (log.item.kind === "D") {
      return {
        type: "Érték törlésre került",
        date: log.date,
        value: log.path + " → " + log.item.lhs.name,
      };
    }
  }
  if (log.kind === "upload") {
    return {
      type: "Új fájl került feltöltésre",
      date: log.date,
      value: log.name,
    };
  }
  if (log.kind === "delete") {
    return {
      type: "Egy fájl törlés került",
      date: log.date,
      value: log.name,
    };
  }
  if (log.kind === "D") {
    return {
      type: "Egy fájl törlés került",
      date: log.date,
      value: log.path[0],
    };
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CommentSection = (props) => {
  const { id } = useParams();
  const [comment, setcomment] = useState("");

  const handleSubmitComment = () => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    const dataRef = ref(database, "ugyfelek/" + id + "/megjegyzesek");

    push(dataRef, { komment: comment, datum: date });
    setcomment("");
  };

  return (
    <Box>
      <Typography sx={{ mb: "20px" }}>Megjegyzések</Typography>
      <TextField
        sx={{ width: "100%", mb: "10px" }}
        label="Megjegyzés"
        multiline
        value={comment}
        onChange={(e) => {
          setcomment(e.target.value);
        }}
      />
      <Button
        variant="outlined"
        onClick={() => {
          handleSubmitComment();
        }}
      >
        Megjegyzés hozzáadása
      </Button>
    </Box>
  );
};

const Customer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useData().customerData.find((item) => item.key === id);
  const componentRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = React.useState(0);

  const applications = useData().applicationsData.filter((item) => {
    return item.cegnev === data.cegnev;
  });

  const loans = useData().loanData.filter((item) => {
    return item.cegnev === data.cegnev;
  });

  const managements = useData().managementData.filter((item) => {
    return item.cegnev === data.cegnev;
  });

  const procurements = useData().procurementData.filter((item) => {
    return item.cegnev === data.cegnev;
  });

  const handleDownload = () => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr.map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")).join("\n");
    }

    let temp = JSON.parse(JSON.stringify(data));

    delete temp.tennivalok;
    delete temp.munkaidoNyilvantartas;
    delete temp.log;

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot([temp]));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, "Ügyfél mentés.csv");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getComments = () => {
    const list = [];
    if (data?.megjegyzesek !== undefined) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(data.megjegyzesek)) {
        value.key = key;
        list.push(value);
      }
    }
    return list;
  };

  const handleComplete = (item) => {
    if (item.teljesitve === undefined) {
      const database = getDatabase();
      const dataRef = ref(database, "ugyfelek/" + id + "/megjegyzesek/" + item.key);
      const date = new Date().toLocaleString() + "";

      update(dataRef, { teljesitve: true, teljesitesDatuma: date });
    }
  };

  const deleteItem = (item) => {
    const database = getDatabase();
    const dataRef = ref(database, "ugyfelek/" + id + "/megjegyzesek/" + item.key);
    remove(dataRef);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{}}>
              {data && data.cegnev} ügyfél adatai
            </Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  navigate("/customers/add", { state: data });
                }}
              >
                Adatok szerkesztése
              </Button>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Vissza
              </Button>
            </Box>
          </Box>
        </Card>

        <Box sx={{ borderBottom: 1, borderColor: "#c4c4c4" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
            <Tab label="Főoldal" {...a11yProps(0)} />
            <Tab label="Kronológia" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ marginTop: "20px" }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Cég adatok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {data && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Cég neve:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.cegnev}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Székhely:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.szekhely}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Telephely:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>
                              {data.telephelyek.map((item) => {
                                return item.telephely + " - " + item.regio + ", ";
                              })}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Adószám:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.adoszam}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cégjegyzékszám:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.cegjegyzekszam}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Státusz:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.statusz}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <Typography>Kapcsolattartó</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {data && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Cég kapcsolattartója:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.kapcsolattarto}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kapcsolattartó telefonszáma:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.kapcsolattartotel}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kapcsolattartó email címe:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.kapcsolattartoemail}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cég ügyintézője:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.ugyintezo}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                  <Typography>Igény</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {data && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Konstrukció:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.konstrukcio}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Ajánló:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.ajanlo}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                  <Typography>Dokumentumok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {data && (
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Fájl neve</TableCell>
                            <TableCell>Kategória</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.docURL ? (
                            data.docURL.map((item) => (
                              <TableRow key={item.key}>
                                <TableCell>
                                  <a
                                    href={item.docURL}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Chip
                                      label={item.docName}
                                      color="primary"
                                      variant="outlined"
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "#f2f8ff",
                                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                        },
                                      }}
                                    />
                                  </a>
                                </TableCell>
                                <TableCell>{item.docCategory}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>Nincsen feltöltött dokumentum</TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Rendszerelőzmények</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h6">Rendszerelőzmények</Typography>
                  {data.log && (
                    <Box
                      sx={{
                        backgroundColor: "#f5f5f5",
                        padding: "10px",
                        borderRadius: "10px",
                        marginTop: "10px",
                      }}
                    >
                      {data.log.map((item, index) => (
                        <Box sx={{ mt: index === 0 ? "0px" : "10px" }} key={"hg" + index}>
                          <Typography style={{ fontWeight: "500" }}>{convertLog(item)?.type}</Typography>
                          <Typography>
                            {item.date}&nbsp; {convertLog(item)?.value}
                          </Typography>
                          <Divider></Divider>
                        </Box>
                      ))}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* <Accordion sx={{ borderRadius: "0px 0px 4px 4px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography>Munkaóra</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dátum</TableCell>
                      <TableCell>Óra</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.munkaidoNyilvantartas && getWorkData()}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion> */}

              <Card sx={{ padding: "20px", marginTop: "20px" }}>
                <Typography variant="h6" sx={{ marginBottom: "20px" }}>
                  Tevékenységek
                </Typography>
                {applications &&
                  applications.map((item, i) => (
                    <Box key={"df" + i}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Typography>Pályázat</Typography>
                        <Button onClick={() => navigate("/applications/" + item.key)}>Pályázat megnyitása</Button>
                      </Box>
                      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Box>
                  ))}

                {loans &&
                  loans.map((item, i) => (
                    <Box key={"as" + i}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Typography>Hitel</Typography>
                        <Button onClick={() => navigate("/loans/" + item.key)}>Hitel megnyitása</Button>
                      </Box>
                      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Box>
                  ))}

                {managements &&
                  managements.map((item, i) => (
                    <Box key={"aw" + i}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Typography>Projektmenedzsment</Typography>
                        <Button onClick={() => navigate("/managements/" + item.key)}>Projektmenedzsment megnyitása</Button>
                      </Box>
                      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Box>
                  ))}

                {procurements &&
                  procurements.map((item, i) => (
                    <Box key={"gdgd" + i}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <Typography>Közbeszerzés</Typography>
                        <Button onClick={() => navigate("/procurements/" + item.key)}>Közbeszerzés megnyitása</Button>
                      </Box>
                      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                    </Box>
                  ))}
              </Card>
              <Box sx={{ mt: "20px" }}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Levelezés</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Mail email={data.cegEmail} label={data.key}></Mail>
                  </AccordionDetails>
                </Accordion>
              </Box>

              <Card
                sx={{
                  mt: "20px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Munkaóra kulcs:</Typography>
                <Tooltip title="Másolás">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.key);
                      enqueueSnackbar("A kulcs másolásra került.", {
                        variant: "success",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      });
                    }}
                  >
                    {data.key}
                  </Typography>
                </Tooltip>
              </Card>

              <Button sx={{ mt: "20px" }} variant="contained" onClick={() => handleDownload()}>
                Letöltés
              </Button>

              <Button sx={{ mt: "20px", ml: "20px" }} onClick={handlePrint} variant="contained">
                Nyomtatás
              </Button>

              <Box sx={{ display: "none", displayPrint: "block" }}>
                <div ref={componentRef}>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Cég adatok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Cég neve:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.cegnev}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Székhely:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.szekhely}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Telephely:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.telephely}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Adószám:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.adoszam}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cégjegyzékszám:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.cegjegyzekszam}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Státusz:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.statusz}</TableCell>
                          </TableRow>
                        </table>
                      </div>

                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Kapcsolattartó
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Cég kapcsolattartója:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.kapcsolattarto}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kapcsolattartó telefonszáma:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.kapcsolattartotel}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Kapcsolattartó email címe:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.kapcsolattartoemail}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cég ügyintézője:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.ugyintezo}</TableCell>
                          </TableRow>
                        </table>
                      </div>
                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Igény
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Konstrukció:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.konstrukcio}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Ajánló:</TableCell>
                            <TableCell sx={{ fontWeight: "bold" }}>{data.ajanlo}</TableCell>
                          </TableRow>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="page-break" />

                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Dokumentumok
                        </Typography>
                        <table>
                          {data.docURL ? (
                            data.docURL.map((item) => (
                              <TableRow key={item.key}>
                                <TableCell>
                                  <a
                                    href={item.docURL}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                    }}
                                  >
                                    <Chip
                                      label={item.docName}
                                      color="primary"
                                      variant="outlined"
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "#f2f8ff",
                                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                        },
                                      }}
                                    />
                                  </a>
                                </TableCell>
                                <TableCell>{item.docCategory}</TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>Nincsen feltöltött dokumentum</TableRow>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
              {/* <Card sx={{ padding: "20px", marginTop: "20px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h6">Feladatok</Typography>
                <Button
                  variant="contained"
                  onClick={() => navigate("/customers/" + id + "/add-todo")}
                >
                  Feladat hozzáadása
                </Button>
              </Box>
              <TodoList
                tennivalok={data.tennivalok}
                name={data.cegnev}
                id={id}
              ></TodoList>
            </Card> */}
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", mb: "20px", mt: "20px" }}>
                <CommentSection />
                <Box sx={{ mt: "20px", backgroundColor: "#f5f5f5", borderRadius: "5px" }}>
                  {getComments().map((item, index) => (
                    <Box key={"sdff" + index}>
                      <Box sx={{ p: "10px", display: "flex", justifyContent: "space-between" }}>
                        <Box>
                          <Box>
                            <Typography variant="body2" sx={{ mb: "4px" }}>
                              {item.datum}
                            </Typography>
                          </Box>
                          <Box sx={{ wordBreak: "break-word" }}>
                            <Typography>{item.komment}</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <IconButton aria-label="delete" onClick={() => deleteItem(item)} color="warning" sx={{ marginRight: "10px" }}>
                            <DeleteIcon />
                          </IconButton>
                          <Switch
                            checked={item.teljesitve === true ? true : false}
                            color="success"
                            onChange={() => handleComplete(item)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  ))}
                </Box>
              </Card>
              <Card sx={{ padding: "20px", mt: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{}}
                  onClick={() => {
                    navigate("add-todo", {
                      state: {
                        location: "ugyfelek",
                        context: "customerData",
                        projektAzonosito: data.projektAzonosito,
                        tennivalok: data.tennivalok,
                      },
                    });
                  }}
                >
                  Feladat hozzáadása
                </Button>
                <TodoList tennivalok={data.tennivalok} name={data.projektAzonosito} id={id} db="ugyfelek" />
              </Card>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Chronology data={data} />
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Customer;
