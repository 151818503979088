import { Button, Card, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { getDatabase, ref, update } from "firebase/database";
import React, { useState } from "react";

const rowNames = [
  "Ssz",
  "Támogatást nyújtó szerv megnevezése",
  "Pályázati azonosító",
  "Támogatás jóváhagyásának időpontja",
  "Támogatás benyújtásának időpontja",
  "Támogatás összege Ft-ban",
  "Támogatás összege EUR-ban",
  "Támogatás jogcíme",
];

const AddRow = (props) => {
  const handleAdd = () => {
    const database = getDatabase();
    const dataRef = ref(database, "palyazatok/" + props.id);

    const temp = props.currentData === undefined ? [] : [...props.currentData];
    temp.push(props.addData);
    update(dataRef, { korabbiTamogatasok: temp });
  };
  return (
    <>
      <TableRow>
        <TableCell />
        {rowNames.map((item, index) => {
          return index === 0 ? null : (
            <TableCell>
              <TextField
                multiline
                margin="none"
                size="small"
                value={props.addData[item] ? props.addData[item] : ""}
                onChange={(e) => {
                  props.setData((prev) => ({ ...prev, [item]: e.target.value }));
                }}
              />
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell rowSpan={7} colSpan={7} />
        <TableCell align="right">
          <Button
            variant="contained"
            onClick={() => {
              handleAdd();
            }}
          >
            Hozzáadás
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

const EditableTable = (props) => {
  const [addData, setaddData] = useState({});

  return (
    <div>
      <Card sx={{ mt: "40px", p: "20px", position: "relative", width: "75vw", marginLeft: "calc(-1 * ((75vw - 100%) / 2))" }}>
        <Typography variant="h6" sx={{ pb: "20px" }}>
          Korábbi támogatások
        </Typography>
        <Table sx={{ width: "100%" }} size="medium">
          <TableHead>
            <TableRow>
              {rowNames.map((item) => (
                <TableCell>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.korabbiTamogatasok?.map((item, i) => {
              return (
                <TableRow key={"ddds2" + i}>
                  <TableCell>{i + 1}.</TableCell>
                  {rowNames.map((name, index) => (index === 0 ? null : <TableCell>{item[name]}</TableCell>))}
                </TableRow>
              );
            })}
            <AddRow currentData={props.data.korabbiTamogatasok} addData={addData} setData={setaddData} id={props.id} />
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};

export default EditableTable;
