import React, { useEffect, useState } from "react";
import axios from "axios";
import { settings } from "../config";
import { useAuth } from "../contexts/UserContext";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FileSaver from "file-saver";
import { useSnackbar } from "notistack";

const Mail = (props) => {
  const url = settings.url;
  const { currentUser } = useAuth();
  const [data, setdata] = useState([]);
  const [open, setopen] = useState(false);
  const [mailIndex, setmailIndex] = useState(0);
  const [mailList, setmailList] = useState();
  const [loading, setloading] = useState(true);
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    //regi megoldas
    // const emails = [];
    // props.email?.split(",").forEach((element) => {
    //   emails.push(element.trim());
    // });
    if (true) {
      currentUser.getIdToken().then((token) => {
        axios({
          method: "post",
          url: url + "/getmail",
          data: {
            email: props.label,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((res) => {
          if (res.data.data === false) {
          } else {
            if (Array.isArray(res.data.data)) {
              const temp = [];
              res.data.data?.forEach((item) => {
                item.plain.text = item.plain.text?.replace(
                  // eslint-disable-next-line no-control-regex
                  new RegExp("\r?\n", "g"),
                  "<br />"
                );
                item.date = new Date(item.date);
                temp.push(item);
              });
              temp.sort(function (a, b) {
                return b.date - a.date;
              });
              setloading(false);
              setdata(temp);
            } else {
              setloading(false);
              enqueueSnackbar(`Email újra hozzáadása szükséges: ${res.data.data}`, {
                variant: "info",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
                autoHideDuration: 10000,
              });
            }
          }
        });
      });
    } else {
      setloading(false);
    }
    mails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewMail = () => {
    auth.currentUser.getIdToken().then((token) => {
      axios
        .get(url + "/addemail", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          window.open(res.data.url, "_blank");
        });
    });
  };

  const mails = async () => {
    const token = await auth.currentUser.getIdToken();
    const res = await axios.get(url + "/listemail", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    setmailList(res.data.emails);
  };

  const getAttach = (id1, id2, id3, mimeType, filename) => {
    currentUser.getIdToken().then((token) => {
      axios({
        method: "post",
        url: url + "/att",
        data: {
          msgId: id1,
          attId: id2,
          email: id3,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        var url = `data:${mimeType};base64,${res.data.emails.replace(/-/g, "+").replace(/_/g, "/")}`;

        fetch(url)
          .then((res) => res.blob())
          .then((res) => {
            FileSaver.saveAs(res, filename);
          });
      });
    });
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <Button
        onClick={() => {
          addNewMail();
        }}
      >
        Email hozzáadása
      </Button>
      {mailList?.length > 0 && mailList.map((item, index) => <Chip label={item} key={index} variant="outlined" sx={{ mr: "10px" }} />)}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dátum</TableCell>
              <TableCell>Feladó</TableCell>
              <TableCell>Tárgy</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    setmailIndex(index);
                    setopen(true);
                  }}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      background: "#f5f5f5",
                    },
                  }}
                >
                  <TableCell>{item.date.toLocaleString() + ""}</TableCell>
                  <TableCell>{item.from}</TableCell>
                  <TableCell>{item.subject}</TableCell>
                </TableRow>
              ))
            ) : loading ? (
              <TableRow>
                <TableCell>
                  <CircularProgress />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell>Nincs beérkezett email, vagy nincs cég email megadva.</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length > 0 && (
        <Dialog open={open} onClose={() => setopen(false)}>
          <Box sx={{ p: "30px" }}>
            <Typography>
              <b>Feladó: </b> {data[mailIndex].from}
            </Typography>
            <Divider sx={{ my: "10px" }}></Divider>
            <Typography>
              <b>Dátum: </b> {data[mailIndex].date.toLocaleString() + ""}
            </Typography>
            <Divider sx={{ my: "10px" }}></Divider>
            <Typography>
              <b>Tárgy: </b> {data[mailIndex].subject}
            </Typography>

            {data[mailIndex].plain?.attachments.length > 0 && (
              <>
                <Divider sx={{ my: "10px" }}></Divider>
                <Box sx={{ mt: "10px" }}>
                  {data[mailIndex].plain.attachments.map((item, index) => (
                    <Chip
                      key={index}
                      label={item.filename}
                      variant="filled"
                      color="primary"
                      sx={{ mr: "10px" }}
                      onClick={() => {
                        getAttach(data[mailIndex].msgId, item.body.attachmentId, data[mailIndex].mail, item.mimeType, item.filename);
                      }}
                    />
                  ))}
                </Box>
              </>
            )}

            <Divider sx={{ my: "10px" }}></Divider>
            <div
              dangerouslySetInnerHTML={{
                __html: data[mailIndex].plain.text,
              }}
            />
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default Mail;
