import { Box, Button, Chip, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import hu from "date-fns/locale/hu";

const MissingDocs = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{}]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2} alignItems={"flex-end"}>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.hianypotlasDatum ? moment(item.hianypotlasDatum, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].hianypotlasDatum = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label={"Hiánypótlás megérkezése"}
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.hianypotlasAtvetele ? moment(item.hianypotlasAtvetele, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].hianypotlasAtvetele = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Hiánypótlás átvétele"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.hianypotlasHatarideje ? moment(item.hianypotlasHatarideje, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].hianypotlasHatarideje = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Hiánypótlás határideje"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.hianypotlasTeljesitese ? moment(item.hianypotlasTeljesitese, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].hianypotlasTeljesitese = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Teljesítés időpontja"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} container justifyContent="start" alignItems="center">
                    <label
                      htmlFor={"contained-button-file-missingDocsTime" + index}
                      style={{
                        width: "100%",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id={"contained-button-file-missingDocsTime" + index}
                        type="file"
                        onChange={(event) => {
                          props.handleFileSelect(event, index + 1 + ". Hiánypótlás");
                        }}
                      />
                      <Button endIcon={<FileUploadIcon />} component="span" variant="outlined" style={{ maxHeight: "35px" }}>
                        Hiánypótlás dokumentuma
                      </Button>
                    </label>
                    {props.docFiles &&
                      props.docFiles
                        .filter((item) => {
                          return item.docCategory === index + 1 + ". Hiánypótlás";
                        })
                        .map((doc, index) => (
                          <Chip
                            key={index}
                            sx={{ marginTop: "10px" }}
                            label={doc.docName}
                            variant="outlined"
                            color="primary"
                            onDelete={() => props.handleSupportDocDelete(index, doc)}
                          />
                        ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: "5px" }} />
                  </Grid>
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MissingDocs;
