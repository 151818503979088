import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { useData } from "../../contexts/DataContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSnackbar } from "notistack";
import { getDatabase, push, ref, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { DeepDiff } from "deep-diff";
import moment from "moment";
import hu from "date-fns/locale/hu";
import Target from "./Target";
import Equipment from "./Equipment";
import { useAuth } from "../../contexts/UserContext";

const FormCheckBox = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          onClick={() => props.setforce && props.setforce(!props.force)}
          control={<Checkbox checked={field.value} onChange={field.onChange} />}
          label={props.label}
        />
      )}
    />
  );
};

const FormDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <DatePicker
            inputFormat="yyyy.MM.dd."
            mask="____.__.__."
            value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
            onChange={(value) => {
              field.onChange(value?.toLocaleDateString() ? value?.toLocaleDateString() : null);
            }}
            label={props.label}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          helperText={props.helperText}
          variant={props.variant}
          label={props.label}
          fullWidth
          InputProps={{
            endAdornment: props.end,
          }}
        />
      )}
    />
  );
};

const MissingDocs = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{ hatarido: "" }]);

  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
              width: "400px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
                width: "100%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                <DatePicker
                  inputFormat="yyyy.MM.dd."
                  mask="____.__.__."
                  value={item.hatarido ? moment(item.hatarido, "YYYY-MM-DD") : null}
                  onChange={(value) => {
                    const temp = [...data];
                    temp[index].hatarido = value?.toLocaleDateString() === undefined ? null : value?.toLocaleDateString();
                    setdata(temp);
                  }}
                  label={"hiánypótlás határideje"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{
                        marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              {index + 1 === data.length && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  style={{
                    maxWidth: "30px",
                    maxHeight: "30px",
                    minWidth: "30px",
                    minHeight: "30px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({ hatarido: "" });
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const MissingDocs2 = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{ javitottDokumentumok: "" }]);

  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
              width: "400px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
                width: "100%",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                <DatePicker
                  inputFormat="yyyy.MM.dd."
                  mask="____.__.__."
                  value={item.javitottDokumentumok ? moment(item.javitottDokumentumok, "YYYY-MM-DD") : null}
                  onChange={(value) => {
                    const temp = [...data];
                    temp[index].javitottDokumentumok = value?.toLocaleDateString() === undefined ? null : value?.toLocaleDateString();
                    setdata(temp);
                  }}
                  label={"javított dokumentumok visszaküldése"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{
                        marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>

              {index + 1 === data.length && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  style={{
                    maxWidth: "30px",
                    maxHeight: "30px",
                    minWidth: "30px",
                    minHeight: "30px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({ javitottDokumentumok: "" });
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const AddManagement = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userList } = useData();
  const [docFiles, setdocFiles] = useState([]);
  const [data] = useState({
    projektFelelose: "",
    azonosito: "",
    beadasIdeje: "",
    befejezesiHatarido: "",
    epitesOsszegeHuf: "",
    epitesOsszegeEur: "",
    gepbeszerzesValue: "",
    ingatlanEpitesValue: "",
    ingatlanFelujitasValue: "",
    ingatlanKorszerusitesValue: "",
    ingatlanVasarlasValue: "",
    jovahagyasNapja: "",
    szerzodeskotesDatum: "",
    szolgaltatasOsszegeEur: "",
    szolgaltatasOsszegeHuf: "",
    arubeszerzesOsszegeEur: "",
    arubeszerzesOsszegeHuf: "",
    cegnev: "",
    cegEmail: "",
    statusz: "",
    adoszamaArubeszerzes: "",
    adoszamaArubeszerzes1: "",
    adoszamaArubeszerzes2: "",
    adoszamaEpites: "",
    adoszamaEpites1: "",
    adoszamaEpites2: "",
    adoszamaSzolgaltatas: "",
    adoszamaSzolgaltatas1: "",
    adoszamaSzolgaltatas2: "",
    ajanlatIdopontjaArubeszerzes: "",
    ajanlatIdopontjaArubeszerzes1: "",
    ajanlatIdopontjaArubeszerzes2: "",
    ajanlatIdopontjaEpites: "",
    ajanlatIdopontjaEpites1: "",
    ajanlatIdopontjaEpites2: "",
    ajanlatIdopontjaSzolgaltatas: "",
    ajanlatIdopontjaSzolgaltatas1: "",
    ajanlatIdopontjaSzolgaltatas2: "",
    ajanlatOsszegeArubeszerzes1: "",
    ajanlatOsszegeArubeszerzes2: "",
    ajanlatOsszegeEpites1: "",
    ajanlatOsszegeEpites2: "",
    ajanlatOsszegeSzolgaltatas1: "",
    ajanlatOsszegeSzolgaltatas2: "",
    eurArfolyamArubeszerzes1: "",
    eurArfolyamArubeszerzes2: "",
    eurArfolyamEpites: "",
    eurArfolyamEpites1: "",
    eurArfolyamEpites2: "",
    eurArfolyamSzolgaltatas: "",
    eurArfolyamSzolgaltatas1: "",
    eurArfolyamSzolgaltatas2: "",
    eurArfolyambeszerzes: "",
    nyertesSzallitoNeveArubeszerzes: "",
    nyertesSzallitoNeveEpites: "",
    nyertesSzallitoNeveSzolgaltatas: "",
    szallitoNeveArubeszerzes1: "",
    szallitoNeveArubeszerzes2: "",
    szallitoNeveEpites1: "",
    szallitoNeveEpites2: "",
    szallitoNeveSzolgaltatas1: "",
    szallitoNeveSzolgaltatas2: "",
  });
  const { handleSubmit, control } = useForm({
    defaultValues: state ? state : data,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const [hianypotlasHatarideje, sethianypotlasHatarideje] = useState(
    useData().managementData.find((item) => item?.key === state?.key)?.hianypotlasHatarideje
  );
  const [javitottDokumentumok, setjavitottDokumentumok] = useState(
    useData().managementData.find((item) => item?.key === state?.key)?.javitottDokumentumok
  );
  const [projektCelja, setprojektCelja] = useState(useData().managementData.find((item) => item?.key === state?.key)?.projektCelja);
  const [eszkozok, seteszkozok] = useState(useData().managementData.find((item) => item?.key === state?.key)?.eszkozok);
  const [szolgaltatas, setszolgaltatas] = useState(useData().managementData.find((item) => item?.key === state?.key)?.szolgaltatas);
  const [arubeszerzes, setarubeszerzes] = useState(useData().managementData.find((item) => item?.key === state?.key)?.arubeszerzes);
  const [epites, setepites] = useState(useData().managementData.find((item) => item?.key === state?.key)?.epites);
  const { customerData } = useData();
  const auth = useAuth();

  useEffect(() => {
    if (state?.docURL) {
      setdocFiles(state.docURL);
    }
  }, [state?.docURL]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handleFileSelect = (event, name, project) => {
    const files = event.target.files;
    const temp = [];

    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }

    for (let index = 0; index < temp.length; index++) {
      if (temp[index] !== undefined) {
        if (docFiles.length > 0) {
          if (docFiles.filter((e) => e.docName === temp[index].name).length > 0) {
            enqueueSnackbar("Ilyen elnevezésű fájl már létezik!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          } else {
            const tempArray = [...docFiles];
            tempArray.push({
              file: temp[index],
              docCategory: name,
              docName: temp[index].name,
              project,
            });
            setdocFiles(tempArray);
          }
        } else {
          const tempArray = [...docFiles];
          tempArray.push({
            file: temp[index],
            docCategory: name,
            docName: temp[index].name,
            project,
          });
          setdocFiles(tempArray);
        }
      }
    }
    handleClose();
  };

  const handleDelete = (index, doc) => {
    const date = new Date().toLocaleString() + "";
    const temp = [...docFiles];
    temp.splice(
      temp.findIndex((item) => item.docName === doc.docName),
      1
    );
    setdocFiles(temp);

    if (doc.docURL !== undefined && doc.import === undefined) {
      const db = getDatabase();
      const storage = getStorage();
      const dataRef = ref(db, "menedzsment/" + state.key);
      const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + doc.docName);
      update(dataRef, { docURL: temp });
      deleteObject(docRef);

      const tempLog = [...state.log];
      tempLog.push({ kind: "delete", name: doc.docName, date: date });
      state.log = tempLog;
    }
  };

  const onSubmit = (formData) => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    let key = "";
    formData.hianypotlasHatarideje = hianypotlasHatarideje;
    formData.javitottDokumentumok = javitottDokumentumok;
    formData.projektCelja = projektCelja;
    formData.eszkozok = eszkozok;
    formData.szolgaltatas = szolgaltatas;
    formData.arubeszerzes = arubeszerzes;
    formData.epites = epites;

    if (state) {
      const dataRef = ref(database, "menedzsment/" + state.key);
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          delete formData[key];
        }
      });

      if (DeepDiff(state, formData) !== undefined) {
        let temp;
        if (state?.log) {
          temp = [...state.log];
        } else {
          temp = [];
        }
        const log = DeepDiff(state, formData);
        log.forEach((element) => {
          element.date = date;
          if (element.path[0] !== "log") {
            temp.push(element);
          }
        });
        formData.log = temp;
      }

      formData.modositasDatuma = date;
      update(dataRef, formData);
      if (docFiles.length > 0) {
        const storage = getStorage();
        let urls = [];
        // if (state?.docURL) {
        //   urls = [...state.docURL];
        // } else {
        //   urls = [];
        // }
        docFiles.forEach((item) => {
          if (item.docURL === undefined) {
            const str = storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName);
            const tempLog = [...formData.log];
            tempLog.push({ kind: "upload", name: item.docName, date: date });
            update(ref(database, "menedzsment/" + state.key), {
              log: tempLog,
            });
            uploadBytes(str, item.file).then(
              () => {
                getDownloadURL(storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName))
                  .then((url) => {
                    urls.push({
                      docName: item.docName,
                      docURL: url,
                      docCategory: item.docCategory,
                      project: item.project ? item.project : "",
                    });
                  })
                  .then(() => {
                    update(ref(database, "menedzsment/" + state.key), {
                      docURL: urls,
                    });
                  });
              },
              (err) => {
                console.log(err);
              }
            );
          } else {
            urls.push(item);
            update(ref(database, "menedzsment/" + state.key), {
              docURL: urls,
            });
          }
        });
      } else {
        update(ref(database, "menedzsment/" + state.key), {
          docURL: [],
        });
      }
      navigate(-1);
    } else {
      formData.rogzitesDatuma = date;
      let count = 0;
      const dataRef = ref(database, "/menedzsment");
      try {
        push(dataRef, formData).then(async (snap) => {
          key = snap.key;
          if (docFiles.length > 0) {
            const storage = getStorage();
            let urls = [];

            const upload = new Promise((resolve, reject) => {
              docFiles.forEach((file, index) => {
                if (file.import) {
                  urls.push({
                    docName: file.docName,
                    docURL: file.docURL,
                    docCategory: file.docCategory,
                    import: true,
                  });
                  count = count + 1;
                  if (count === docFiles.length) {
                    resolve();
                  }
                } else {
                  const str = storageRef(storage, "dokumentumok/" + key + "_" + file.docName);
                  uploadBytes(str, file.file).then(() => {
                    getDownloadURL(storageRef(storage, "dokumentumok/" + key + "_" + file.docName)).then((url) => {
                      urls.push({
                        docName: file.docName,
                        docURL: url,
                        docCategory: file.docCategory,
                        project: file.project ? file.project : "",
                      });
                      count = count + 1;
                      if (count === docFiles.length) {
                        resolve();
                      }
                    });
                  });
                }
              });
            });
            upload.then(() => {
              update(ref(database, "menedzsment/" + key), {
                docURL: urls,
              });
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
      navigate(-1);
    }
  };

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5" style={{}}>
              {state ? "Projekt menedzsment szerkesztése" : "Új Projekt menedzsment"}
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIosIcon />}>
              Vissza
            </Button>
          </Box>
        </Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ marginBottom: "20px" }}>
                    Cég kiválasztása
                  </Typography>
                  <FormControl sx={{ width: "100%", mb: "30px" }}>
                    <InputLabel id="demo-simple-select-label">Cég</InputLabel>
                    <Controller
                      name="cegnev"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} labelId="demo-simple-select-label" id="demo-simple-select" label="Cég" fullWidth>
                          {customerData &&
                            customerData.map((item, index) => (
                              <MenuItem key={index} value={item.cegnev}>
                                {item.cegnev}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                {(auth.role === "Admin" || auth.role === "Irodavezető") && (
                  <Grid item xs={12}>
                    <FormTextField
                      control={control}
                      width="200px"
                      label="Cég Email címe"
                      name="cegEmail"
                      helperText="Több email esetén vesszővel elválasztva"
                    />
                  </Grid>
                )}
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label-user">Projekt felelőse</InputLabel>
                      <Controller
                        name="projektFelelose"
                        control={control}
                        render={({ field }) => (
                          <Select {...field} labelId="demo-simple-select-label-user" id="demo-simple-select-user" label="Projekt felelőse">
                            {userList &&
                              userList.map((item, index) => (
                                <MenuItem key={index} value={item.email}>
                                  {item.email}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField control={control} label="Státusz" name="statusz" />
                  </Grid>
                  <Grid container spacing={2} item>
                    <Grid item xs={12} sx={{ marginTop: "15px" }}>
                      <Typography>Projekt célja:</Typography>
                    </Grid>
                    <Grid container spacing={1} item xs={12}>
                      <Grid item xs={12}>
                        <Target data={projektCelja} set={setprojektCelja} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} item>
                  <Grid item xs={12} sx={{ marginTop: "15px" }}>
                    <Typography>Szerepkör:</Typography>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      <FormCheckBox control={control} name="ajanlatotKero" label="Ajánlatot kérő" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormCheckBox control={control} name="ajanlatotAdo" label="Ajánlatot adó" />
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ my: "10px" }} />
                <Grid container spacing={2} item>
                  <Grid item xs={12} sx={{ marginTop: "15px" }}>
                    <Typography>Eljárás típusa:</Typography>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      <FormCheckBox control={control} name="hazai" label="Hazai" />
                    </Grid>
                    <Grid item xs={6}>
                      <FormCheckBox control={control} name="eu" label="EU" />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt fájlok
                </Typography>
                <Box sx={{}}>
                  <Button onClick={handleClick} variant="outlined" endIcon={<FileUploadIcon />}>
                    Projekt fájlok
                  </Button>
                </Box>
                <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                  <MenuItem value={"Kiküldött megbízási szerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Kiküldött megbízási szerződés", "projekt");
                        }}
                      />
                      Kiküldött megbízási szerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Aláírt megbízási szerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file2"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file2"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Aláírt megbízási szerződés", "projekt");
                        }}
                      />
                      Aláírt megbízási szerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Benyújtott dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file6"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file6"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Benyújtott dokumentumok", "projekt");
                        }}
                      />
                      Benyújtott dokumentumok
                    </label>
                  </MenuItem>
                  <MenuItem value={"Hiánypótlás értesítő"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file16"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file16"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Hiánypótlás értesítő", "projekt");
                        }}
                      />
                      Hiánypótlás értesítő
                    </label>
                  </MenuItem>
                  <MenuItem value={"Hiánypótlási dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file17"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file17"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Hiánypótlási dokumentumok", "projekt");
                        }}
                      />
                      Hiánypótlási dokumentumok
                    </label>
                  </MenuItem>
                  <MenuItem value={"Bírálat"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file18"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file18"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Bírálat", "projekt");
                        }}
                      />
                      Bírálat
                    </label>
                  </MenuItem>
                  <MenuItem value={"Módosítás"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file19"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file19"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Módosítás", "projekt");
                        }}
                      />
                      Módosítás
                    </label>
                  </MenuItem>
                  <MenuItem value={"Egyéb dokumentumok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file20"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file20"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Egyéb dokumentumok", "projekt");
                        }}
                      />
                      Egyéb dokumentumok
                    </label>
                  </MenuItem>
                </Menu>
                <Box sx={{ marginTop: "10px" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fájl neve</TableCell>
                          <TableCell>Kategória</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {docFiles &&
                          docFiles
                            .filter((item) => {
                              return item.project === "projekt";
                            })
                            .map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {item.docName}
                                  {item.company && (
                                    <>
                                      <br />
                                      Cég: {item.company}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>{item.docCategory}</TableCell>
                                <TableCell sx={{}}>
                                  <IconButton onClick={() => handleDelete(index, item)}>
                                    <DeleteIcon></DeleteIcon>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>

              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Ügyfélanyag
                </Typography>
                <Box>
                  <Button onClick={(event) => setAnchorEl2(event.currentTarget)} variant="outlined" endIcon={<FileUploadIcon />}>
                    Ügyfélanyag
                  </Button>
                </Box>
                <Menu open={open2} onClose={handleClose} anchorEl={anchorEl2}>
                  <MenuItem value={"Aláírási címpéldány"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Aláírási címpéldány");
                        }}
                      />
                      Aláírási címpéldány
                    </label>
                  </MenuItem>
                  <MenuItem value={"Társasági szerződés"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file2"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file2"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Társasági szerződés");
                        }}
                      />
                      Társasági szerződés
                    </label>
                  </MenuItem>
                  <MenuItem value={"Tagjegyzék"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file6"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file6"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Tagjegyzék");
                        }}
                      />
                      Tagjegyzék
                    </label>
                  </MenuItem>
                  <MenuItem value={"TAO bevallás"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file16"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file16"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "TAO bevallás");
                        }}
                      />
                      TAO bevallás
                    </label>
                  </MenuItem>
                  <MenuItem value={"Disztribútori"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file17"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file17"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Disztribútori");
                        }}
                      />
                      Disztribútori
                    </label>
                  </MenuItem>
                  <MenuItem value={"Árajánlat"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file18"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file18"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Árajánlat");
                        }}
                      />
                      Árajánlat
                    </label>
                  </MenuItem>
                  <MenuItem value={"Egyéb fájlok"} sx={{ padding: "0px" }}>
                    <label
                      htmlFor="contained-button-file19"
                      style={{
                        width: "100%",
                        padding: "6px 16px 6px 16px",
                        cursor: "pointer",
                      }}
                    >
                      <input
                        multiple
                        style={{ display: "none" }}
                        accept="application/pdf, .xlsx, .xls,.docx"
                        id="contained-button-file19"
                        type="file"
                        onChange={(event) => {
                          handleFileSelect(event, "Egyéb fájlok");
                        }}
                      />
                      Egyéb fájlok
                    </label>
                  </MenuItem>
                </Menu>
                <Box sx={{ marginTop: "10px" }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Fájl neve</TableCell>
                          <TableCell>Kategória</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {docFiles &&
                          docFiles
                            .filter((item) => {
                              return item.project === undefined || item.project === "";
                            })
                            .map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {item.docName}
                                  {item.company && (
                                    <>
                                      <br />
                                      Cég: {item.company}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>{item.docCategory}</TableCell>
                                <TableCell sx={{}}>
                                  <IconButton onClick={() => handleDelete(index, item)}>
                                    <DeleteIcon></DeleteIcon>
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Projekt időpontok
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormDate control={control} name="szerzodeskotesDatum" label="Szerződéskötés dátuma" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} name="befejezesiHatarido" label="Befejezési határidő" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} name="beadasIdeje" label="Beadás ideje" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate control={control} name="jovahagyasNapja" label="Jóváhagyás napja" />
                  </Grid>
                  <Grid item xs={12} container justifyContent={"center"}>
                    <MissingDocs data={hianypotlasHatarideje} set={sethianypotlasHatarideje} />
                  </Grid>
                  <Grid item xs={12} container justifyContent={"center"}>
                    <MissingDocs2 data={javitottDokumentumok} set={setjavitottDokumentumok} />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px", marginBottom: "20px" }}>
                <Typography variant="h6" style={{ marginBottom: "20px" }}>
                  Pénzügy
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Equipment data={epites} set={setepites} type={"Építés"} />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <Equipment data={arubeszerzes} set={setarubeszerzes} type={"Árubeszerzés"} />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <Equipment data={szolgaltatas} set={setszolgaltatas} type={"Szolgáltatás"} />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <Equipment data={eszkozok} set={seteszkozok} type={"Eszköz"} />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button type="submit" variant="contained" endIcon={<AddIcon />}>
              {state ? "Adatok mentése" : "Hozzáadás"}
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default AddManagement;
