import { Box, Card, Grid, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useData } from "../contexts/DataContext";
import { Button, Divider } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { useNavigate } from "react-router";
import Stats from "./Stats";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import moment from "moment";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { useAuth } from "../contexts/UserContext";

const Main = () => {
  const [todo, settodo] = useState();
  const data = useData();
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [page2, setPage2] = React.useState(1);
  const [showData, setshowData] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageCount2, setpageCount2] = useState(1);
  const auth = useAuth();

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChange2 = (event, value) => {
    setPage2(value);
  };
  const rowsPerPage = 7;
  const rowsPerPage2 = 7;

  useEffect(() => {
    if (data !== undefined) {
      if (data.emailData && data.notificationData) {
        const temp = data.emailData;
        const temp2 = data.notificationData;

        const temp3 = [...temp, ...temp2]?.sort(function (a, b) {
          return moment(b.date, "YYYY-MM-DD").toDate() - moment(a.date, "YYYY-MM-DD").toDate();
        });

        setshowData(temp3);

        if (temp3?.length < rowsPerPage) {
          setpageCount(1);
        } else {
          setpageCount(Math.ceil(temp3?.length / rowsPerPage));
        }
      }

      let temp = [];
      data.applicationsData?.forEach((item) => {
        if (item.tennivalok) {
          item.tennivalok.forEach((j) => {
            j.loc = "applications/" + item.key;
            j.cegnev = item.cegnev;
          });
          temp.push(...item.tennivalok);
        }
      });
      data.loanData?.forEach((item) => {
        if (item.tennivalok) {
          item.tennivalok.forEach((j) => {
            j.loc = "loans/" + item.key;
            j.cegnev = item.cegnev;
          });
          temp.push(...item.tennivalok);
        }
      });
      data.managementData?.forEach((item) => {
        if (item.tennivalok) {
          item.tennivalok.forEach((j) => {
            j.loc = "managements/" + item.key;
            j.cegnev = item.cegnev;
          });

          temp.push(...item.tennivalok);
        }
      });
      data.procurementData?.forEach((item) => {
        if (item.tennivalok) {
          item.tennivalok.forEach((j) => {
            j.loc = "procurements/" + item.key;
            j.cegnev = item.cegnev;
          });

          temp.push(...item.tennivalok);
        }
      });

      const selectedTodo = temp
        .filter((item) => {
          return item.teljesitett !== true;
        })
        ?.sort(function (a, b) {
          return (
            Math.floor(moment(a.datum, "YYYY-MM-DD").toDate().getTime() / 1000) - Math.floor(moment(b.datum, "YYYY-MM-DD").toDate().getTime() / 1000)
          );
        });

      settodo(selectedTodo);

      if (selectedTodo?.length < rowsPerPage2) {
        setpageCount2(1);
      } else {
        setpageCount2(Math.ceil(selectedTodo?.length / rowsPerPage2));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <Box
            sx={{
              marginTop: "40px",
              marginLeft: { xs: "30px", lg: "60px" },
              marginRight: { xs: "30px" },
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "white",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography variant="h6">Feladatok</Typography>
            <Card sx={{ marginTop: "10px", padding: "20px" }}>
              {todo &&
                todo?.slice((page2 - 1) * rowsPerPage2, (page2 - 1) * rowsPerPage2 + rowsPerPage2).map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      margin: "15px 15px 7px 7px ",
                      padding: "10px",
                      borderRadius: "6px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <BusinessCenterIcon sx={{ color: "#eeba83" }} />
                      {item.cegnev}

                      <AccountCircleIcon sx={{ color: "#cfb5eb" }} />
                      <Box
                        sx={{
                          display: "inline-block",
                          fontWeight: "400",
                        }}
                      >
                        {item.felelos ? item.felelos : null}
                      </Box>
                    </Box>
                    <Divider sx={{ my: "10px" }}></Divider>
                    <Box
                      sx={{
                        wordBreak: "break-word",
                        whiteSpace: "pre-line",
                        textDecoration: item.teljesitett ? "line-through" : "none",
                      }}
                    >
                      {item.szoveg}
                    </Box>
                    <Divider sx={{ my: "10px" }}></Divider>
                    <Box
                      sx={{
                        fontWeight: "500",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <Box>{item.datum}</Box>
                      <Box>
                        {/* <IconButton
                            aria-label="delete"
                            color="warning"
                            sx={{ marginRight: "10px" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Switch
                            checked={item.teljesitett === true ? true : false}
                            color="success"
                            inputProps={{ "aria-label": "controlled" }}
                          /> */}
                        <Button
                          onClick={() => {
                            navigate(item.loc);
                          }}
                        >
                          Megnyitás
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}
              <Stack spacing={2}>
                <Pagination count={pageCount2} page={page2} onChange={handleChange2} />
              </Stack>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Box
            sx={{
              marginTop: "40px",
              marginLeft: { xs: "30px" },
              marginRight: { xs: "30px", lg: "60px" },
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "white",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Typography variant="h6" sx={{ mb: "20px" }}>
              Kiküldött emailek
            </Typography>
            <Box>
              {showData?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage).map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    padding: "10px",
                    backgroundColor: "#f3f3f3",
                    mb: "10px",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>{item.notification === undefined ? "Munkaidő nyilvántartás" : "Értesítés"}</Box>
                    <Box>{item.date}</Box>
                  </Box>
                  <Box
                    sx={{
                      mt: "4px",
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>{item.cegnev}</Box>
                    <Box>
                      {item.accepted && (
                        <Tooltip title={`Elfogadva, ekkor: ${item.elfogadasDatuma}`}>
                          <DoneIcon sx={{ fill: "green" }} />
                        </Tooltip>
                      )}
                      {item.accepted === false && (
                        <Tooltip title="Elfogadásra vár">
                          <HourglassBottomIcon sx={{ fill: "orange" }} />
                        </Tooltip>
                      )}
                      {item.accepted === undefined && item.notification === undefined && (
                        <Tooltip title="Hiba elküldés során">
                          <ErrorIcon sx={{ fill: "#fc6060" }} />
                        </Tooltip>
                      )}
                      {item.notification === true && <Typography variant="body2">{item.type}</Typography>}
                    </Box>
                  </Box>
                </Box>
              ))}
              <Stack spacing={2}>
                <Pagination count={pageCount} page={page} onChange={handleChange} />
              </Stack>
            </Box>
          </Box>
          {auth.role === "Admin" && (
            <Box
              sx={{
                marginTop: "40px",
                marginLeft: { xs: "30px" },
                marginRight: { xs: "30px", lg: "60px" },
                padding: "20px",
                borderRadius: "8px",
                backgroundColor: "white",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              <Stats />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          {/* <Box
            sx={{
              marginTop: "40px",
              marginLeft: { xs: "30px", lg: "60px" },
              marginRight: { xs: "30px" },
              mb: "20px",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "white",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <Stats />
          </Box> */}
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
