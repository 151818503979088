import {
  Button,
  Card,
  Chip,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useData } from "../contexts/DataContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getDatabase, onValue, push, ref, remove, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import hu from "date-fns/locale/hu";

const Postal = () => {
  const { customerData } = useData();
  const [loadedData, setloadedData] = useState();
  const [data, setdata] = useState({ cegnev: "", date: null, tipus: "" });
  const [query, setquery] = useState("");

  useEffect(() => {
    const db = getDatabase();
    const dataRef = ref(db, "/iktatas");
    onValue(dataRef, (snapshot) => {
      console.log("fetching data - iktatas");
      const d = snapshot.val();
      const temp = [];
      for (let id in d) {
        d[id].key = id;
        temp.push(d[id]);
      }
      setloadedData(temp);
    });
  }, []);

  const handleSubmit = () => {
    const db = getDatabase();

    const dataRef = ref(db, "iktatas");
    push(dataRef, {
      cegnev: data.cegnev,
      datum: data.date,
      tipus: data.tipus,
    }).then((snap) => {
      const key = snap.key;
      if (data.file) {
        const storage = getStorage();
        const fileRef = storageRef(storage, "iktatas/" + key + "_" + data.file[0].name);
        uploadBytes(fileRef, data.file[0]).then(() => {
          getDownloadURL(fileRef).then((url) => {
            update(ref(db, "iktatas/" + snap.key), {
              fileURL: url,
              fileName: data.file[0].name,
              scanDate: data.file[0]?.lastModifiedDate.toLocaleDateString(),
            });
          });
        });
      }
    });

    setdata({ cegnev: "", date: null, tipus: null });
  };

  const handleDelete = (item) => {
    const db = getDatabase();
    const dataRef = ref(db, "iktatas/" + item.key);
    remove(dataRef);

    if (item.fileName) {
      const storage = getStorage();
      const fileRef = storageRef(storage, "iktatas/" + item.key + "_" + item.fileName);
      deleteObject(fileRef);
    }
  };

  return (
    <div style={{ marginLeft: "", marginTop: "40px" }}>
      <Container maxWidth="lg">
        <Card
          sx={{
            padding: "20px",
          }}
        >
          <Typography variant="h6">Iktatás</Typography>
        </Card>
        <Card sx={{ marginTop: "20px", p: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Típus</InputLabel>
                <Select
                  label="Típus"
                  sx={{ width: "250px" }}
                  value={data.tipus}
                  onChange={(e) => setdata({ ...data, tipus: e.target.value })}
                  defaultValue=""
                >
                  <MenuItem value="Bejövő">Bejövő</MenuItem>
                  <MenuItem value="Kimenő">Kimenő</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Cég kiválasztása</InputLabel>
              <Select
                label="Cég kiválasztása"
                value={data.cegnev}
                onChange={(e) => setdata({ ...data, cegnev: e.target.value })}
                sx={{ width: "250px" }}
              >
                {customerData &&
                  customerData.map((item, index) => (
                    <MenuItem key={index} value={item.cegnev}>
                      {item.cegnev}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
              <DatePicker
                inputFormat="yyyy.MM.dd."
                mask="____.__.__."
                label="Postára adás"
                value={data.date ? moment(data.date, "YYYY-MM-DD").toDate() : null}
                onChange={(newValue) => {
                  setdata({ ...data, date: newValue?.toLocaleDateString() ? newValue?.toLocaleDateString() : null });
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor="contained-button-file">
                <input
                  style={{ display: "none" }}
                  accept="application/pdf"
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => {
                    setdata({ ...data, file: e.target.files });
                  }}
                />
                <Button variant="outlined" component="span">
                  Fájl feltöltése
                </Button>
              </label>
              {data.file && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Chip label={data.file[0].name} sx={{ mt: "5px" }}></Chip>
                  <Typography variant="body2">{data.file[0]?.lastModifiedDate.toLocaleDateString()}</Typography>
                </Box>
              )}
            </Box>
            <Divider orientation="vertical" flexItem></Divider>
            <Button variant="contained" onClick={() => handleSubmit()}>
              Iktatás rögzítése
            </Button>
          </Box>
        </Card>
        <Card sx={{ p: "20px", mt: "20px" }}>
          <TextField label="Keresés" value={query} onChange={(e) => setquery(e.target.value)} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Dátum</TableCell>
                <TableCell>Típus</TableCell>
                <TableCell>Cég</TableCell>
                <TableCell>Szkennelés dátuma</TableCell>
                <TableCell>Fájlok</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadedData &&
                loadedData
                  .filter((item) => {
                    const name = item.cegnev.toLowerCase();
                    return name.includes(query) || item.datum.includes(query);
                  })
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.datum}</TableCell>
                      <TableCell>{item.tipus}</TableCell>
                      <TableCell>{item.cegnev}</TableCell>
                      <TableCell>{item.scanDate}</TableCell>
                      <TableCell>
                        {item.fileName && (
                          <Chip
                            label={item.fileName}
                            variant="outlined"
                            color="primary"
                            onClick={() => window.open(item.fileURL, "_blank", "noopener,noreferrer")}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDelete(item)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </div>
  );
};

export default Postal;
