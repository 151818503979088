import { Box, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import hu from "date-fns/locale/hu";

const Submitted = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{}]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2} alignItems={"flex-end"}>
                  <Grid item xs={6}>
                    <TextField
                      label={index + 1 + ". Finanszírozó neve"}
                      defaultValue={item.finanszirozoNeve}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].finanszirozoNeve = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.benyujtasDatuma ? moment(item.benyujtasDatuma, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].benyujtasDatuma = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Benyújtás dátuma"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Ajánlott összeg"
                      defaultValue={item.ajanlottOsszeg}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].ajanlottOsszeg = e.target.value;
                        setdata(temp);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">Ft</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Megjegyzés"
                      defaultValue={item.megjegyzes}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].megjegyzes = e.target.value;
                        setdata(temp);
                      }}
                      multiline
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: "5px" }} />
                  </Grid>
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Submitted;
