import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { useAuth } from "../contexts/UserContext";
import {
  Button,
  Container,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { settings } from "../config";
import Menu from "@mui/material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box } from "@mui/system";

const url = settings.url;

const roles = [
  {
    value: "Irodavezető",
    label: "Irodavezető",
  },
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Adatrögzítő",
    label: "Adatrögzítő",
  },
];

export default function Colleagues() {
  const [data, setdata] = useState();
  const [addData, setaddData] = useState({
    email: "",
    password: "",
    tel: "",
  });
  const { currentUser } = useAuth();
  const [inputRole, setinputRole] = useState("Admin");
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setdialog] = useState(false);
  const [s, sets] = useState();

  const handleChange = (event) => {
    setinputRole(event.target.value);
  };

  const fetchData = () => {
    currentUser.getIdToken().then((token) => {
      axios
        .get(url + "/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setdata(res.data);
        });
    });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    currentUser.getIdToken().then((token) => {
      axios({
        method: "post",
        url: url + "/adduser",
        data: {
          addData,
          role: inputRole,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          enqueueSnackbar("Sikeres adatbevitel!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          setaddData({
            email: "",
            password: "",
            tel: "",
          });
          if (res.status === 200) {
            fetchData();
            sets(res.data);
            setdialog(true);
          }
        })
        .catch(() => {
          enqueueSnackbar("Hiba történt!", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        });
    });
  };

  const MenuButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [dialogOpen, setdialogOpen] = useState(false);
    const [dialogEmail, setdialogEmail] = useState();
    const [dialogId, setdialogId] = useState();
    const [dialogPass, setdialogPass] = useState("");

    const handleDelete = (uid) => {
      currentUser.getIdToken().then((token) => {
        axios({
          method: "post",
          url: url + "/deleteuser",
          data: {
            uid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar("Sikeres törlés!", {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
              fetchData();
            }
          })
          .catch(() => {
            enqueueSnackbar("Hiba történt!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          });
      });
      setAnchorEl(null);
    };

    const showUpdateDialog = (id, email) => {
      setdialogEmail(email);
      setdialogId(id);
      setdialogOpen(true);
      setAnchorEl(null);
    };

    const updatePass = (uid) => {
      currentUser.getIdToken().then((token) => {
        axios({
          method: "post",
          url: url + "/updateuser",
          data: {
            uid,
            pass: dialogPass,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar("Sikeres jelszóváltoztatás!", {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
              fetchData();
            }
          })
          .catch(() => {
            enqueueSnackbar("Hiba történt!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          });
      });
      setdialogOpen(false);
    };
    return (
      <>
        <IconButton onClick={handleClick}>
          <SettingsIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleDelete(props.id)}>Törlés</MenuItem>
          <MenuItem onClick={() => showUpdateDialog(props.id, props.email)}>
            Új jelszó
          </MenuItem>
        </Menu>
        <Dialog onClose={() => setdialogOpen(false)} open={dialogOpen}>
          <Box
            sx={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{dialogEmail} új jelszava:</Typography>
            <TextField
              variant="outlined"
              label="Jelszó"
              required
              helperText="Min. 6 karakter"
              sx={{ marginTop: 2 }}
              value={dialogPass}
              onChange={({ target }) => setdialogPass(target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              onClick={() => {
                updatePass(dialogId);
              }}
            >
              Jelszó megváltoztatása
            </Button>
          </Box>
        </Dialog>
      </>
    );
  };

  const TableList = (props) => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              {/* <TableCell>Azonosító</TableCell> */}
              <TableCell>Telefonszám</TableCell>
              <TableCell>Szerepkör</TableCell>
              <TableCell>Utoljára belépett</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.userData ? (
              props.userData.map((row) => (
                <TableRow
                  key={row.uid}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.email}</TableCell>
                  {/* <TableCell>{row.uid}</TableCell> */}
                  <TableCell>{row.tel}</TableCell>
                  <TableCell>{row.role}</TableCell>
                  <TableCell>{row.meta}</TableCell>
                  <TableCell>
                    {/* <Button
                      variant="contained"
                      onClick={() => handleDelete(row.uid)}
                      endIcon={<Delete />}
                    >
                      Törlés
                    </Button> */}
                    <MenuButton id={row.uid} email={row.email} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>Betöltés...</TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "40px" }}>
      <Paper elevation={6} style={{ padding: "20px" }}>
        <Typography variant="h6" color="initial" gutterBottom>
          Felhasználók
        </Typography>
        <TableList userData={data}></TableList>
        <form onSubmit={handleSubmit}>
          <div style={{ marginTop: 20, gap: 20, display: "flex" }}>
            <TextField
              variant="outlined"
              label="Felhasználónév (Email)"
              required
              value={addData.email}
              onChange={({ target }) =>
                setaddData((state) => ({ ...state, email: target.value }))
              }
            />
            <TextField
              variant="outlined"
              label="Jelszó"
              required
              helperText="Min. 6 karakter"
              value={addData.password}
              onChange={({ target }) =>
                setaddData((state) => ({ ...state, password: target.value }))
              }
            />
            <TextField
              select
              label="Szerepkör"
              value={inputRole}
              onChange={handleChange}
              sx={{ width: "200px" }}
            >
              {roles.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              label="Telefonszám"
              required
              value={addData.tel}
              onChange={({ target }) =>
                setaddData((state) => ({ ...state, tel: target.value }))
              }
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ marginTop: "10px" }}
            endIcon={<Add />}
            onClick={() => {}}
          >
            Fiók hozzáadása
          </Button>
        </form>
      </Paper>
      <Dialog
        open={dialog}
        onClose={() => {
          setdialog(false);
          sets("");
        }}
      >
        <Typography sx={{ padding: "20px" }}>
          Fiók kulcsa: <b>{s}</b>
        </Typography>
      </Dialog>
    </Container>
  );
}
