import { Box, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import hu from "date-fns/locale/hu";

const Milestone = (props) => {
  const [data, setdata] = useState(props.data ? props.data : [{}]);
  useEffect(() => {
    props.set(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box>
      {data.map((item, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: index === 0 ? "0px" : "7px",
            }}
            key={index}
          >
            {index > 0 ? (
              <IconButton
                onClick={() => {
                  const temp = [...data];
                  temp.splice(index, 1);
                  setdata(temp);
                }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  marginLeft: data.length > 1 ? (index > 0 ? null : { marginLeft: "40px" }) : null,
                }}
              >
                <Grid container spacing={2} alignItems={"flex-end"}>
                  <Grid item xs={12}>
                    {" "}
                    <Typography variant="h5" sx={{ mb: "12px", fontSize: "1.1rem" }}>
                      {index + 1}. Mérföldkő
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      multiline
                      label={"Mérföldkő"}
                      defaultValue={item.merfoldko}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].merfoldko = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "9px" }}>
                      Elszámolás benyújtásának időpontja:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.elszamolasIdopontja ? moment(item.elszamolasIdopontja, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].elszamolasIdopontja = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Időpont"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "9px" }}>
                      Elszámolás benyújtásának <br></br>
                      <b>TO szerinti</b> időpontja:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.elszamolasTOIdopontja ? moment(item.elszamolasTOIdopontja, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].elszamolasTOIdopontja = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Időpont"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Mérföldkő összege"
                      defaultValue={item.merfoldkoOsszege}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].merfoldkoOsszege = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "6px" }}>
                      Elszámolásra benyújtott összeg:
                    </Typography>
                    <TextField
                      label="Összeg"
                      defaultValue={item.elszamolasOsszege}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].elszamolasOsszege = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "6px" }}>
                      Elszámolásra fennmaradó költségvetési összeg:
                    </Typography>
                    <TextField
                      label="Összeg"
                      defaultValue={item.elszamolasraFennmaradoKoltsegvetesiOsszeg}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].elszamolasraFennmaradoKoltsegvetesiOsszeg = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "6px" }}>
                      További elszámolásra fennmaradó támogatási összeg:
                    </Typography>
                    <TextField
                      label="Összeg"
                      defaultValue={item.tovabbiElszamolasraFennmaradoTamogatasiOsszeg}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].tovabbiElszamolasraFennmaradoTamogatasiOsszeg = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
                      <DatePicker
                        inputFormat="yyyy.MM.dd."
                        mask="____.__.__."
                        value={item.merfoldkoDatuma ? moment(item.merfoldkoDatuma, "YYYY-MM-DD") : null}
                        onChange={(e) => {
                          const temp = [...data];
                          temp[index].merfoldkoDatuma = e?.toLocaleDateString() ? e?.toLocaleDateString() : null;
                          setdata(temp);
                        }}
                        label="Mérföldkő dátuma"
                        renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" sx={{ mb: "9px" }}>
                      Mérföldkőig elszámolni kívánt támogatás:
                    </Typography>
                    <TextField
                      label="Támogatás"
                      defaultValue={item.merfoldkoigTamogatas}
                      onBlur={(e) => {
                        const temp = [...data];
                        temp[index].merfoldkoigTamogatas = e.target.value;
                        setdata(temp);
                      }}
                    />
                  </Grid>
                  {index + 1 !== data.length && (
                    <Grid item xs={12}>
                      <Divider sx={{ my: "5px" }} />
                    </Grid>
                  )}
                </Grid>
              </Box>
              {index + 1 === data.length && (
                <IconButton
                  size="large"
                  color="primary"
                  style={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    minWidth: "40px",
                    minHeight: "40px",
                  }}
                  onClick={() => {
                    const temp = [...data];
                    temp.push({});
                    setdata(temp);
                  }}
                >
                  <AddIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Milestone;
