import { Card, Divider, IconButton, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getDatabase, ref, update } from "firebase/database";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const TodoList = (props) => {
  const deleteItem = (todo) => {
    const temp = props.tennivalok;
    temp.forEach((item, index) => {
      if (JSON.stringify(item) === JSON.stringify(todo)) {
        const db = getDatabase();
        const dataRef = ref(db, props.db + "/" + props.id);
        const temp = props.tennivalok;
        temp.splice(index, 1);
        update(dataRef, { tennivalok: temp });
      }
    });
  };

  const handleComplete = (todo) => {
    const db = getDatabase();
    const dataRef = ref(db, props.db + "/" + props.id);
    const temp = props.tennivalok;
    const date = new Date().toLocaleString() + "";

    temp.forEach((item) => {
      if (JSON.stringify(item) === JSON.stringify(todo)) {
        if (item.teljesitett) {
        } else {
          item.teljesitett = true;
          item.teljesitesDatuma = date;
        }
      }
    });

    update(dataRef, { tennivalok: temp });
  };

  return (
    <div>
      {props.tennivalok && (
        <Card sx={{ marginTop: "10px", padding: "20px" }}>
          <Typography sx={{ fontSize: "1.1rem" }}>Feladatok:</Typography>
          {props.tennivalok
            .filter((item) => {
              return !item.teljesitett;
            })
            .sort(function (a, b) {
              return Math.floor(new Date(a.datum).getTime() / 1000) - Math.floor(new Date(b.datum).getTime() / 1000);
            })
            .map((item, index) => (
              <Box
                sx={{
                  margin: "15px 15px 7px 7px ",
                  padding: "10px",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                }}
                key={index}
              >
                <Box
                  sx={{
                    marginBottom: "8px",
                    textDecoration: item.teljesitett ? "line-through" : "none",
                    fontWeight: "500",
                  }}
                >
                  {item.datum}
                </Box>
                <Divider></Divider>
                <Box
                  sx={{
                    my: "8px",
                    whiteSpace: "pre-line",
                    wordBreak: "break-word",
                    textDecoration: item.teljesitett ? "line-through" : "none",
                  }}
                >
                  {item.szoveg}
                </Box>
                <Divider></Divider>
                <Box
                  sx={{
                    marginTop: "8px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "inline-block",
                        fontWeight: "400",
                      }}
                    >
                      {item.felelos ? item.felelos : null}
                    </Box>
                  </Box>
                  <Box>
                    <IconButton aria-label="delete" onClick={() => deleteItem(item)} color="warning" sx={{ marginRight: "10px" }}>
                      <DeleteIcon />
                    </IconButton>
                    <Switch
                      checked={item.teljesitett === true ? true : false}
                      color="success"
                      onChange={() => handleComplete(item)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          <Typography sx={{ fontSize: "1.1rem", marginTop: "30px" }}>Teljesített feladatok:</Typography>
          {props.tennivalok
            .filter((item) => {
              return item.teljesitett;
            })
            .sort(function (a, b) {
              return Math.floor(new Date(a.datum).getTime() / 1000) - Math.floor(new Date(b.datum).getTime() / 1000);
            })
            .map((item, index) => (
              <Box
                sx={{
                  margin: "15px 15px 7px 7px ",
                  padding: "10px",
                  borderRadius: "6px",
                  backgroundColor: "#f5f5f5",
                }}
                key={index}
              >
                <Box
                  sx={{
                    marginBottom: "8px",
                    wordBreak: "break-word",
                  }}
                >
                  {item.szoveg}
                </Box>
                <Divider></Divider>
                <Box sx={{ mt: "8px" }}>
                  <span style={{ fontWeight: 600 }}>Létrehozás dátuma</span>: {item.bevitelDatuma}
                </Box>
                <Box sx={{ mb: "8px" }}>
                  <span style={{ fontWeight: 600 }}>Teljesítés dátuma:</span> {item.teljesitesDatuma}
                </Box>
                <Divider></Divider>
                <Box
                  sx={{
                    marginTop: "8px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Box>
                    {item.datum}
                    <Box
                      sx={{
                        display: "inline-block",
                        fontWeight: "400",
                        ml: "30px",
                      }}
                    >
                      {item.felelos ? item.felelos : null}
                    </Box>
                  </Box>
                  <Box>
                    <IconButton aria-label="delete" onClick={() => deleteItem(item)} color="warning" sx={{ marginRight: "10px" }}>
                      <DeleteIcon />
                    </IconButton>
                    <Switch
                      checked={item.teljesitett === true ? true : false}
                      color="success"
                      onChange={() => handleComplete(item)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
        </Card>
      )}
    </div>
  );
};

export default TodoList;
