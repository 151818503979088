import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  TableHead,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { getDatabase, ref, remove } from "firebase/database";
import { useNavigate } from "react-router";
import { useData } from "../../contexts/DataContext";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FileSaver from "file-saver";

const Settings = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setdialogOpen] = React.useState(false);
  const [selectedId, setselectedId] = React.useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteId = () => {
    if (selectedId && selectedId !== "") {
      const db = getDatabase();
      const dataRef = ref(db, "hitelek/" + selectedId);
      remove(dataRef);
      setselectedId();
    }
  };

  const handleDelete = (id) => {
    setselectedId(id);
    setAnchorEl(null);
    setdialogOpen(true);
  };

  return (
    <div>
      <IconButton
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => handleDelete(props.id)}>Törlés</MenuItem>
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setdialogOpen(false);
        }}
      >
        <Box sx={{ padding: "50px" }}>
          <Typography>Biztosan törölni szeretné?</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "30px",
              marginTop: "10px",
            }}
          >
            <Button variant="outlined" onClick={() => setdialogOpen(false)}>
              Nem
            </Button>
            <Button variant="contained" onClick={() => deleteId()}>
              Igen
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

const LoanTable = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const { loanData: data } = useData();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [filter, setfilter] = React.useState([]);

  React.useEffect(() => {
    setPage(0);
  }, [props.query]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = () => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(
            pathStr
          );
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr
        .map((row) =>
          row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")
        )
        .join("\n");
    }

    const temp = [...data];

    for (let item of temp) {
      delete item.tennivalok;
      delete item.munkaidoNyilvantartas;
      delete item.log;
    }

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot(temp));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, "Hitel mentés.csv");
  };

  const getDistinct = () => {
    return [...new Set(data.map((item) => item.statusz))];
  };

  const handleFilterArray = (e, name) => {
    setfilter({ ...filter, [name]: e.target.checked });
  };

  const filterChipList = () => {
    const temp = [];
    for (let item of Object.keys(filter)) {
      if (filter[item]) {
        temp.push(item);
      }
    }
    return temp;
  };

  return (
    <>
      <Box>
        {filter &&
          filterChipList().map((item, index) => (
            <Chip
              label={item}
              variant="outlined"
              color="primary"
              key={index}
              sx={{ ml: index === 0 ? "0px" : "10px", mb: "10px" }}
            />
          ))}
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Sorszám</TableCell>
              <TableCell align="center">Cég</TableCell>
              <TableCell align="center">
                Státusz
                <IconButton
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                >
                  <Box sx={{ padding: "10px" }}>
                    <FormGroup>
                      {getDistinct().map((item, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={filter[item] ? true : false}
                              onChange={(e) => handleFilterArray(e, item)}
                            />
                          }
                          label={item}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Menu>
              </TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          {data?.length > 0 ? (
            <TableBody>
              {(rowsPerPage > 0
                ? data
                    // eslint-disable-next-line array-callback-return
                    .filter((item) => {
                      if (Object.values(filter).some((val) => val === true)) {
                        if (item.statusz in filter) {
                          if (filter[item.statusz]) {
                            return item;
                          }
                        }
                      } else {
                        return item;
                      }
                    })
                    .filter((item) => {
                      return item.cegnev?.toLowerCase().includes(props.query);
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : data
              ).map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.count}.</TableCell>
                  <TableCell align="center">{row.cegnev}</TableCell>
                  <TableCell align="center">{row.statusz}</TableCell>
                  <TableCell sx={{ width: "80px" }}>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/loans/" + row.key)}
                    >
                      Adatlap
                    </Button>
                  </TableCell>
                  <TableCell sx={{ width: "50px" }}>
                    <Settings id={row.key} />
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <Box sx={{ padding: "10px" }}>Nincsen megjelenítendő adat.</Box>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        labelDisplayedRows={function defaultLabelDisplayedRows({
          from,
          to,
          count,
        }) {
          return `${from}-${to} sor a  ${
            count !== -1 ? count : `more than ${to}`
          }-ból`;
        }}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={"Sorok száma: "}
        component="div"
        count={data && data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Button variant="outlined" onClick={() => handleDownload()}>
        Letöltés
      </Button>
    </>
  );
};

export default LoanTable;
