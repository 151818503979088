import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Controller, useForm } from "react-hook-form";
import {  getDatabase, ref, update } from "firebase/database";
import DeleteIcon from "@mui/icons-material/Delete";

const FormTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          helperText={props.helperText}
          label={props.label}
          fullWidth
          InputProps={{
            endAdornment: props.end,
          }}
          type={props.type}
          inputProps={props.inputProps}
          multiline={props.multiline}
        />
      )}
    />
  );
};

const FormCheckBox = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          onClick={() => props.setforce && props.setforce(!props.force)}
          control={<Checkbox checked={field.value} onChange={field.onChange} />}
          label={props.label}
        />
      )}
    />
  );
};

const Market = (props) => {
  const [dialogOpen, setdialogOpen] = useState({ open: false });

  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    if (dialogOpen.editData) {
      reset(dialogOpen.editData);
    } else {
      reset({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  const onSubmit = (formData) => {
    const date = new Date().toLocaleString() + "";
    const database = getDatabase();
    const dataRef = ref(database, "palyazatok/" + props.id);

    if (dialogOpen.editData === undefined) {
      Object.keys(formData).forEach((key) => {
        if (formData[key] === undefined) {
          formData[key] = "";
        }
      });

      formData.datum = date;
      if (dialogOpen.type === 0) {
        formData.type = 0;
      } else {
        formData.type = 1;
      }
      const updateData = props.data.piacfelmeres ? props.data.piacfelmeres : [];
      updateData.push(formData);
      update(dataRef, { piacfelmeres: updateData });
    } else {
      formData.modositasDatuma = date;
      const temp = props.data.piacfelmeres;
      temp[dialogOpen.index] = formData;
      update(dataRef, { piacfelmeres: temp });
    }

    setdialogOpen({ open: false });
  };

  const handleDelete = (index) => {
    const temp = props.data.piacfelmeres;
    temp.splice(index, 1);
    const db = getDatabase();
    const dataRef = ref(db, "palyazatok/" + props.id);

    update(dataRef, { piacfelmeres: temp });
  };

  return (
    <div>
      <Box sx={{ mt: "20px" }}>
        <Button
          variant="contained"
          endIcon={<AddIcon></AddIcon>}
          sx={{ mr: "20px" }}
          onClick={() => {
            setdialogOpen({ open: true, type: 0 });
          }}
        >
          Részletes
        </Button>
        <Button
          variant="contained"
          endIcon={<AddIcon></AddIcon>}
          onClick={() => {
            setdialogOpen({ open: true, type: 1 });
          }}
        >
          Egyszerűsített
        </Button>
      </Box>
      <Card sx={{ p: "20px", mt: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sorszám</TableCell>
              <TableCell>típus</TableCell>
              <TableCell>Eszköz</TableCell>
              <TableCell>Dátum</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.piacfelmeres?.map((item, i) => (
              <TableRow
                key={i}
                hover
                sx={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (e.target.id === "cell") {
                    setdialogOpen({
                      open: true,
                      type: item.type,
                      editData: props.data.piacfelmeres[i],
                      index: i,
                    });
                  }
                }}
              >
                <TableCell id="cell">{i + 1 + "."}</TableCell>
                <TableCell id="cell">
                  {item.type === 1 ? "Egyszerűsített" : "Részletes"}
                </TableCell>
                <TableCell id="cell">
                  {item["Eszköz szoftver know-how megnevezése"]
                    ? item["Eszköz szoftver know-how megnevezése"]
                    : item["Eszköz típusa"]}
                </TableCell>
                <TableCell id="cell">{item.datum}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDelete(i)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
      <Dialog
        open={dialogOpen.open}
        onClose={() => setdialogOpen({ open: false })}
        fullWidth
        maxWidth="md"
      >
        {dialogOpen.type === 0 && (
          <Box sx={{ p: "40px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h5" sx={{ mb: "30px" }}>
                Részletes piacfelmérés
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Eszköz/szoftver/know-how megnevezése:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Eszköz szoftver know-how megnevezése"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Eszköz/szoftver/know-how típusa:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Eszköz szoftver know-how típusa"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Eszköz/szoftver/know-how VTSZ/TESZOR száma:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Eszköz szoftver know-how VTSZ TESZOR száma"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tartozékai (ha van)</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Tartozékai (ha van)"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box sx={{ mt: "20px", mr: "16px" }}>
                <Controller
                  name="Műszaki specifikáció"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      label="Műszaki specifikáció:"
                      fullWidth
                    />
                  )}
                />
              </Box>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Gyártó cégszerű megnevezése:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Gyártó cégszerű megnevezése"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gyártó származási ország:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Gyártó származási ország"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ width: "500px" }}>
                      A kiválasztott szállító típusa:{" "}
                      <Typography variant="body2">
                        Forgalmazói státusz esetén (amennyiben nem
                        &quot;Gyártó&quot; kerül kiválasztásra) csatolja a
                        gyártóval való kapcsolatot igazoló alátámasztó
                        dokumentumot (pl. disztribútori szerződés, forgalmazói
                        megállapodás).
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormCheckBox
                        control={control}
                        label="Gyártó"
                        name="Gyártó"
                      />
                      <FormCheckBox
                        control={control}
                        label="Kizárólagos forgalmazó"
                        name="Kizárólagos forgalmazó"
                      />
                      <FormCheckBox
                        control={control}
                        label="Viszonteladó"
                        name="Viszonteladó"
                      />
                      <FormCheckBox
                        control={control}
                        label="Hazaikülföldi márkaképviselet"
                        name="Hazai külföldi márkaképviselet"
                      />
                      <FormCheckBox
                        control={control}
                        label="Egyéb"
                        name="Egyéb"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>A kiválasztott szállító megnevezése:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító megnevezése"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A kiválasztott szállító adószáma:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító adószáma"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító származási ország:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító származási ország"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító főtevékenysége:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító főtevékenysége"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító tevékenységi körébe tartozik az
                      árajánlat tárgyát képező eszköz/szoftver kereskedelme:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító tevékenységi körébe tartozik az árajánlat tárgyát képező eszköz - szoftver kereskedelme"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító alapításának időpontja:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító alapításának időpontja"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A kiválasztott szállító honlapja:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító honlapja"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A kiválasztott szállító telefon:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító telefon"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A kiválasztott szállító e-mail:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító e-mail"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító magánszemély tulajdonosa(i):
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító magánszemélytulajdonosa(i)"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A kiválasztott szállító képviselői:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító képviselői"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító értékesítésből származó
                      árbevételének alakulása az utolsó lezárt üzleti évben:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító értékesítésből származó árbevételének alakulása az utolsó lezárt üzleti évben"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      A támogatást igénylőtől független szállító:
                    </TableCell>
                    <TableCell>
                      <FormCheckBox
                        control={control}
                        label="igen"
                        name="A támogatást igénylőtől független szállító - igen"
                      />
                      <FormCheckBox
                        control={control}
                        label="nem"
                        name="A támogatást igénylőtől független szállító - nem"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box sx={{ mt: "20px", mr: "16px" }}>
                <Controller
                  name="Referencia"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      label="Referencia:"
                      fullWidth
                    />
                  )}
                />
              </Box>

              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Az eszköz/szoftver/know-how nettó egységára:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Az eszköz szoftver know-how nettó egységára"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Az eszköz/szoftver/know-how darabszáma:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Az eszköz szoftver know-how darabszáma"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Az eszköz/szoftver/know-how összes nettó ára:
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Az eszköz szoftver know-how összes nettó ára"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A szállítás nettó költsége:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A szállítás nettó költsége"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A beüzemelés nettó költsége:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A beüzemelés nettó költsége"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A betanítás nettó költsége:</TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="A betanítás nettó költsége"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Összesen nettó:</b>
                    </TableCell>
                    <TableCell>
                      <FormTextField control={control} name="Összesen nettó" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Áfa:</b>
                    </TableCell>
                    <TableCell>
                      <FormTextField control={control} name="Áfa" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Összesen bruttó:</b>
                    </TableCell>
                    <TableCell>
                      <FormTextField control={control} name="Összesen bruttó" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      Amennyiben a vételár nem Ft-ban lesz kiegyenlítve, úgy
                      kérjük a megfelelő devizában kitölteni.
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Az eszköz ára deviza alapú:</b>
                    </TableCell>
                    <TableCell>
                      <FormCheckBox
                        control={control}
                        label="igen"
                        name="Az eszköz ára deviza alapú - igen"
                      />
                      <FormCheckBox
                        control={control}
                        label="nem"
                        name="Az eszköz ára deviza alapú - nem"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <b>Ajánlati ár devizája:</b>
                    </TableCell>
                    <TableCell>
                      <FormTextField
                        control={control}
                        name="Ajánlati ár devizája"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Box sx={{ mt: "20px", mr: "16px" }}>
                <Controller
                  name="Leírás"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} multiline label="Leírás:" fullWidth />
                  )}
                />
              </Box>
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: "15px",
                }}
              >
                <Button variant="contained" type="submit">
                  Mentés
                </Button>
              </Box>
            </form>
          </Box>
        )}

        {dialogOpen.type === 1 && (
          <Box sx={{ p: "40px" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h5" sx={{ mb: "30px" }}>
                Egyszerűsített piacfelmérés
              </Typography>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Cég neve:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField control={control} name="Cég neve" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Költségelem:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField control={control} name="Költségelem" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tevékenység:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField control={control} name="Tevékenység" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Megvalósítási helyszín:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Megvalósítási helyszín"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h6">Eszköz</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Eszköz típusa:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField control={control} name="Eszköz típusa" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>VTSZ száma:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="VTSZ száma"
                        multiline="true"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Tartozékok megnevezése:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Tartozékok megnevezése"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Gyártó megnevezése:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Gyártó megnevezése"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography variant="h6">Szállító</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>A kiválasztott szállító típusa:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító típusa"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Egyéb típus esetén kérjük ismertesse a szállító típusát:
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Egyéb típus esetén kérjük ismertesse a szállító típusát"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>A kiválasztott szállító megnevezése:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító megnevezése"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Adószáma:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField control={control} name="Adószáma" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      A kiválasztott szállító honlapja, amely tartalmazza a
                      szállító elérhetőségeit (e-mail, telefon):
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="A kiválasztott szállító honlapja amely tartalmazza a szállító elérhetőségeit (e-mail, telefon)"
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Az eszköz nettó egységára (Ft-ban):</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Az eszköz nettó egységára (Ft-ban)"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Az eszköz bruttó egységára (Ft-ban):</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Az eszköz bruttó egységára (Ft-ban)"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Darabszám:</TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField control={control} name="Darabszám" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Az Eszköz nettó összköltsége (Ft-ban):
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Az Eszköz nettó összköltsége (Ft-ban)"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Az Eszköz bruttó összköltsége (Ft-ban):
                    </TableCell>
                    <TableCell sx={{ width: "300px" }}>
                      <FormTextField
                        control={control}
                        name="Az Eszköz bruttó összköltsége (Ft-ban)"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Typography variant="h6" sx={{ mt: "20px", mb: "7px" }}>
                Piacfelmérés lépései
              </Typography>
              <Typography variant="body2">
                Kérjük, igazolja a listaáras termék piaci árának realitását,
                adja meg annak elérhetőségét a kiválasztott, a termék
                tekintetében kompetens, potenciális gyártó/szállító/forgalmazó
                honlapján, másrészt további két, független
                gyártó/szállító/forgalmazó honlapján is. Legalább az egyik
                honlapnak webáruházzal is kell rendelkeznie. A honlapokról
                készült, rendszerdátumot is tartalmazó képernyőképeket kérjük,
                csatolja a támogatási kérelemhez.
              </Typography>
              <Typography sx={{ mt: "15px" }}>
                Az eszköz elérhetősége a forgalmazók honlapjain:
              </Typography>

              <Box sx={{ mt: "3px", mr: "16px" }}>
                <Controller
                  name="Az eszköz elérhetősége a forgalmazók honlapjain"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} multiline label="" fullWidth />
                  )}
                />
              </Box>
              <Box
                sx={{
                  mt: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: "15px",
                }}
              >
                <Button variant="contained" type="submit">
                  Mentés
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Dialog>
    </div>
  );
};

export default Market;
