import { Button, Card, Checkbox, Chip, Divider, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useSnackbar } from "notistack";
import { getDatabase, ref, update } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { Box } from "@mui/system";
import { DeepDiff } from "deep-diff";
import moment from "moment";
import hu from "date-fns/locale/hu";
import { useAuth } from "../../contexts/UserContext";

const FormDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hu}>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field }) => (
          <DatePicker
            inputFormat="yyyy.MM.dd."
            mask="____.__.__."
            value={field.value ? moment(field.value, "YYYY-MM-DD") : null}
            onChange={(value) => field.onChange(value?.toLocaleDateString() ? value?.toLocaleDateString() : null)}
            label={props.label}
            renderInput={(params) => <TextField {...params} sx={{ width: props.width }} />}
          />
        )}
      />
    </LocalizationProvider>
  );
};

const FormCheckBox = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      defaultValue={false}
      render={({ field }) => (
        <FormControlLabel
          onClick={() => props.setforce && props.setforce(!props.force)}
          control={<Checkbox checked={field.value} onChange={field.onChange} />}
          label={props.label}
        />
      )}
    />
  );
};

const FormTextField = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <TextField
          {...field}
          label={props.label}
          fullWidth
          InputProps={{
            endAdornment: props.end,
          }}
        />
      )}
    />
  );
};

const ApplicationAdmin = (props) => {
  const [docFiles, setdocFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: props.data,
  });
  const state = props.data;
  const [force, setforce] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (props.data?.docURL) {
      setdocFiles(props.data.docURL);
    }
  }, [props.data?.docURL]);

  const handleFileSelect = (event, name, company) => {
    const files = event.target.files;
    const tempArray = [...docFiles];
    const temp = [];

    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }

    for (let index = 0; index < temp.length; index++) {
      if (temp[index] !== undefined) {
        if (docFiles.length > 0) {
          if (docFiles.filter((e) => e.docName === temp[index].name).length > 0) {
            enqueueSnackbar("Ilyen elnevezésű fájl már létezik!", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          } else {
            tempArray.push({
              file: temp[index],
              docCategory: name,
              docName: temp[index].name,
              company,
            });
          }
        } else {
          tempArray.push({
            file: temp[index],
            docCategory: name,
            docName: temp[index].name,
            company,
          });
        }
      }
    }
    setdocFiles(tempArray);

  };
  const handleSupportDocDelete = (index, doc) => {
    const date = new Date().toLocaleString() + "";
    const temp = [...docFiles];
    temp.splice(
      temp.findIndex((item) => item.docName === doc.docName),
      1
    );
    setdocFiles(temp);

    if (doc.docURL !== undefined) {
      const db = getDatabase();
      const storage = getStorage();
      const dataRef = ref(db, "palyazatok/" + state.key);
      const docRef = storageRef(storage, "dokumentumok/" + state.key + "_" + doc.docName);
      update(dataRef, { docURL: temp });
      deleteObject(docRef);
      const tempLog = [...state.log];
      tempLog.push({ kind: "delete", name: doc.docName, date: date });
      update(dataRef, { log: tempLog });
    }
  };

  const onSubmit = (formData) => {
    const database = getDatabase();
    const date = new Date().toLocaleString() + "";

    Object.keys(formData).forEach((key) => {
      if (formData[key] === undefined) {
        delete formData[key];
      }
    });

    if (DeepDiff(state, formData) !== undefined) {
      let temp;

      if (state?.log) {
        temp = [...state.log];
      } else {
        temp = [];
      }

      const log = DeepDiff(state, formData);
      log.forEach((element) => {
        element.date = date;
        if (element.path[0] !== "log") {
          temp.push(element);
        }
      });
      formData.log = temp;
    }

    if ((state.szerzodesmodositas === "" || state.szerzodesmodositas === false) && formData.szerzodesmodositas === true) {
      const today = new Date().toLocaleDateString() + "";
      formData.szerzodesmodositasDatuma = today;
    }

    const dataRef = ref(database, "palyazatok/" + state.key);
    formData.modositasDatuma = date;
    update(dataRef, formData);

    if (docFiles.length > 0) {
      const storage = getStorage();
      let urls = [];
      docFiles.forEach((item) => {
        if (item.docURL === undefined) {
          const str = storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName);
          const tempLog = [...formData.log];
          tempLog.push({ kind: "upload", name: item.docName, date: date });
          update(ref(database, "palyazatok/" + state.key), {
            log: tempLog,
          });
          uploadBytes(str, item.file).then(
            () => {
              getDownloadURL(storageRef(storage, "dokumentumok/" + state.key + "_" + item.docName))
                .then((url) => {
                  urls.push({
                    docName: item.docName,
                    docURL: url,
                    docCategory: item.docCategory,
                    company: item.company ? item.company : "",
                    nev: item.nev ? item.nev : "",
                    osszeg: item.osszeg ? item.osszeg : "",
                    admin: true,
                  });
                })
                .then(() => {
                  update(ref(database, "palyazatok/" + state.key), {
                    docURL: urls,
                  });
                });
            },
            (err) => {
              console.log(err);
            }
          );
        } else {
          urls.push(item);
          update(ref(database, "palyazatok/" + state.key), {
            docURL: urls,
          });
        }
      });
      enqueueSnackbar("Sikeres mentés.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      update(ref(database, "palyazatok/" + state.key), {
        docURL: [],
      });
      enqueueSnackbar("Sikeres mentés.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={{ mt: "10px" }}>
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ p: "20px", overflowX: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ mb: "8px" }}>
                  Eredeti megbízási beérkezésének dátuma:
                </Typography>
                <FormDate control={control} name="eredetiMegbizasBeerkezesiDatuma" label="dátum" />
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <label
                  htmlFor="contained-button-file-support-2123"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    marginTop: "27px",
                  }}
                >
                  <input
                  multiple
                    style={{ display: "none" }}
                    accept="application/pdf, .xlsx, .xls,.docx"
                    id="contained-button-file-support-2123"
                    type="file"
                    onChange={(event) => {
                      handleFileSelect(event, "Eredeti megbízási beérkezésének dátuma");
                    }}
                  />
                  <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                    Fájl kiválasztása
                  </Button>
                </label>
                <Box>
                  {docFiles
                    ?.filter((item) => {
                      return item.docCategory === "Eredeti megbízási beérkezésének dátuma";
                    })
                    .map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ marginTop: "10px" }}
                        label={item.docName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleSupportDocDelete(index, item)}
                        onClick={() => {
                          if (item.docURL !== undefined && item.docURL !== "") {
                            window.open(item.docURL, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: "10px" }}></Divider>
              </Grid>
              <Grid item xs={12} container>
                <Box>
                  <FormCheckBox control={control} label="Szerződésmódosítás" name="szerzodesmodositas" force={force} setforce={setforce} />
                  {getValues("szerzodesmodositas") === true && (
                    <>
                      <label
                        htmlFor="contained-button-file-support-change"
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <input
                        multiple
                          style={{ display: "none" }}
                          accept="application/pdf, .xlsx, .xls,.docx"
                          id="contained-button-file-support-change"
                          type="file"
                          onChange={(event) => {
                            handleFileSelect(event, "Szerződésmódosítás");
                          }}
                        />
                        <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                          Fájl kiválasztása
                        </Button>
                      </label>
                      <Box>
                        {docFiles
                          ?.filter((item) => {
                            return item.docCategory === "Szerződésmódosítás";
                          })
                          .map((item, index) => (
                            <Chip
                              key={index}
                              sx={{ marginTop: "10px" }}
                              label={item.docName}
                              variant="outlined"
                              color="primary"
                              onDelete={() => handleSupportDocDelete(index, item)}
                              onClick={() => {
                                if (item.docURL !== undefined && item.docURL !== "") {
                                  window.open(item.docURL, "_blank", "noopener,noreferrer");
                                }
                              }}
                            />
                          ))}
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: "10px" }}></Divider>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" sx={{ mb: "8px" }}>
                  Teljesítés igazolás kiküldésének napja:
                </Typography>
                <FormDate control={control} name="teljesitesIgazolasKikuldesenekNapja" label="dátum" />
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <label
                  htmlFor="contained-button-file-support"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <input
                  multiple
                    style={{ display: "none" }}
                    accept="application/pdf, .xlsx, .xls,.docx"
                    id="contained-button-file-support"
                    type="file"
                    onChange={(event) => {
                      handleFileSelect(event, "Teljesítés igazolás kiküldésének napja");
                    }}
                  />
                  <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                    Fájl kiválasztása
                  </Button>
                </label>
                <Box>
                  {docFiles
                    ?.filter((item) => {
                      return item.docCategory === "Teljesítés igazolás kiküldésének napja";
                    })
                    .map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ marginTop: "10px" }}
                        label={item.docName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleSupportDocDelete(index, item)}
                        onClick={() => {
                          if (item.docURL !== undefined && item.docURL !== "") {
                            window.open(item.docURL, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: "10px" }}></Divider>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" sx={{ mb: "8px" }}>
                  Visszaérkezett teljesítésigazolás:
                </Typography>
                <FormDate control={control} name="visszaerkezettTeljesitesigazolas" label="dátum" />
              </Grid>

              <Grid item xs={6} container alignItems="center">
                <label
                  htmlFor="contained-button-file-support-2"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <input
                  multiple
                    style={{ display: "none" }}
                    accept="application/pdf, .xlsx, .xls,.docx"
                    id="contained-button-file-support-2"
                    type="file"
                    onChange={(event) => {
                      handleFileSelect(event, "Visszaérkezett teljesítésigazolás");
                    }}
                  />
                  <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                    Fájl kiválasztása
                  </Button>
                </label>
                <Box>
                  {docFiles
                    ?.filter((item) => {
                      return item.docCategory === "Visszaérkezett teljesítésigazolás";
                    })
                    .map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ marginTop: "10px" }}
                        label={item.docName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleSupportDocDelete(index, item)}
                        onClick={() => {
                          if (item.docURL !== undefined && item.docURL !== "") {
                            window.open(item.docURL, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    ))}
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Card sx={{ p: "20px", overflowX: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ mb: "8px" }}>
                  Projekt mappa kész:
                </Typography>
                <FormDate control={control} name="projektMappaKesz" label="dátum" />
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <label
                  htmlFor="contained-button-file-support-200"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <input
                  multiple
                    style={{ display: "none" }}
                    accept="application/pdf, .xlsx, .xls,.docx"
                    id="contained-button-file-support-200"
                    type="file"
                    onChange={(event) => {
                      handleFileSelect(event, "Projekt mappa kész");
                    }}
                  />
                  <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                    Fájl kiválasztása
                  </Button>
                </label>
                <Box>
                  {docFiles
                    ?.filter((item) => {
                      return item.docCategory === "Projekt mappa kész";
                    })
                    .map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ marginTop: "10px" }}
                        label={item.docName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleSupportDocDelete(index, item)}
                        onClick={() => {
                          if (item.docURL !== undefined && item.docURL !== "") {
                            window.open(item.docURL, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: "10px" }}></Divider>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body2" sx={{ mb: "8px" }}>
                  Projekt mappa kiküldésének ideje:
                </Typography>
                <FormDate control={control} name="projektMappakikuldese" label="dátum" />
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <label
                  htmlFor="contained-button-file-support-3"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <input
                  multiple
                    style={{ display: "none" }}
                    accept="application/pdf, .xlsx, .xls,.docx"
                    id="contained-button-file-support-3"
                    type="file"
                    onChange={(event) => {
                      handleFileSelect(event, "Projekt mappa kiküldésének ideje");
                    }}
                  />
                  <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                    Fájl kiválasztása
                  </Button>
                </label>
                <Box>
                  {docFiles
                    ?.filter((item) => {
                      return item.docCategory === "Projekt mappa kiküldésének ideje";
                    })
                    .map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ marginTop: "10px" }}
                        label={item.docName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleSupportDocDelete(index, item)}
                        onClick={() => {
                          if (item.docURL !== undefined && item.docURL !== "") {
                            window.open(item.docURL, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: "10px" }}></Divider>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Visszaérkezett az átadás-átvételi jegyzőkönyv:</Typography>
              </Grid>
              <Grid item xs={6}>
                <FormCheckBox control={control} label="igen" name="visszaerkezettAzAtadasatveteliTrue" />
              </Grid>
              <Grid item xs={6}>
                <FormCheckBox control={control} label="nem" name="visszaerkezettAzAtadasatveteliFalse" />
              </Grid>
              <Grid item xs={6}>
                <FormDate control={control} name="visszaerkezettAtadasAtvitel" label="dátum" />
              </Grid>
              <Grid item xs={6} container alignItems="center">
                <label
                  htmlFor="contained-button-file-support-21234"
                  style={{
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  <input
                  multiple
                    style={{ display: "none" }}
                    accept="application/pdf, .xlsx, .xls,.docx"
                    id="contained-button-file-support-21234"
                    type="file"
                    onChange={(event) => {
                      handleFileSelect(event, "Visszaérkezett átadás-átvételi jegyzőkönyv");
                    }}
                  />
                  <Button endIcon={<FileUploadIcon />} component="span" variant="outlined">
                    Fájl kiválasztása
                  </Button>
                </label>
                <Box>
                  {docFiles
                    ?.filter((item) => {
                      return item.docCategory === "Visszaérkezett átadás-átvételi jegyzőkönyv";
                    })
                    .map((item, index) => (
                      <Chip
                        key={index}
                        sx={{ marginTop: "10px" }}
                        label={item.docName}
                        variant="outlined"
                        color="primary"
                        onDelete={() => handleSupportDocDelete(index, item)}
                        onClick={() => {
                          if (item.docURL !== undefined && item.docURL !== "") {
                            window.open(item.docURL, "_blank", "noopener,noreferrer");
                          }
                        }}
                      />
                    ))}
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {auth.role === "Admin" && (
            <Card sx={{ p: "20px" }}>
              <Typography variant="h6" sx={{ mb: "20px" }}>
                Díjazás
              </Typography>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item container spacing={2} xs={12} md={6}>
                  <Grid item xs={6}>
                    <FormTextField control={control} name="elolegOsszege" label="Előleg összege" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} name="elolegSzamlaSzama" label="Előleg számla száma" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate width="100%" control={control} name="elolegDatuma" label="Előleg dátuma" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate width="100%" control={control} name="elolegKiegyenlitesDatuma" label="Előleg kiegyenlítés dátuma" />
                  </Grid>
                </Grid>
                <Grid item container spacing={2} xs={12} md={6}>
                  <Grid item xs={6}>
                    <FormTextField control={control} name="sikerdijMerteke" label="Sikerdíj mértéke" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} name="sikerdijOsszege" label="Sikerdíj összege" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField control={control} name="sikerdijSzamlaSzama" label="Sikerdíj számla száma" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate width="100%" control={control} name="sikerdijVarhatoEsedekessege" label="Sikerdíj várható esedékessége" />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDate width="100%" control={control} name="sikerdijKiegyenlitesDatuma" label="Sikerdíj kiegyenlítés dátuma" />
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}>
        <Button variant="contained" type="submit">
          Mentés
        </Button>
      </Box>
    </form>
  );
};

export default ApplicationAdmin;
