import { Box, Button, Card, Container, Grid, TextField, Typography } from "@mui/material";
import { get, getDatabase, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/UserContext";

const Input = (props) => {
  return (
    <Grid item xs={6}>
      <Typography sx={{ fontWeight: "500" }}>{props.text}</Typography>
      <Typography variant="body2" gutterBottom>
        {props.helper}
      </Typography>

      <TextField
        multiline
        fullWidth
        value={props.data[props.name]}
        onChange={(e) => props.setdata({ ...props.data, [props.name]: e.target.value })}
      />
    </Grid>
  );
};

const Template = () => {
  const [data, setdata] = useState({});
  const { testMail } = useAuth();

  const handleSubmit = () => {
    const db = getDatabase();
    const dataRef = ref(db, "sablonok");
    set(dataRef, { ...data });
  };

  useEffect(() => {
    const db = getDatabase();
    const dataRef = ref(db, "sablonok");
    get(dataRef).then((snap) => {
      setdata(snap.val());
    });
  }, []);

  return (
    <div style={{ marginLeft: "", marginTop: "40px" }}>
      <Container maxWidth="lg">
        <Card
          sx={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Email sablonok</Typography>
          <Button variant="contained" onClick={() => testMail()}>
            Email kiküldés (TEST)
          </Button>
        </Card>
        <Card sx={{ p: "20px", mt: "20px" }}>
          <Box sx={{ p: "20px", backgroundColor: "#f4f4f4", mb: "20px", borderRadius: "5px" }}>
            <Box>Beilleszthető változók:</Box>
            <Box sx={{ ml: "15px" }}>cégvezető neve → (cégvezető neve)</Box>
            <Box sx={{ ml: "15px" }}>kapcsolattartó neve → (kapcsolattartó neve)</Box>
            <Box sx={{ ml: "15px" }}>projekt azonosító → (GINOP-)</Box>
          </Box>
          <Grid container spacing={2}>
            <Input name="hianypotlas" helper="Email naponta a határidő leteltéig" text="Hiánypótlás határideje" data={data} setdata={setdata} />
            <Input name="tisztazo" helper="Email naponta a határidő leteltéig" text="Tisztázó kérdés határideje" data={data} setdata={setdata} />
            <Input
              name="befejezes"
              helper="Dátumot megelőző 60 nappal 5 naponta"
              text="Projekt befejezése (elszámolás esedékessége)"
              data={data}
              setdata={setdata}
            />
            <Input
              name="tamogatasiElolegElszamolasa"
              helper="Dátumot megelőző 4 hónappal 30 naponta"
              text="Támogatási előleg elszámolásának napja"
              data={data}
              setdata={setdata}
            />
            <Input
              name="fenntartasiIdoszakiJelentes"
              helper="Minden év első 6 hónapjának 2. napján"
              text="Fenntartási időszaki jelentés"
              data={data}
              setdata={setdata}
            />
            <Input
              name="zaroElszamolas"
              helper="Dátumot megelőző 60 nappal 5 naponta"
              text="Záró elszámolás esedékessége"
              data={data}
              setdata={setdata}
            />
            <Input
              name="elszamolasHianypotlas"
              helper="Email naponta a határidő leteltéig"
              text="Elszámolás hiánypótlásának határideje"
              data={data}
              setdata={setdata}
            />
            <Input
              name="elszamolasTisztazo"
              helper="Email naponta a határidő leteltéig"
              text="Elszámolás tisztázó kérdésének határideje"
              data={data}
              setdata={setdata}
            />
            <Input name="szerzodesmodositas" helper="Dátumától számított 5 nap után" text="Szerződésmódosítás" data={data} setdata={setdata} />
            <Input
              name="teljesitesigazolas"
              helper="10 nap múlva sem került megadásra"
              text="Visszaérkezett teljesítésigazolás"
              data={data}
              setdata={setdata}
            />
            <Input
              name="eredetiMegbizas"
              helper="Amíg nem érkezik meg, email naponta"
              text="Eredeti megbízási beérkezése"
              data={data}
              setdata={setdata}
            />
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Mentés
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </div>
  );
};

export default Template;
