import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { Alert, Card } from "@mui/material";
import { useAuth } from "../contexts/UserContext";

export default function LoginPage() {
  const { mFa } = useAuth();
  const { currentUser } = useAuth();
  let navigate = useNavigate();
  const [error, seterror] = React.useState(false);
  const [token, settoken] = React.useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      // await login(data.get("email"), data.get("password"));
      mFa(token, data.get("email"), data.get("password")).then((res) => {
        if (!res) {
          seterror(true);
        }
      });
    } catch (e) {
      seterror(true);
    }
  };

  React.useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div style={{ height: "100vh", display: "flex", alignItems: "center" }}>
      <Container maxWidth="xs">
        <Box
          component={Card}
          sx={{
            padding: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "0px 10px 29px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Belépés
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email cím"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Jelszó"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="Kétfaktoros kulcs"
              value={token}
              onChange={(e) => settoken(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Belépés
            </Button>
            {error && <Alert severity="error">Rossz belépési adatok!</Alert>}
          </Box>
        </Box>
      </Container>
    </div>
  );
}
