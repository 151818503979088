import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useData } from "../../contexts/DataContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TodoList from "../TodoList";
import Mail from "../Mail";
import FileSaver from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useSnackbar } from "notistack";
import WorkHour from "../WorkHour";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const convertLog = (log) => {
  if (log.kind === "N") {
    return {
      type: "Új bevitt érték",
      value: log.path[0] + " → " + JSON.stringify(log.rhs),
    };
  }
  if (log.kind === "E") {
    const temp = log.rhs === "" ? "üres mező" : log.rhs;
    return {
      type: "Érték megváltozott",
      value: log.path + ": " + log.lhs + " → " + temp,
    };
  }
  if (log.kind === "A") {
    if (log.item.kind === "N") {
      return {
        type: "Új bevitt érték",
        date: log.date,
        value: log.path + " → " + log.item.rhs?.name,
      };
    }
    if (log.item.kind === "D") {
      return {
        type: "Érték törlésre került",
        date: log.date,
        value: log.path + " → " + log.item.lhs?.name,
      };
    }
  }
  if (log.kind === "upload") {
    return {
      type: "Új fájl került feltöltésre",
      date: log.date,
      value: log.name,
    };
  }
  if (log.kind === "delete") {
    return {
      type: "Egy fájl törlés került",
      date: log.date,
      value: log.name,
    };
  }
};

const Management = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useData().managementData.find((item) => item.key === id);
  const [value, setValue] = React.useState(0);
  const componentRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkItem = (name, label) => {
    const n = name + "Value";
    if (data[name]) {
      return { value: data[name], label, v: data[n] };
    } else {
      return { value: false, label };
    }
  };

  const handleDownload = (id) => {
    function pivot(arr) {
      var mp = new Map();

      function setValue(a, path, val) {
        if (Object(val) !== val) {
          // primitive value
          var pathStr = path.join(".");
          var i = (mp.has(pathStr) ? mp : mp.set(pathStr, mp.size)).get(pathStr);
          a[i] = val;
        } else {
          for (var key in val) {
            setValue(a, key === "0" ? path : path.concat(key), val[key]);
          }
        }
        return a;
      }

      var result = arr.map((obj) => setValue([], [], obj));
      return [[...mp.keys()], ...result];
    }

    function toCsv(arr) {
      return arr.map((row) => row.map((val) => (isNaN(val) ? JSON.stringify(val) : +val)).join(";")).join("\n");
    }

    let temp = JSON.parse(JSON.stringify(data))

    for (let item of temp) {
      delete item.tennivalok;
      delete item.munkaidoNyilvantartas;
      delete item.log;
    }

    var BOM = "\uFEFF";
    var csvData = BOM + toCsv(pivot([temp]));

    var blob = new Blob([csvData], {
      type: "text/csv;charset=UTF-8;",
    });

    FileSaver.saveAs(blob, "Projektmenedzsment mentés.csv");
  };

  const group = () => {
    let array = [];
    array.push(checkItem("gepbeszerzes", "gépbeszerzés"));
    array.push(checkItem("ingatlanEpites", "ingatlan építés"));
    array.push(checkItem("ingatlanVasarlas", "ingatlan vásárlás"));
    array.push(checkItem("ingatlanFelujitas", "ingatlan felújítás"));
    array.push(checkItem("ingatlanKorszerusites", "ingatlan korszerűsítés"));
    array.push(checkItem("megujuloEnergia", "megújuló energia"));
    return array;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Container sx={{ marginTop: "20px" }}>
      <Box sx={{ padding: "20px" }}>
        <Card sx={{ padding: "20px", marginBottom: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Projektmenedzsment adatai</Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  navigate("/managements/add", { state: data });
                }}
              >
                Adatok szerkesztése
              </Button>
              <Button variant="outlined" onClick={() => navigate(-1)}>
                Vissza
              </Button>
            </Box>
          </Box>
        </Card>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "rgba(0, 0, 0, 0.12)",
              mb: "30px",
            }}
          >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab label="Főoldal" {...a11yProps(0)} />
              <Tab label="Munkaóra nyilvántartás" {...a11yProps(2)} />
              <Tab label="Előzmények" {...a11yProps(3)} />
            </Tabs>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Projekt</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Cég:</TableCell>
                        <TableCell>{data.cegnev}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cég Email címe:</TableCell>
                        <TableCell>{data.cegEmail}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Projekt felelőse:</TableCell>
                        <TableCell>{data.projektFelelose}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Projekt azonosító:</TableCell>
                        <TableCell>{data.azonosito}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography variant="body2" sx={{ margin: "15px" }}>
                      Projekt célja:
                    </Typography>
                    {group().map((item, index) => {
                      if (item.value) {
                        return (
                          <Chip key={"f" + index} label={item.label + " - " + item.v} variant="outlined" color="primary" sx={{ margin: "3px" }} />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Box>
                  <Divider sx={{ my: "10px" }} />
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography variant="body2" sx={{ margin: "15px" }}>
                      Szerepkör:
                    </Typography>
                    {data.ajanlatotKero && <Chip label={"Ajánlatot kérő"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                    {data.ajanlatotAdo && <Chip label={"Ajánlatot adó"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                  </Box>
                  <Divider sx={{ my: "10px" }} />
                  <Box
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography variant="body2" sx={{ margin: "15px" }}>
                      Eljárás típusa:
                    </Typography>
                    {data.hazai && <Chip label={"Hazai"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                    {data.eu && <Chip label={"EU"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Pénzügy</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Építés összege max:</TableCell>
                        <TableCell>{data.epitesOsszegeHuf && data.epitesOsszegeHuf + " HUF - " + data.epitesOsszegeEur + " EUR"}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Árubeszerzés összege max:</TableCell>
                        <TableCell>
                          {data.arubeszerzesOsszegeHuf && data.arubeszerzesOsszegeHuf + " HUF - " + data.arubeszerzesOsszegeEur + " EUR"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Szolgáltatás összege max:</TableCell>
                        <TableCell>
                          {data.szolgaltatasOsszegeHuf && data.szolgaltatasOsszegeHuf + " HUF - " + data.szolgaltatasOsszegeEur + " EUR"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Szolgáltatás koncesszió összege max:</TableCell>
                        <TableCell>
                          {data.szolgaltatasKoncesszioOsszegeHuf &&
                            data.szolgaltatasKoncesszioOsszegeHuf + " HUF - " + data.szolgaltatasKoncesszioOsszegeEur + " EUR"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Projekt időpontok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Szerződéskötés dátuma:</TableCell>
                        <TableCell>{data.szerzodeskotesDatum}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Befejezési határidő:</TableCell>
                        <TableCell>{data.befejezesiHatarido}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Beadás ideje:</TableCell>
                        <TableCell>{data.beadasIdeje}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Jóváhagyás napja:</TableCell>
                        <TableCell>{data.jovahagyasNapja}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Hiánypótlás határideje:</TableCell>
                        <TableCell sx={{ display: "flex" }}>
                          {data.hianypotlasHatarideje &&
                            data.hianypotlasHatarideje.map(
                              (item,i) =>
                                item.hatarido !== "" && <Chip key={"g"+i} label={item.hatarido} variant="outlined" color="primary" sx={{ margin: "3px" }} />
                            )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>javított dokumentumok visszaküldése:</TableCell>
                        <TableCell>
                          {data.javitottDokumentumok &&
                            data.javitottDokumentumok.map(
                              (item,i) =>
                                item.javitottDokumentumok !== "" && (
                                  <Chip key={"h"+i} label={item.javitottDokumentumok} variant="outlined" color="primary" sx={{ margin: "3px" }} />
                                )
                            )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Typography>Dokumentumok</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Fájl neve</TableCell>
                        <TableCell>Kategória</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.docURL?.map((item,i) => (
                        <TableRow key={"j"+i}>
                          <TableCell>
                            <a
                              href={item.docURL}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <Chip
                                label={item.docName}
                                variant="outlined"
                                color="primary"
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    backgroundColor: "#f2f8ff",
                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  },
                                }}
                              />
                            </a>
                          </TableCell>
                          <TableCell>{item.docCategory}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>

              <Card
                sx={{
                  mt: "20px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Munkaóra kulcs:</Typography>
                <Tooltip title="Másolás">
                  <Typography
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(data.key);
                      enqueueSnackbar("A kulcs másolásra került.", {
                        variant: "success",
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "right",
                        },
                      });
                    }}
                  >
                    {data.key}
                  </Typography>
                </Tooltip>
              </Card>

              <Button sx={{ mt: "20px" }} variant="contained" onClick={() => handleDownload()}>
                Letöltés
              </Button>
              <Button sx={{ mt: "20px", ml: "20px" }} onClick={handlePrint} variant="contained">
                Nyomtatás
              </Button>

              <Box sx={{ display: "none", displayPrint: "block" }}>
                <div ref={componentRef}>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Projekt adatok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Cég:</TableCell>
                            <TableCell>{data.cegnev}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Cég Email címe:</TableCell>
                            <TableCell>{data.cegEmail}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Projekt felelőse:</TableCell>
                            <TableCell>{data.projektFelelose}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Projekt azonosító:</TableCell>
                            <TableCell>{data.azonosito}</TableCell>
                          </TableRow>
                        </table>
                        <Box
                          sx={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          <Typography variant="body2" sx={{ margin: "15px" }}>
                            Projekt célja:
                          </Typography>
                          {group().map((item, index) => {
                            if (item.value) {
                              return (
                                <Chip
                                  key={"a" + index}
                                  label={item.label + " - " + item.v}
                                  variant="outlined"
                                  color="primary"
                                  sx={{ margin: "3px" }}
                                />
                              );
                            } else {
                              return null;
                            }
                          })}
                        </Box>
                        <Divider sx={{ my: "10px" }} />
                        <Box
                          sx={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          <Typography variant="body2" sx={{ margin: "15px" }}>
                            Szerepkör:
                          </Typography>
                          {data.ajanlatotKero && <Chip label={"Ajánlatot kérő"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                          {data.ajanlatotAdo && <Chip label={"Ajánlatot adó"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                        </Box>
                        <Divider sx={{ my: "10px" }} />
                        <Box
                          sx={{
                            marginTop: "10px",
                            display: "flex",
                            alignItems: "baseline",
                          }}
                        >
                          <Typography variant="body2" sx={{ margin: "15px" }}>
                            Eljárás típusa:
                          </Typography>
                          {data.hazai && <Chip label={"Hazai"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                          {data.eu && <Chip label={"EU"} variant="outlined" color="primary" sx={{ margin: "3px" }} />}
                        </Box>
                      </div>

                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Pénzügy
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Építés összege max:</TableCell>
                            <TableCell>{data.epitesOsszegeHuf && data.epitesOsszegeHuf + " HUF - " + data.epitesOsszegeEur + " EUR"}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Árubeszerzés összege max:</TableCell>
                            <TableCell>
                              {data.arubeszerzesOsszegeHuf && data.arubeszerzesOsszegeHuf + " HUF - " + data.arubeszerzesOsszegeEur + " EUR"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Szolgáltatás összege max:</TableCell>
                            <TableCell>
                              {data.szolgaltatasOsszegeHuf && data.szolgaltatasOsszegeHuf + " HUF - " + data.szolgaltatasOsszegeEur + " EUR"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Szolgáltatás koncesszió összege max:</TableCell>
                            <TableCell>
                              {data.szolgaltatasKoncesszioOsszegeHuf &&
                                data.szolgaltatasKoncesszioOsszegeHuf + " HUF - " + data.szolgaltatasKoncesszioOsszegeEur + " EUR"}
                            </TableCell>
                          </TableRow>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="page-break"></div>
                  <div style={{ display: "block" }}>
                    <div className="grid-container">
                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Projekt időpontok
                        </Typography>
                        <table>
                          <TableRow>
                            <TableCell>Szerződéskötés dátuma:</TableCell>
                            <TableCell>{data.szerzodeskotesDatum}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Befejezési határidő:</TableCell>
                            <TableCell>{data.befejezesiHatarido}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Beadás ideje:</TableCell>
                            <TableCell>{data.beadasIdeje}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Jóváhagyás napja:</TableCell>
                            <TableCell>{data.jovahagyasNapja}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Hiánypótlás határideje:</TableCell>
                            <TableCell sx={{ display: "flex" }}>
                              {data.hianypotlasHatarideje &&
                                data.hianypotlasHatarideje.map(
                                  (item, i) =>
                                    item.hatarido !== "" && (
                                      <Chip key={"b" + i} label={item.hatarido} variant="outlined" color="primary" sx={{ margin: "3px" }} />
                                    )
                                )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>javított dokumentumok visszaküldése:</TableCell>
                            <TableCell>
                              {data.javitottDokumentumok &&
                                data.javitottDokumentumok.map(
                                  (item, i) =>
                                    item.javitottDokumentumok !== "" && (
                                      <Chip
                                        key={"c" + i}
                                        label={item.javitottDokumentumok}
                                        variant="outlined"
                                        color="primary"
                                        sx={{ margin: "3px" }}
                                      />
                                    )
                                )}
                            </TableCell>
                          </TableRow>
                        </table>
                      </div>

                      <div>
                        <Typography variant="h6" sx={{ ml: "20px", py: "15px" }}>
                          Dokumentumok
                        </Typography>
                        <table>
                          {data.docURL?.map((item, i) => (
                            <TableRow key={"d" + i}>
                              <TableCell>
                                <a
                                  href={item.docURL}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <Chip
                                    label={item.docName}
                                    variant="outlined"
                                    color="primary"
                                    sx={{
                                      cursor: "pointer",
                                      "&:hover": {
                                        backgroundColor: "#f2f8ff",
                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                      },
                                    }}
                                  />
                                </a>
                              </TableCell>
                              <TableCell>{item.docCategory}</TableCell>
                            </TableRow>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ padding: "20px" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate("add-todo", {
                      state: {
                        location: "menedzsment",
                        context: "managementData",
                        projektAzonosito: data.azonosito,
                        tennivalok: data.tennivalok,
                      },
                    });
                  }}
                >
                  Feladat hozzáadása
                </Button>
                <TodoList tennivalok={data.tennivalok} id={id} db="menedzsment" />
              </Card>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WorkHour id={id} data={data.munkaidoNyilvantartas}></WorkHour>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Box sx={{ padding: "20px" }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Levelezés</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Mail email={data.cegEmail} label={data.key}></Mail>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Rendszerelőzmények</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="h6">Rendszerelőzmények</Typography>
                {data.log && (
                  <Box
                    sx={{
                      backgroundColor: "#f5f5f5",
                      padding: "10px",
                      borderRadius: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {data.log.map((item, index) => (
                      <Box key={"e" + index} sx={{ mt: index === 0 ? "0px" : "10px" }}>
                        <Typography style={{ fontWeight: "500" }}>{convertLog(item)?.type}</Typography>
                        <Typography>
                          {item.date}&nbsp; {convertLog(item)?.value}
                        </Typography>
                        <Divider></Divider>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default Management;
