import { getDatabase, onValue, ref } from "firebase/database";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./UserContext";
import axios from "axios";
import { settings } from "../config";

const url = settings.url;
const DataContext = createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [customerData, setcustomerData] = useState();
  const [applicationsData, setapplicationsData] = useState();
  const [loanData, setloanData] = useState();
  const [managementData, setmanagementData] = useState();
  const [procurementData, setprocurementData] = useState();
  const [emailData, setemailData] = useState();
  const [notificationData, setnotificationData] = useState();
  const [recommendationData, setrecommendationData] = useState();
  const [loading, setloading] = useState(true);
  const [userList, setuserList] = useState();
  const auth = useAuth();


  useEffect(() => {
    if (!auth.currentUser) {
      setloading(false);
    } else {
      const db = getDatabase();
      const dataRef = ref(db, "/ugyfelek");
      onValue(dataRef, (snapshot) => {
        console.log("fetching data - customers");
        const d = snapshot.val();
        const temp = [];
        let count = 1;
        for (let id in d) {
          d[id].count = count;
          d[id].key = id;
          temp.push(d[id]);
          count = count + 1;
        }
        temp.reverse()
        setcustomerData(temp);
        // setloading(false);
      });
      const dataRef2 = ref(db, "/palyazatok");
      onValue(dataRef2, (snapshot) => {
        console.log("fetching data - applications");
        const d = snapshot.val();
        const temp = [];
        let count = 1;
        for (let id in d) {
          d[id].count = count;
          d[id].key = id;
          temp.push(d[id]);
          count = count + 1;
        }
        temp.reverse()
        setapplicationsData(temp);
        // setloading(false);
      });

      const dataRef3 = ref(db, "/hitelek");
      onValue(dataRef3, (snapshot) => {
        console.log("fetching data - loans");
        const d = snapshot.val();
        const temp = [];
        let count = 1;
        for (let id in d) {
          d[id].count = count;
          d[id].key = id;
          temp.push(d[id]);
          count = count + 1;
        }
        temp.reverse()
        setloanData(temp);
        // setloading(false);
      });

      const dataRef4 = ref(db, "/menedzsment");
      onValue(dataRef4, (snapshot) => {
        console.log("fetching data - managements");
        const d = snapshot.val();
        const temp = [];
        let count = 1;
        for (let id in d) {
          d[id].count = count;
          d[id].key = id;
          temp.push(d[id]);
          count = count + 1;
        }
        temp.reverse()
        setmanagementData(temp);
        //setloading(false);
      });

      const dataRef5 = ref(db, "/kozbeszerzes");
      onValue(dataRef5, (snapshot) => {
        console.log("fetching data - procurements");
        const d = snapshot.val();
        const temp = [];
        let count = 1;
        for (let id in d) {
          d[id].count = count;
          d[id].key = id;
          temp.push(d[id]);
          count = count + 1;
        }
        temp.reverse()
        setprocurementData(temp);
        //  setloading(false);
      });

      const dataRef6 = ref(db, "/email");
      onValue(dataRef6, (snapshot) => {
        console.log("fetching data - emails");
        const d = snapshot.val();
        const temp = [];
        for (let id in d) {
          d[id].id = id
          temp.push(d[id]);
        }
        setemailData(temp);
        //setloading(false);
      });

      const dataRef7 = ref(db, "/ertesito");
      onValue(dataRef7, (snapshot) => {
        console.log("fetching data - notifications");
        const d = snapshot.val();
        const temp = [];
        for (let id in d) {
          d[id].notification = true
          temp.push(d[id]);
        }
        setnotificationData(temp);
       // setloading(false);
      });

      const dataRef8 = ref(db, "/ajanlo");
      onValue(dataRef8, (snapshot) => {
        console.log("fetching data - recommendations");
        const d = snapshot.val();

        setrecommendationData(d);
        setloading(false);
      });

      auth.currentUser.getIdToken().then((token) => {
        axios
          .get(url + "/users", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setuserList(res.data);
          });
      });
    }
  }, [auth.currentUser]);

  const value = {
    customerData,
    applicationsData,
    loanData,
    managementData,
    procurementData,
    emailData,
    notificationData,
    recommendationData,
    userList,
  };

  return (
    <DataContext.Provider value={value}>
      {!loading && children}
    </DataContext.Provider>
  );
}
