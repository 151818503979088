import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { useAuth } from "../contexts/UserContext";

const links = [
  { name: "Főoldal", url: "" },
  { name: "Ügyfelek", url: "customers" },
  { name: "Pályázatok", url: "applications" },
  { name: "Hitel", url: "loans" },
  { name: "Projekt menedzsment", url: "managements" },
  { name: "Közbeszerzés", url: "procurements" },
  { name: "Munkaóra", url: "work" },
  { name: "Ügyintézők", url: "colleagues" },
  { name: "Iktatás", url: "postal" },
  { name: "Sablonok", url: "template" },
];

const Sidebar = () => {
  const { logout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        margin: "10px",
        borderRadius: "8px",
        height: "calc(100vh - 20px)",

        width: "180px",
        backgroundColor: "rgb(256,256,256)",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        overflowY: "auto",
        direction: "rtl",
      }}
      id="style-1"
    >
      <Box
        sx={{
          padding: "20px 0px 20px 0px",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.7rem",
          color: "#4b5563",
        }}
      >
        CRM
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "calc(100% - 80px)",
          direction: "ltr",
        }}
      >
        <Box>
          {links.map((item, index) => (
            <div key={index}>
              <Box
                onClick={() => {
                  if (item.name === "Ügyintézők") {
                    if (auth.role === "Admin") {
                      navigate(item.url);
                    }
                  } else {
                    navigate(item.url);
                  }

                }}
                sx={{
                  padding: "8px",
                  margin: "8px",
                  borderRadius: "10px",
                  backgroundColor: pathname.split("/")[1] === item.url ? "rgb(242, 242, 242)" : null,
                  fontWeight: "600",
                  fontSize: "1.1rem",
                  "&:hover": {
                    backgroundColor: "rgb(242, 242, 242)",
                    cursor: "pointer",
                  },
                }}
              >
                {item.name}
              </Box>

              {item.name === "Ügyfelek" && <Divider />}
              {item.name === "Közbeszerzés" && <Divider />}
              {item.name === "Főoldal" && <Divider />}
            </div>
          ))}
        </Box>
        <Box sx={{ pb: "10px" }}>
          <Box
            onClick={() => {
              logout();
            }}
            sx={{
              padding: "10px",
              margin: "10px",
              borderRadius: "10px",
              textAlign: "center",
              fontWeight: "500",
              border: "2px solid rgb(242, 242, 242)",
              "&:hover": {
                backgroundColor: "rgb(242, 242, 242)",
                cursor: "pointer",
              },
            }}
          >
            Kilépés
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
