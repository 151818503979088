import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import { Box } from "@mui/system";
import { useData } from "../contexts/DataContext";
import { useSnackbar } from "notistack";
import { getDatabase, ref, update } from "firebase/database";
import { useAuth } from "../contexts/UserContext";
import { deleteObject, getDownloadURL, getStorage, listAll, ref as storageRef, uploadBytes } from "firebase/storage";
import FileSaver from "file-saver";

const Row = (item, index) => {
  const data = [];
  for (const [key, value] of Object.entries(item)) {
    data.push({ name: key, value });
  }

  return (
    <TableRow key={"fd" + index}>
      {data.map((item, index) => (
        <TableCell key={"zd" + index} sx={{ fontWeight: index === data.length - 1 ? "500" : "" }}>
          {item.value}
        </TableCell>
      ))}
    </TableRow>
  );
};

const Work = () => {
  const [savedata, setsavedata] = useState([]);
  const [companies, setcompanies] = useState([]);
  const [file, setfile] = useState();
  const [fields, setfields] = useState();
  const tevekenysegek = useData();
  const { enqueueSnackbar } = useSnackbar();
  const { testWorkHour } = useAuth();
  const [oldFiles, setoldFiles] = useState();

  const getdata = (results) => {
    const temp = [];
    results.data.forEach((item) => {
      for (const [key, value] of Object.entries(item)) {
        if (key === "Új Címke 1" && value !== "Total") {
          temp.push({
            loadFileData: value,
            foundData: checkValue(value).value,
            foundKey: checkValue(value).key,
            location: checkValue(value).location,
            db: checkValue(value).db,
            type: checkValue(value).type,
            file: item,
          });
        }
      }
    });
    sumComp();
    setsavedata(temp);
    setfields(results.meta.fields);
  };

  const changeHandler = (event) => {
    try {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: getdata,
      });
      setfile(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const checkValue = (item) => {
    for (let j of tevekenysegek.applicationsData) {
      if (item.includes(j.key)) {
        return {
          value: j.cegnev,
          key: j.key,
          location: "palyazatok/" + j.key,
          db: "applicationsData",
          type: "Pályázat",
        };
      }
    }

    for (let j of tevekenysegek.loanData) {
      if (item.includes(j.key)) {
        return {
          value: j.cegnev,
          key: j.key,
          location: "hitelek/" + j.key,
          db: "loanData",
          type: "Hitel",
        };
      }
    }

    for (let j of tevekenysegek.managementData) {
      if (item.includes(j.key)) {
        return {
          value: j.cegnev,
          key: j.key,
          location: "menedzsment/" + j.key,
          db: "managementData",
          type: "Projekt menedzsment",
        };
      }
    }

    for (let j of tevekenysegek.procurementData) {
      if (item.includes(j.key)) {
        return {
          value: j.cegnev,
          key: j.key,
          location: "kozbeszerzes/" + j.key,
          db: "procurementData",
          type: "Közbeszerzés",
        };
      }
    }
    return { value: "", key: "" };
  };

  const sumComp = () => {
    const temp = [];
    for (let j of tevekenysegek.applicationsData) {
      if (j.cegnev !== undefined) {
        temp.push({
          value: j.cegnev,
          key: j.key,
          location: "palyazatok/" + j.key,
          db: "applicationsData",
          type: "Pályázat",
          applicationID: j.projektAzonosito,
        });
      }
    }

    for (let j of tevekenysegek.loanData) {
      if (j.cegnev !== undefined) {
        temp.push({
          value: j.cegnev,
          key: j.key,
          location: "hitelek/" + j.key,
          db: "loanData",
          type: "Hitel",
        });
      }
    }

    for (let j of tevekenysegek.managementData) {
      if (j.cegnev !== undefined) {
        temp.push({
          value: j.cegnev,
          key: j.key,
          location: "menedzsment/" + j.key,
          db: "managementData",
          type: "Projekt menedzsment",
        });
      }
    }

    for (let j of tevekenysegek.procurementData) {
      if (j.cegnev !== undefined) {
        temp.push({
          value: j.cegnev,
          key: j.key,
          location: "kozbeszerzes/" + j.key,
          db: "procurementData",
          type: "Közbeszerzés",
        });
      }
    }

    temp.sort((a, b) => a.value.localeCompare(b.value));
    setcompanies(temp);
  };

  const loadFiles = () => {
    const storage = getStorage();
    const listRef = storageRef(storage, "munkaora");

    listAll(listRef).then(async (res) => {
      const temp = [];
      res.items.forEach((itemRef) => {
        temp.push(itemRef);
      });

      for await (let item of temp) {
        const pathReference = storageRef(storage, item._location.path_);
        getDownloadURL(pathReference).then((url) => {
          item.downloadLink = url;
        });
      }

      setoldFiles(temp);
    });
  };

  useEffect(() => {
    loadFiles();
  }, []);

  const handleDelete = (location) => {
    console.log(location);
    const storage = getStorage();
    const deleteRef = storageRef(storage, location);

    deleteObject(deleteRef).then(() => {
      loadFiles();
    });
  };

  const handleSubmit = () => {
    // if (savedata.some((item) => item.foundKey === "")) {
    //   enqueueSnackbar("Hiányzó mező!", {
    //     variant: "error",
    //     anchorOrigin: {
    //       vertical: "bottom",
    //       horizontal: "right",
    //     },
    //   });
    // } else {
    const db = getDatabase();
    const storage = getStorage();

    savedata.forEach(async (item) => {
      if (item.foundKey !== "") {
        let temp = await tevekenysegek[item.db].filter((i) => {
          return i.key === item.foundKey;
        });
        let up = temp[0].munkaidoNyilvantartas === undefined ? {} : temp[0].munkaidoNyilvantartas;

        for (const [key, value] of Object.entries(item.file)) {
          if (key !== "Új Címke 1" && key !== "Össz idő" && key !== "Megjegyzés") {
            let newKey = key.replaceAll(".", "-");
            if (value !== "0:00:00") {
              up[newKey] = value;
            }
          }
        }
        update(ref(db, item.location), {
          munkaidoNyilvantartas: up,
        });

        setsavedata([]);
      }
    });

    const fileRef = storageRef(storage, "munkaora/" + file.name);
    uploadBytes(fileRef, file).then(() => {
      loadFiles();
    });
    setfile();
  };

  return (
    <div style={{ marginLeft: "", marginTop: "40px" }}>
      <Container maxWidth="lg">
        <Card
          sx={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <label
              htmlFor="contained-button-file-support-3"
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            >
              <input
                style={{ display: "none" }}
                accept=".csv"
                id="contained-button-file-support-3"
                type="file"
                onChange={(event) => {
                  changeHandler(event);
                }}
              />
              <Button endIcon={<FileUploadIcon />} component="span" variant="contained">
                Fájl kiválasztása
              </Button>
            </label>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              testWorkHour();
            }}
          >
            Email kiküldés (TEST)
          </Button>
        </Card>

        {savedata.length > 0 && (
          <>
            <Card sx={{ mt: "20px", p: "20px", overflow: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>{fields && fields.map((item, index) => <TableCell key={"a" + index}>{item}</TableCell>)}</TableRow>
                </TableHead>
                <TableBody>{savedata.map((item, index) => Row(item.file, index))}</TableBody>
              </Table>
            </Card>

            <Box sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
              <Card
                sx={{
                  p: "20px",
                  mt: "20px",
                  minWidth: "600px",
                }}
              >
                <Grid container spacing={2}>
                  {savedata.map((item, index) => {
                    return (
                      <>
                        <Grid key={"ff" + index} item xs={6} container justifyContent="flex-end" alignItems="center">
                          {item.loadFileData}
                          {item.maybe}
                        </Grid>
                        <Grid item xs={6} justifyContent="flex-start" key={"b" + index}>
                          <Box
                            sx={{
                              padding: "10px",
                              borderRadius: "5px",
                              width: "200px",
                              backgroundColor: item.foundData !== "" ? (item.maybe ? "#ffe1a4" : "#dbfbdc") : "#ffebeb",
                            }}
                          >
                            {item.foundData ? (
                              <>
                                {item.foundData} <br></br> {item.type}
                              </>
                            ) : (
                              <TextField select variant="outlined" margin="none" size="small" placeholder="Nincs találat" sx={{ width: "200px" }}>
                                {companies.map((compItem, compIndex) => (
                                  <Tooltip title={compItem.applicationID ? compItem.applicationID : ""}>
                                    <MenuItem
                                      key={"ffff" + compIndex}
                                      onClick={() => {
                                        const temp = [...savedata];
                                        temp[index].foundData = compItem.value;
                                        temp[index].foundKey = compItem.key;
                                        temp[index].location = compItem.location;
                                        temp[index].type = compItem.type;
                                        temp[index].db = compItem.db;
                                        setsavedata(temp);
                                      }}
                                    >
                                      {compItem.value} -{" "}
                                      <Box sx={{ ml: "4px", border: "2px solid rgba(21, 128, 234, 0.12)", borderRadius: "6px", px: "5px" }}>
                                        {compItem.type}
                                      </Box>
                                    </MenuItem>
                                  </Tooltip>
                                ))}
                              </TextField>
                            )}
                          </Box>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "center", mt: "40px" }}>
                  <Button variant="contained" onClick={() => handleSubmit()}>
                    Mentés
                  </Button>
                </Box>
              </Card>
            </Box>
          </>
        )}
        <Card sx={{ mt: "20px", p: "20px" }}>
          <Typography variant="h6">Előző munkaidő nyilvántartások</Typography>
          <Box sx={{ mt: "20px" }}>
            {oldFiles?.map((item) => (
              <Box sx={{ display: "flex", gap: "20px", width: "100%" }}>
                <Box sx={{ mt: "2px" }}>{item._location.path_.substring(9)}</Box>
                <Box sx={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => {
                        FileSaver.saveAs(item.downloadLink, item._location.path_.substring(9));
                      }}
                    >
                      Letöltés
                    </Button>
                  </Box>
                  <Box>
                    <Tooltip title="A feltöltött fájl törlése">
                      <Button
                        variant="text"
                        size="small"
                        color="warning"
                        onClick={() => {
                          handleDelete(item._location.path_);
                        }}
                      >
                        Törlés
                      </Button>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Card>
      </Container>
    </div>
  );
};

export default Work;
